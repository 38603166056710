import { AgoraEventHandler } from "@/agora";
import { ErrorLocale, TeacherClassError } from "@/locales/localeid";
import { MediaStatus, RoomModel } from "@/models";
import { GLError, GLErrorCode } from "@/models/error.model";
import { UpdateLessonAndUnitModel } from "@/models/update-lesson-and-unit.model";
import { UserModel } from "@/models/user.model";
import router from "@/router";
import {
  FetchSessionDtoResModel,
  HelperService,
  InfoService,
  OneToOneDto,
  RemoteTeachingService,
  TeacherUpdateMediaStateRequestModel,
  ToggleStudentMediaDevicesRequestModel,
  transformOneToOneDataServeHelper,
  transformOneToOneDataServeTeacher,
} from "@/services";
import { GetIndependentStudentsResponse, IndependentService } from "@/services/independent";
import { BlobTagItem } from "@/services/storage/interface";
import { StudentStorageService } from "@/services/storage/service";
import { store } from "@/store";
import { Sticker } from "@/store/annotation/state";
import { UserRole, VCPlatform } from "@/store/app/state";
import { callingUtils } from "@/store/room/teacher/utils";
import { TeachingMode } from "@/store/teaching/interfaces";
import { AGORA_MINIMUM_USER_VOLUME, MIN_ZOOM_RATIO } from "@/utils/constant";
import { Logger } from "@/utils/logger";
import { Paths } from "@/utils/paths";
import { getDeviceId, MediaDeviceTypes } from "@/utils/utils";
import { FabricObject, ToggleAllTargetsModel, ToggleTargetModel } from "@/ws";
import { ConnectionDisconnectedReason, ConnectionState, IAgoraRTCRemoteUser, UID } from "agora-rtc-sdk-ng";
import { notification } from "ant-design-vue";
import { ErrorCode, fmtMsg } from "vue-glcommonui";
import { ActionTree } from "vuex";
import {
  CallingConnectionStatus,
  ClassViewPayload,
  DefaultPayload,
  DeviceMediaPayload,
  InitClassRoomPayload,
  NetworkQualityPayload,
  StudentBadgePayload,
  StudentCaptureStatus,
  UserIdPayload,
  UserMediaPayload,
  ValueOfClassView,
} from "../interface";
import { ParamsToJoinCurSessionInterface } from "./../interface";
import { useTeacherRoomWSHandler } from "./handler";
import { TeacherRoomState } from "./state";
import { CanvasObjectModel } from "@/hooks/use-send-websocket-msg";
import { serializeFabricElementWithCustomAttributes } from "@/utils/fabric-utils";
import { ExposureDetailType } from "@/views/teacher-class/components/lesson-plan/lesson-plan";
import { vuexName, VuexNames } from "@/store/utils";

const actions: ActionTree<TeacherRoomState, any> = {
  async endClass({ dispatch, state, commit }, payload: DefaultPayload) {
    if (state.info) {
      const { markAsComplete } = payload;
      await RemoteTeachingService.teacherEndClassRoom(state.info?.id, markAsComplete);
      commit("setIsSessionEnded", true);
    }
    await dispatch("resetClass");
    commit("teacher/setIgnoreHelperRequestJoinClass", false, { root: true });
  },
  /** Reset class's state in local */
  async resetClass({ commit }, endClass = true) {
    if (endClass) {
      commit("endClass");
    } else {
      commit("leaveClass");
    }
    commit("lesson/resetState", null, { root: true });
    commit("calling/resetState", null, { root: true });
    commit("classTeaching/endClass", null, { root: true });
  },
  async helperExitClass({ commit, state, dispatch }, byTeacherRemove: boolean) {
    if (state.info && !byTeacherRemove) {
      try {
        await HelperService.exitSession();
      } catch (error) {
        Logger.error(error);
      }
    }
    await dispatch("resetClass");
    commit("teacher/setIsHelper", false, { root: true });
  },
  setClassViewAsync({ state, commit }, payload: ClassViewPayload) {
    commit("setClassView", payload);
    state.manager?.WSClient.sendRequestSetTeachingMode(ValueOfClassView(payload.classView));
  },
  setUser({ commit }, payload: UserModel) {
    commit("setUser", payload);
  },
  setError(store, payload: GLError | null) {
    store.commit("setError", payload);
  },
  async updateAudioAndVideoFeed({ state, rootGetters }, options?: { studentCameras: string[] }) {
    const { manager, students, idOne, idOneWithAnotherTeacher, teacher, helper, isDisconnected: localTeacherDisconnected } = state;
    if (!teacher || !manager?.agoraClient?.joined) return;
    if (localTeacherDisconnected) return manager?.updateAudioAndVideoFeed([], []);
    const userId: string | undefined = rootGetters["auth/userId"];
    const hasHelper = !!helper;
    const isHelper = hasHelper && userId === helper.id;
    let cameras = students.filter((s) => s.videoEnabled && rootGetters["calling/checkCallingHasUserById"](s.id)).map((s) => s.id);
    let audios = students.filter((s) => s.audioEnabled && rootGetters["calling/checkCallingHasUserById"](s.id)).map((s) => s.id);
    if (options?.studentCameras) {
      cameras = options.studentCameras;
    }
    // * Should teacher subscribe helper's audio and video
    if (hasHelper && !isHelper && rootGetters["calling/checkCallingHasUserById"](helper.id)) {
      if (helper.videoEnabled && helper.isVideoShownByTeacher) {
        cameras.push(helper.id);
      }
      if (helper.audioEnabled) {
        audios.push(helper.id);
      }
    }
    // * vice versa
    if (isHelper && rootGetters["calling/checkCallingHasUserById"](teacher.id)) {
      if (teacher.videoEnabled) {
        cameras.push(teacher.id);
      }
      if (teacher.audioEnabled) {
        audios.push(teacher.id);
      }
    }
    if (idOne) {
      const clonedCameras = [...cameras];
      clonedCameras.forEach((cameraId) => {
        if (cameraId !== idOne && cameraId !== teacher.id && cameraId !== helper?.id) {
          const index = cameras.findIndex((id) => id === cameraId);
          if (index > -1) {
            cameras.splice(index, 1);
          }
        }
      });
      const shouldIgnoreTeacherVoice = rootGetters["classTeaching/getOneToOneWithHelperIgnoreTeacherVoice"];
      const clonedAudios = [...audios];
      clonedAudios.forEach((audioId) => {
        if (audioId !== idOne) {
          if (isHelper && audioId === teacher.id && !shouldIgnoreTeacherVoice) return;
          const index = audios.findIndex((id) => id === audioId);
          if (index > -1) {
            audios.splice(index, 1);
          }
        }
      });
    }
    if (idOneWithAnotherTeacher) {
      cameras = cameras.filter((camId) => camId !== idOneWithAnotherTeacher);
      audios = audios.filter((audioId) => {
        let shouldSubscribe = true;
        if (audioId === idOneWithAnotherTeacher || (isHelper && audioId === teacher.id) || (!isHelper && audioId === helper?.id)) {
          shouldSubscribe = false;
        }
        return shouldSubscribe;
      });
    }
    return manager.updateAudioAndVideoFeed([...new Set(cameras)], [...new Set(audios)]);
  },
  async leaveRoom({ state, commit, dispatch, rootGetters }, _payload: { leave: boolean }) {
    const checkMessageTimer = rootGetters["checkMessageVersionTimer"];
    if (checkMessageTimer) {
      clearInterval(checkMessageTimer);
    }
    dispatch("setCheckMessageVersionTimer", -1, { root: true });
    await state.manager?.close(_payload.leave);
    // reset module's state
    commit("leaveClass");
    commit("classTeaching/endClass", null, { root: true });
  },
  async joinWSRoom(store, rejoin = false) {
    if (!store.state.info || !store.state.manager) return;
    const { teacher } = store.state;
    const userId: string | undefined = store.rootGetters["auth/userId"];
    const isHelper = teacher && userId !== teacher?.id;
    const deviceId = getDeviceId();
    const isMuteAudio = store.rootGetters["isMuteAudio"];
    const isHideVideo = store.rootGetters["isHideVideo"];
    store.state.manager?.WSClient.sendRequestJoinRoom(store.state.info.id, deviceId, isMuteAudio, isHideVideo, isHelper);
    if (rejoin) return;
    const eventHandler = useTeacherRoomWSHandler(store);
    store.state.manager?.registerEventHandler(eventHandler);
    store.dispatch("setMuteAudio", { status: MediaStatus.noStatus }, { root: true });
    store.dispatch("setHideVideo", { status: MediaStatus.noStatus }, { root: true });
  },
  async joinRoom(store, payload: any) {
    const { state, rootGetters } = store;
    const needConfirmToHandOver = rootGetters["getReplacedClassId"];
    if (needConfirmToHandOver) return;
    if (!state.info || !state.teacher || !state.manager) return;
    const userId: string | undefined = store.rootGetters["auth/userId"];
    const isHelper = state.helper?.id === userId;
    let cameraStatus = isHelper ? state.helper?.videoEnabled : state.teacher?.videoEnabled;
    let microphoneStatus = isHelper ? state.helper?.audioEnabled : state.teacher?.audioEnabled;
    const isMuteAudio = rootGetters["isMuteAudio"];
    if (isMuteAudio === MediaStatus.mediaLocked) {
      microphoneStatus = false;
    } else if (isMuteAudio === MediaStatus.mediaNotLocked) {
      microphoneStatus = true;
    }

    const isHideVideo = store.rootGetters["isHideVideo"];
    if (isHideVideo === MediaStatus.mediaLocked) {
      cameraStatus = false;
    } else if (isHideVideo === MediaStatus.mediaNotLocked) {
      cameraStatus = true;
    }
    let callingEventHandlers: AgoraEventHandler | null = null;
    if (rootGetters["platform"] == VCPlatform.Agora) {
      callingEventHandlers = {
        onLocalNetworkUpdate: (payload: NetworkQualityPayload) => {
          bandwidthUpdateBasedOnAgora(payload);
        },
        onUserJoined: ({ uid }: IAgoraRTCRemoteUser) => {
          const id = typeof uid === "string" ? uid : uid.toString();
          remoteUserJoined(id);
        },
        onUserLeft: async ({ uid }: IAgoraRTCRemoteUser, reason: string) => {
          const id = typeof uid === "string" ? uid : uid.toString();
          await remoteUserLeft(id);
        },
        onConnectionStateChange: (curState: ConnectionState, prevState: ConnectionState, reason: ConnectionDisconnectedReason | undefined) => {
          localUserConnectionChanged(curState, prevState);
        },
        onException: (payload) => {
          //
        },
      };
    }
    const { bandwidthUpdateBasedOnAgora, remoteUserJoined, remoteUserLeft, localUserConnectionChanged } = callingUtils(store);
    await state.manager?.join({
      camera: cameraStatus,
      microphone: microphoneStatus,
      classId: state.info.id,
      teacherId: state.user?.id,
      idOne: state.idOne,
      isMirror: state.info.isTeacherVideoMirror,
      isRemoteMirror: state.info.isStudentVideoMirror,
      callingEventHandlers,
    });
    await store.dispatch("upToDateSessionAfterSignalRConnected");
  },
  async upToDateSessionAfterSignalRConnected({ rootState, commit, dispatch }) {
    let isCurrentUserInOneMode = false;
    const isHelper = rootState.teacher.isHelper;
    const sessionId = rootState.classTeaching.sessionId;
    /** Archive helper data */
    const setOneToOneWithHelperData = (oneToOneWithHelperDto: OneToOneDto) => {
      const { isIgnoreTeacherAudio } = oneToOneWithHelperDto;
      commit("classTeaching/setOneToOneWithHelperIgnoreTeacherVoice", isIgnoreTeacherAudio, { root: true });
    };
    let resp: FetchSessionDtoResModel | null = null;
    try {
      resp = await RemoteTeachingService.fetchSessionDtoById(sessionId);
    } catch (e) {
      Logger.error(e);
      return;
    }
    if (isHelper) {
      resp = transformOneToOneDataServeHelper(resp);
    } else {
      resp = transformOneToOneDataServeTeacher(resp);
    }
    const roomInfo = resp.data;
    /** If current lesson is "alternative" type, fetch media state to update the media's progress */
    commit("whiteboard/setShouldFetchMediaState", true, { root: true });
    commit("classTeaching/setMode", roomInfo.independentMode ? TeachingMode.Independent : TeachingMode.Normal, { root: true });
    commit("teams/setIsTeamMode", roomInfo.isTeamMode, { root: true });
    if (isHelper && roomInfo.studentHelperOneToOne) {
      setOneToOneWithHelperData(roomInfo.helperOneAndOneDto);
    }
    if (roomInfo.helper) {
      commit("teacher/setIgnoreHelperRequestJoinClass", true, { root: true });
    }
    commit("setRoomInfo", { ...roomInfo, options: { isActionUpToDate: true, isHelper } });
    await dispatch("setVideoCallPlatform", roomInfo.videoPlatformProvider, { root: true });
    if (isHelper) {
      commit(`annotation/${roomInfo.studentHelperOneToOne ? "setInfoOneMode" : "setInfo"}`, roomInfo.annotation, { root: true });
    } else {
      commit(`annotation/${roomInfo.studentOneToOne ? "setInfoOneMode" : "setInfo"}`, roomInfo.annotation, { root: true });
    }
    commit(
      "annotation/setDrawings",
      (!isHelper && roomInfo.studentOneToOne ? roomInfo.annotation?.oneOneDrawing?.fabrics : roomInfo.annotation?.drawing.fabrics) ?? [],
      { root: true },
    );
    await dispatch("interactive/setInfo", roomInfo.lessonPlan.interactive, {
      root: true,
    });
    await dispatch("setStudentOneWithAnotherTeacherId", { id: roomInfo.oneToOneWithAnotherTeacherStudentId });
    const { oneAndOneDto, studentOneToOne } = roomInfo;
    if (oneAndOneDto) {
      isCurrentUserInOneMode = true;
      const { exposureSelected, itemContentSelected, isShowWhiteBoard } = oneAndOneDto;
      commit("classTeaching/setMyOneAndOneData", oneAndOneDto, { root: true });
      commit(
        "lesson/setCurrentExposure",
        {
          id: exposureSelected,
          preventSelectFirstSlideAutomatically: !!itemContentSelected,
        },
        { root: true },
      );
      commit(
        "lesson/setCurrentExposureItemMedia",
        {
          id: itemContentSelected,
        },
        { root: true },
      );
      commit("setWhiteboard", isShowWhiteBoard);
      commit("updateIsPalette", { id: oneAndOneDto.id, isPalette: oneAndOneDto.isEnablePalette });
    } else {
      commit("setWhiteboard", roomInfo.isShowWhiteBoard);
    }
    if (studentOneToOne) {
      await dispatch("teacherRoom/setStudentOneId", { id: studentOneToOne }, { root: true });
      await dispatch("lesson/setZoomRatio", oneAndOneDto.ratio ? oneAndOneDto.ratio : MIN_ZOOM_RATIO, {
        root: true,
      });
      await dispatch("lesson/setPdfScrollProgress", oneAndOneDto.ratioScrollPdf ?? 0, {
        root: true,
      });
      if (oneAndOneDto.position) {
        await dispatch(
          "lesson/setImgCoords",
          {
            x: oneAndOneDto.position.x,
            y: oneAndOneDto.position.y,
          },
          { root: true },
        );
      }
    } else {
      await dispatch("lesson/setBothExposureAndSlide", roomInfo.lessonPlan, { root: true });
      await dispatch("lesson/setZoomRatio", roomInfo.lessonPlan.ratio ? roomInfo.lessonPlan.ratio : MIN_ZOOM_RATIO, { root: true });
      await dispatch("lesson/setPdfScrollProgress", roomInfo.ratioScrollPdf ?? 0, { root: true });
      if (roomInfo.lessonPlan.position) {
        await dispatch(
          "lesson/setImgCoords",
          {
            x: roomInfo.lessonPlan.position.x,
            y: roomInfo.lessonPlan.position.y,
          },
          { root: true },
        );
      }
      await dispatch("teacherRoom/setStudentOneId", { id: "" }, { root: true });
    }
    await dispatch(
      vuexName(VuexNames.LESSON.DISPATCHES.PROCESS_VISIBLE_TARGETS_DATA_FROM_API),
      isCurrentUserInOneMode ? roomInfo.annotation?.oneOneDrawing?.visibleShapes : roomInfo.annotation?.drawing?.visibleShapes,
      {
        root: true,
      },
    );
  },
  async initClassRoom({ commit, dispatch, rootState }, payload: InitClassRoomPayload) {
    try {
      let isCurrentUserInOneMode = false;
      /** Archive independent mode data */
      const setIndependentModeData = async (currentUnit: number, currentLesson: number) => {
        let response: null | GetIndependentStudentsResponse;
        if (payload.isHelper) {
          response = await IndependentService.helperGetIndependentStudentsItem();
        } else {
          response = await IndependentService.getIndependentStudentsItem();
        }
        commit("classTeaching/setMode", TeachingMode.Independent, { root: true });
        commit("teacherTeaching/setIndependentListStudentItems", response.data, { root: true });
        commit("classTeaching/setIndependentModeCurrentUnit", currentUnit, { root: true });
        commit("classTeaching/setIndependentModeCurrentLesson", currentLesson, { root: true });
      };
      const setClassTeachingData = (sessionInfo: { sessionId: string; groupId: string; classId: string }) => {
        commit("classTeaching/setSessionInfo", sessionInfo, { root: true });
      };
      //** This is the only time to check if the teacher has lost connection using our server, then it will rely on AGORA */
      const checkTeacherDisconnect = (teacherCallingConnectionStatus: CallingConnectionStatus) => {
        commit("setTeacherDisconnected", teacherCallingConnectionStatus === CallingConnectionStatus.Disconnected);
      };
      /** Archive helper data */
      const setOneToOneWithHelperData = (oneToOneWithHelperDto: OneToOneDto) => {
        const { isIgnoreTeacherAudio } = oneToOneWithHelperDto;
        commit("classTeaching/setOneToOneWithHelperIgnoreTeacherVoice", isIgnoreTeacherAudio, { root: true });
      };
      /** Archive team mode data */
      const setTeamModeData = async (isTeamMode: boolean) => {
        commit("teams/setIsTeamMode", isTeamMode, { root: true });
        await dispatch("teams/getTeams", null, { root: true });
      };
      /** Init the class */
      commit("setUser", { id: payload.userId, name: payload.userName });
      let roomResponse: FetchSessionDtoResModel;
      if (payload.isHelper && payload.groupId) {
        roomResponse = await HelperService.fetchSessionDataAsHelper(payload.groupId, payload.deviceId);
      } else {
        roomResponse = await RemoteTeachingService.fetchSessionDataAsTeacher(payload.deviceId);
      }
      const roomInfo: RoomModel = roomResponse.data;
      if (roomInfo?.classInfo?.classId !== payload.classId) {
        commit("setError", {
          errorCode: GLErrorCode.CLASS_IS_NOT_ACTIVE,
          message: fmtMsg(ErrorLocale.ClassNotStarted),
        });
        return Promise.reject(GLErrorCode.CLASS_IS_NOT_ACTIVE);
      }
      if (payload.isHelper && payload.callFirstTime) {
        checkTeacherDisconnect(roomInfo.teacher.callingConnectionStatus);
      }
      setClassTeachingData({ sessionId: roomInfo.id, classId: roomInfo.classInfo.classId, groupId: roomInfo.classInfo.groupId });
      /** If current lesson is "alternative" type, fetch media state to update the media's progress */
      store.commit("whiteboard/setShouldFetchMediaState", true, { root: true });
      await setTeamModeData(roomInfo.isTeamMode);
      if (payload.isHelper && roomInfo.studentHelperOneToOne) {
        setOneToOneWithHelperData(roomInfo.helperOneAndOneDto);
      }
      commit("setParamsForHelperJoinSession", {
        deviceId: payload.deviceId,
        groupId: payload.groupId || "",
      });
      if (roomInfo.helper) {
        commit("teacher/setIgnoreHelperRequestJoinClass", true, { root: true });
      }
      commit("setRoomInfo", roomInfo);
      await store.dispatch("setVideoCallPlatform", roomInfo.videoPlatformProvider, { root: true });
      if (payload.isHelper) {
        commit(`annotation/${roomInfo.studentHelperOneToOne ? "setInfoOneMode" : "setInfo"}`, roomInfo.annotation, { root: true });
      } else {
        commit(`annotation/${roomInfo.studentOneToOne ? "setInfoOneMode" : "setInfo"}`, roomInfo.annotation, { root: true });
      }
      commit(
        "annotation/setDrawings",
        (!payload.isHelper && roomInfo.studentOneToOne ? roomInfo.annotation?.oneOneDrawing?.fabrics : roomInfo.annotation?.drawing.fabrics) ?? [],
        { root: true },
      );
      await dispatch(
        "lesson/setInfo",
        {
          lessonPlan: roomInfo.lessonPlan,
          isSetCurrentExposure: roomInfo.lessonPlan.contentSelected && !roomInfo.studentOneToOne,
        },
        { root: true },
      );
      await dispatch("interactive/setInfo", roomInfo.lessonPlan.interactive, {
        root: true,
      });
      if (roomInfo.independentMode) {
        await setIndependentModeData(roomInfo.classInfo.unit, roomInfo.classInfo.lesson);
      }
      await dispatch("setStudentOneWithAnotherTeacherId", { id: roomInfo.oneToOneWithAnotherTeacherStudentId });
      const studentOneToOne = roomInfo.studentOneToOne;
      const { oneAndOneDto } = roomInfo;
      if (oneAndOneDto) {
        isCurrentUserInOneMode = true;
        const { exposureSelected, itemContentSelected, isShowWhiteBoard } = oneAndOneDto;
        store.commit("classTeaching/setMyOneAndOneData", oneAndOneDto, { root: true });
        store.commit("lesson/setCurrentExposure", {
          id: exposureSelected,
          preventSelectFirstSlideAutomatically: !!itemContentSelected,
        });
        store.commit("lesson/setCurrentExposureItemMedia", {
          id: itemContentSelected,
        });
        commit("setWhiteboard", isShowWhiteBoard);
      } else {
        commit("setWhiteboard", roomInfo.isShowWhiteBoard);
      }
      if (studentOneToOne) {
        await dispatch("teacherRoom/setStudentOneId", { id: studentOneToOne }, { root: true });
        await dispatch("lesson/setZoomRatio", oneAndOneDto.ratio ? oneAndOneDto.ratio : MIN_ZOOM_RATIO, {
          root: true,
        });
        await dispatch("lesson/setPdfScrollProgress", oneAndOneDto.ratioScrollPdf ?? 0, {
          root: true,
        });
        if (oneAndOneDto.position) {
          await dispatch(
            "lesson/setImgCoords",
            {
              x: oneAndOneDto.position.x,
              y: oneAndOneDto.position.y,
            },
            { root: true },
          );
        }
      } else {
        await dispatch("lesson/setZoomRatio", roomInfo.lessonPlan.ratio ? roomInfo.lessonPlan.ratio : MIN_ZOOM_RATIO, { root: true });
        await dispatch("lesson/setPdfScrollProgress", roomInfo.ratioScrollPdf ?? 0, { root: true });
        if (roomInfo.lessonPlan.position) {
          await dispatch(
            "lesson/setImgCoords",
            {
              x: roomInfo.lessonPlan.position.x,
              y: roomInfo.lessonPlan.position.y,
            },
            { root: true },
          );
        }
        await dispatch("teacherRoom/setStudentOneId", { id: "" }, { root: true });
      }
      await dispatch(
        vuexName(VuexNames.LESSON.DISPATCHES.PROCESS_VISIBLE_TARGETS_DATA_FROM_API),
        isCurrentUserInOneMode ? roomInfo.annotation?.oneOneDrawing?.visibleShapes : roomInfo.annotation?.drawing?.visibleShapes,
        {
          root: true,
        },
      );
      commit(
        vuexName(VuexNames.CLASS_TEACHING.COMMITS.SET_INITIAL_CLASS_DATA_SNAPSHOT),
        {
          exposureId: roomInfo.lessonPlan.contentSelected,
        },
        {
          root: true,
        },
      );
    } catch (err) {
      Logger.error(err);
      if (rootState.teacherRoom.isDisconnected) return;
      if (err.code === ErrorCode.ConcurrentUserException) {
        await dispatch(
          "modal/confirmReplacementSession",
          async () => {
            const resp = payload.isHelper
              ? await RemoteTeachingService.sessionReplacementAccept4Helper(getDeviceId())
              : await RemoteTeachingService.sessionReplacementAccept4Teacher(getDeviceId());
            if (resp?.success) location.reload();
          },
          { root: true },
        );
        return;
      }
      await router.push(Paths.Teacher);
    }
  },
  async forceOutHelperOneToOne({ rootGetters, dispatch }) {
    const isHelper = rootGetters["teacher/isHelper"];
    const idOneWithAnotherTeacher = rootGetters["teacherRoom/getStudentModeOneWithAnotherTeacherId"];
    const idOne = rootGetters["teacherRoom/getStudentModeOneId"];

    if ((idOneWithAnotherTeacher && !isHelper) || (isHelper && idOne)) {
      await dispatch("sendOneAndOne", {
        status: false,
        id: null,
        isHelper: true,
      });
    }
  },
  setSpeakingUsers({ commit }, payload: { level: number; uid: UID }[]) {
    const validSpeakings: Array<string> = [];
    if (payload) {
      payload.map((item) => {
        if (item.level >= AGORA_MINIMUM_USER_VOLUME) {
          // should check by a level
          validSpeakings.push(item.uid?.toString());
        }
      });
    }
    commit("setSpeakingUsers", { userIds: validSpeakings });
  },
  async setStudentAudio({ state, commit }, payload: UserMediaPayload) {
    try {
      await state.manager?.WSClient.sendRequestMuteStudentAudio(payload.id, !payload.enable);
    } catch (error) {
      Logger.error(error);
    }
  },
  async setStudentVideo({ state, commit }, payload: UserMediaPayload) {
    try {
      await state.manager?.WSClient.sendRequestMuteStudentVideo(payload.id, !payload.enable);
    } catch (error) {
      Logger.error(error);
    }
  },
  async toggleAnnotation({ state, commit }, payload: { studentId: string; isEnable: boolean }) {
    commit("setStudentPalette", payload);
    state.manager?.WSClient.sendRequestToggleAnnotation(payload.studentId, payload.isEnable);
  },
  setStudentBadge({ state }, payload: StudentBadgePayload) {
    state.manager?.WSClient.sendRequestSetStudentBadge([payload.id], payload.badge);
  },
  async setAllStudentBadge({ state }) {
    state.manager?.WSClient.sendRequestSetStudentBadge([], 1);
  },
  async setTeacherAudio({ state, commit }, payload: DeviceMediaPayload) {
    if (state.microphoneLock) return;
    commit("setMicrophoneLock", { enable: true });
    try {
      await state.manager?.setMicrophone({ enable: payload.enable });
      await state.manager?.WSClient.sendRequestMuteAudio(!payload.enable);
      commit("setTeacherAudio", payload);
    } catch (err) {
      Logger.error(err);
      notification.error({
        message: fmtMsg(ErrorLocale.ToggleMicroError),
      });
    } finally {
      commit("setMicrophoneLock", { enable: false });
    }
  },

  async setTeacherVideo({ state, commit }, payload: DeviceMediaPayload) {
    if (state.cameraLock) return;
    commit("setCameraLock", { enable: true });
    try {
      await state.manager?.setCamera({
        enable: payload.enable,
        videoEncoderConfigurationPreset: "480p",
      });
      await state.manager?.WSClient.sendRequestMuteVideo(!payload.enable);
      commit("setTeacherVideo", payload);
    } catch (err) {
      Logger.error(err);
      notification.error({
        message: fmtMsg(ErrorLocale.ToggleCameraError),
      });
    } finally {
      commit("setCameraLock", { enable: false });
    }
  },
  // TODO: handle for old mobile app version, remove after force update version released
  hideAllStudents({ state }) {
    state.manager?.WSClient.sendRequestMuteAllStudentVideo(true);
  },
  // TODO: handle for old mobile app version, remove after force update version released
  showAllStudents({ state }) {
    state.manager?.WSClient.sendRequestMuteAllStudentVideo(false);
  },
  // TODO: handle for old mobile app version, remove after force update version released
  muteAllStudents({ state }) {
    state.manager?.WSClient.sendRequestMuteAllStudentAudio(true);
  },
  // TODO: handle for old mobile app version, remove after force update version released
  unmuteAllStudents({ state }) {
    state.manager?.WSClient.sendRequestMuteAllStudentAudio(false);
  },
  disableAllStudents({ commit, state }) {
    commit("disableAllStudents", {});
    state.manager?.WSClient.sendRequestDisableAllAnnotation(true);
  },
  disableAllStudentsPalette({ commit, state }) {
    commit("disableAllStudentsPalette", {});
    state.manager?.WSClient.sendRequestResetPaletteAllStudent(true);
  },

  enableAllStudents({ state, commit }) {
    commit("enableAllStudents", {});
    state.manager?.WSClient.sendRequestDisableAllAnnotation(false);
  },
  setStudentConnectionStatus(store, payload: UserIdPayload) {
    store.commit("setStudentConnectionStatus", payload);
  },
  studentLeftClass(store, payload: UserIdPayload) {
    store.commit("studentLeftClass", payload);
  },
  studentLeaving(store, payload: UserIdPayload) {
    store.commit("studentLeaving", payload);
  },
  studentRaisingHand(store, payload: { id: string; raisingHand: boolean }) {
    store.commit("studentRaisingHand", payload);
  },
  addGlobalAudio({ state }, payload: UserIdPayload) {
    state.manager?.WSClient.sendRequestAddGlobalAudio(payload.id);
  },
  clearGlobalAudio({ state }, _payload: any) {
    state.manager?.WSClient.sendRequestClearGlobalAudio();
  },
  addStudentAudio({ state }, payload: UserIdPayload) {
    state.manager?.WSClient.sendRequestAddStudentAudio(payload.id);
  },
  clearStudentAudio({ state }, _payload: any) {
    state.manager?.WSClient.sendRequestClearStudentAudio();
  },
  setBlackOut({ state }, payload: { isBlackOut: boolean }) {
    state.manager?.WSClient.sendRequestBlackOutLessonContent(payload.isBlackOut);
  },
  async setCurrentExposure({ state, commit }, payload: { id: string }) {
    try {
      await state.manager?.WSClient.sendRequestStartLessonContent(payload.id);
      commit("lesson/setCurrentExposure", { id: payload.id }, { root: true });
    } catch (error) {
      Logger.error(error);
    }
  },
  endExposure({ state }, payload: { id: string }) {
    state.manager?.WSClient.sendRequestEndLessonContent(payload.id);
  },
  async changeRemoteExposureItem({ state }, payload: { id: string }) {
    try {
      await state.manager?.WSClient.sendRequestSetLessonItemContent(payload.id);
    } catch (error) {
      Logger.error(error);
      await state.manager?.WSClient.sendRequestSetLessonItemContent(payload.id);
    }
  },
  clearStudentRaisingHand({ state }, payload: { id: string }) {
    const student = state.students.find((e) => e.id === payload.id && e.raisingHand);
    if (student) state.manager?.WSClient.sendRequestClearRaisingHand(payload.id);
  },
  setClassAction({ state }, payload: { action: number }) {
    state.manager?.WSClient.sendRequestSetClassAction(payload.action);
  },
  async teacherAnswer(
    { state },
    payload: {
      x: number;
      y: number;
      contentId: string;
    },
  ) {
    await state.manager?.WSClient.sendRequestAnswer(payload);
  },
  async setPointer({ state, getters }, payload: { x: number; y: number }) {
    if (getters["isTeacherUseOnly"]) {
      if (getters["getTeacherOrHelperOneToOne"]) return;
      await state.manager?.WSClient.sendRequestTeacherUseOnlySetPointer(payload);
    } else {
      await state.manager?.WSClient.sendRequestSetPointer(payload);
    }
  },
  async setMode({ state, getters }, payload: { mode: number }) {
    const isTeacherUseOnly = getters["isTeacherUseOnly"];
    if (isTeacherUseOnly) {
      await state.manager?.WSClient.sendRequestTeacherUseOnlyUpdateAnnotationMode(payload.mode);
    } else {
      await state.manager?.WSClient.sendRequestUpdateAnnotationMode(payload.mode);
    }
  },
  async sendLastLineAsync({ state, getters }, payload: { drawing: string }) {
    if (!state.info) return;
    try {
      await RemoteTeachingService.teacherDrawLine(
        JSON.stringify(serializeFabricElementWithCustomAttributes(payload.drawing)),
        state.info.id,
        getters["isTeacherUseOnly"],
      );
    } catch (e) {
      Logger.log(e);
    }
  },
  async requestClearBoardAsync({ state, getters }, payload: {}) {
    const isTeacherUseOnly = getters["isTeacherUseOnly"];
    if (isTeacherUseOnly) {
      await state.manager?.WSClient.sendRequestTeacherUseOnlyClearAllBrush(payload);
    } else {
      await state.manager?.WSClient.sendRequestClearAllBrush(payload);
    }
  },
  async setResetZoom({ state }, payload: any) {
    await state.manager?.WSClient.sendRequestResetZoom(payload);
  },
  async setZoomSlide({ state, getters }, payload: { ratio: number; position: { x: number; y: number } }) {
    await state.manager?.WSClient.sendRequestZoomSlide(payload);
  },
  async setMoveZoomedSlide({ state, getters }, payload: { x: number; y: number; viewPortX: number; viewPortY: number }) {
    const isTeacherUseOnly = getters["isTeacherUseOnly"];
    if (isTeacherUseOnly) {
      await state.manager?.WSClient.sendRequestTeacherUseOnlyMoveZoomedSlide(payload);
    } else {
      await state.manager?.WSClient.sendRequestMoveZoomedSlide(payload);
    }
  },
  async setDeleteBrush({ state, getters }, payload: {}) {
    const isTeacherUseOnly = getters["isTeacherUseOnly"];
    if (isTeacherUseOnly) {
      await state.manager?.WSClient.sendRequestTeacherUseOnlyDeleteBrush(payload);
    } else {
      await state.manager?.WSClient.sendRequestDeleteBrush(payload);
    }
  },
  async setStickers({ state }, payload: { stickers: Array<Sticker> }) {
    await state.manager?.WSClient.sendRequestSetStickers(payload.stickers);
  },
  async setClearStickers({ state }, payload: {}) {
    await state.manager?.WSClient.sendRequestClearStickers(payload);
  },
  // async sendUnity({ state }, payload: {message: string}) {
  //   await state.manager?.WSClient.sendRequestUnity(payload.message);
  // },
  async sendOneAndOne({ state }, payload: { status: boolean; id: string; isHelper: boolean }) {
    await state.manager?.WSClient.sendRequestSetOneToOne(payload);
  },
  setStudentOneId({ commit }, p: { id: string }) {
    commit("setStudentOneId", p);
  },
  setStudentOneWithAnotherTeacherId({ commit }, p: { id: string }) {
    commit("setStudentOneWithAnotherTeacherId", p);
  },
  setWhiteboard({ state }, { isShowWhiteBoard }: { isShowWhiteBoard: boolean }) {
    state.manager?.WSClient.sendRequestSetWhiteboard(isShowWhiteBoard);
  },
  async setMediaState({ rootState }, payload: TeacherUpdateMediaStateRequestModel) {
    const sessionId = rootState.classTeaching.sessionId;
    await RemoteTeachingService.teacherUpdateMediaState(sessionId, payload);
  },
  setCurrentTimeMedia({ state }, payload: any) {
    state.manager?.WSClient.sendRequestSetCurrentTimeMedia(payload);
  },
  setLaserPath({ state, getters }, payload: string) {
    if (getters["isTeacherUseOnly"]) {
      if (getters["getTeacherOrHelperOneToOne"]) return;
      state.manager?.WSClient.sendRequestTeacherUseOnlyDrawLaser(payload);
    } else {
      state.manager?.WSClient.sendRequestDrawLaser(payload);
    }
  },
  setOnline({ commit }) {
    commit("setOnline");
  },
  setOffline({ commit, rootState }) {
    if (rootState.app.userRole === UserRole.Teacher) {
      commit("setOffline");
    }
  },
  setListStudentLowBandWidth({ commit }, p: string[]) {
    commit("setListStudentLowBandWidth", p);
  },
  async setShapesForStudent({ state, getters }, payload: Array<string>) {
    if (!state.info) return;
    const isTeacherUseOnly = getters["isTeacherUseOnly"];
    try {
      await RemoteTeachingService.teacherAddShape(payload, state.info.id, isTeacherUseOnly);
    } catch (e) {
      Logger.log(e);
    }
  },
  async getAvatarTeacher({ commit }, payload: { teacherId: string }) {
    const response = await InfoService.fetchTeacherAvatarUrl(payload.teacherId);
    if (response) commit("setAvatarTeacher", response);
  },
  async getAvatarHelper({ commit }, payload: { helperId: string }) {
    const response = await InfoService.fetchTeacherAvatarUrl(payload.helperId);
    if (response) commit("setAvatarHelper", response);
  },
  teacherCreateFabricObject({ state, getters }, payload: any) {
    const { objectId } = payload;
    const fabricObject: FabricObject = {
      fabricId: objectId,
      fabricData: JSON.stringify(serializeFabricElementWithCustomAttributes(payload)),
    };
    const isTeacherUseOnly = getters["isTeacherUseOnly"];
    if (isTeacherUseOnly) {
      state.manager?.WSClient.sendRequestTeacherUseOnlyCreateFabricObject(fabricObject);
    } else {
      state.manager?.WSClient.sendRequestCreateFabricObject(fabricObject);
    }
  },
  teacherModifyFabricObject({ state, getters }, payload: any) {
    const { objectId } = payload;
    const fabricObject: FabricObject = {
      fabricId: objectId,
      fabricData: JSON.stringify(serializeFabricElementWithCustomAttributes(payload)),
    };
    const isTeacherUseOnly = getters["isTeacherUseOnly"];
    if (isTeacherUseOnly) {
      state.manager?.WSClient.sendRequestTeacherUseOnlyModifyFabricObject(fabricObject);
    } else {
      state.manager?.WSClient.sendRequestModifyFabricObject(fabricObject);
    }
  },
  async generateOneToOneToken({ state }, payload: { classId: string; userId: string }) {
    try {
      const zoom = state.manager?.zoomClient;
      if (zoom) {
        await zoom.teacherJoinOneToOneSubSession(payload.userId);
        await store.dispatch("teacherRoom/sendOneAndOne", {
          status: true,
          id: payload.userId,
        });
      }
    } catch (error) {
      Logger.log(error);
    }
  },
  async setLessonAndUnit(
    { commit, state, dispatch, rootGetters, getters },
    p: {
      unit: number;
      lesson: number;
      unitId: number;
      isCompleted: boolean;
    } | null,
  ) {
    if (!state.info?.id) {
      return;
    }

    const updateState = async (roomInfo: RoomModel) => {
      commit({ type: "lesson/clearLessonData" }, { root: true });
      commit("setRoomInfo", roomInfo);
      await dispatch(
        "lesson/setInfo",
        {
          lessonPlan: roomInfo.lessonPlan,
          isSetCurrentExposure: roomInfo.lessonPlan.contentSelected && !roomInfo.studentOneToOne,
        },
        { root: true },
      );
      await dispatch("lesson/setZoomRatio", MIN_ZOOM_RATIO, {
        root: true,
      });
      await dispatch("lesson/setPdfScrollProgress", 0, {
        root: true,
      });
      await dispatch("lesson/setImgCoords", undefined, { root: true });
    };

    if (p) {
      const data: UpdateLessonAndUnitModel = {
        unit: p.unit,
        lesson: p.lesson,
        unitId: p.unitId,
        sessionId: state.info?.id as string,
        isCompleted: p.isCompleted,
      };
      const roomInfo = await RemoteTeachingService.teacherUpdateLessonAndUnit(data);
      // if (p.isCompleted) {
      //   const contents = state.info?.lessonPlan?.contents;
      //   for (const content of contents ?? []) {
      //     await dispatch("endExposure", { id: content.id });
      //   }
      // }
      await updateState(roomInfo);
      await state.manager?.WSClient.sendRequestUpdateSessionAndUnit({});
    } else {
      let roomResponse = null;
      const params: ParamsToJoinCurSessionInterface = getters["getParamsToJoinCurSession"];
      const isHelper = rootGetters["teacher/isHelper"];
      if (isHelper) {
        roomResponse = await HelperService.fetchSessionDataAsHelper(params.groupId, params.deviceId);
      } else {
        roomResponse = await RemoteTeachingService.fetchSessionDataAsTeacher(params.deviceId);
      }
      const roomInfo: RoomModel = roomResponse.data;
      await updateState(roomInfo);
    }
  },
  async sendRequestCaptureImage({ state }, payload: { isCaptureAll: boolean; studentId: string }) {
    await state.manager?.WSClient.sendRequestCaptureImage(payload);
  },
  async getStudentCapturedImages(
    { getters, commit },
    p: {
      token: string;
      schoolId: string;
      classId: string;
      groupId: string;
      studentId: string;
      date: string;
      filterMode: number;
    },
  ) {
    try {
      const result = await StudentStorageService.getFiles(p.token, p.schoolId, p.classId, p.groupId, p.studentId, p.date, p.filterMode);
      if (result.length) {
        const data = result.sort((a, b) => (a.tags.dateTime > b.tags.dateTime ? -1 : 1));
        commit("setStudentsImageCaptured", data);
      }
    } catch (error) {
      Logger.error(error);
    }
  },
  async removeStudentImage({ getters }, p: { token: string; fileName: string }) {
    try {
      await StudentStorageService.removeFile(p.token, p.fileName);
    } catch (error) {
      Logger.error(error);
      notification.error({
        message: error.error,
        duration: 3,
      });
    }
  },
  async setRoomInfo({ commit }, p: FetchSessionDtoResModel) {
    commit("setRoomInfo", p);
  },
  setStudentsImageCaptured({ commit }, p: Array<BlobTagItem>) {
    commit("setStudentsImageCaptured", p);
  },
  setStudentsCaptureDone({ commit }, p: StudentCaptureStatus) {
    commit("setStudentsCaptureDone", p);
  },
  setCaptureAll({ commit }, p: boolean) {
    commit("setCaptureAll", p);
  },
  clearStudentsCaptureDone({ commit }) {
    commit("clearStudentsCaptureDone");
  },
  async setPencilPath({ state, getters }, p: any) {
    if (getters["isTeacherUseOnly"]) {
      if (getters["getTeacherOrHelperOneToOne"]) return;
      await state.manager?.WSClient.sendRequestTeacherUseOnlyDrawPencil(p);
    } else {
      await state.manager?.WSClient.sendRequestDrawPencil(p);
    }
  },
  async setDeleteShape({ state, getters }, payload: {}) {
    const isTeacherUseOnly = getters["isTeacherUseOnly"];
    if (isTeacherUseOnly) {
      await state.manager?.WSClient.sendRequestTeacherUseOnlyDeleteShape(payload);
    } else {
      await state.manager?.WSClient.sendRequestDeleteShape(payload);
    }
  },
  async setDeleteFabric({ state, getters }, payload: {}) {
    const isTeacherUseOnly = getters["isTeacherUseOnly"];
    if (isTeacherUseOnly) {
      await state.manager?.WSClient.sendRequestTeacherUseOnlyDeleteFabric(payload);
    } else {
      await state.manager?.WSClient.sendRequestDeleteFabric(payload);
    }
  },

  async teacherRemoveHelper({ state, commit }) {
    try {
      const helperId = state.helper?.id;
      if (!helperId) return;
      const { success, code } = await HelperService.teacherRemoveHelper(helperId);
      // reset the helper state
      if (success) {
        commit("setHelperInfo", undefined);
      } else {
        if (code == GLErrorCode.HELPER_IS_IN_1_1) {
          notification.warning({
            key: state.teacher?.id,
            message: fmtMsg(TeacherClassError.FailRemoveHelper),
          });
        }
      }
    } catch (error) {
      Logger.error(error);
    }
  },
  async toggleHelperMicro({ state, commit }, isOff: boolean) {
    if (!state.info) return;
    if (state.microphoneLock) return;
    commit("setMicrophoneLock", { enable: true });
    try {
      await HelperService.toggleHelperMicro(isOff, state.info.id);
      await state.manager?.setMicrophone({ enable: !isOff });
    } catch (error) {
      notification.error({
        message: fmtMsg(ErrorLocale.ToggleMicroError),
      });
    } finally {
      commit("setMicrophoneLock", { enable: false });
    }
  },
  async toggleHelperCamera({ state, commit }, isOff: boolean) {
    if (!state.info) return;
    if (state.cameraLock) return;
    commit("setCameraLock", { enable: true });
    try {
      await HelperService.toggleHelperCamera(isOff, state.info.id);
      await state.manager?.setCamera({
        enable: !isOff,
        videoEncoderConfigurationPreset: "480p",
      });
    } catch (error) {
      Logger.error(error);
      notification.error({
        message: fmtMsg(ErrorLocale.ToggleCameraError),
      });
    } finally {
      commit("setCameraLock", { enable: false });
    }
  },
  async teacherRequestStudentReConnectVideo({ state, commit }, payload: { studentId: string }) {
    try {
      await state.manager?.WSClient.sendRequestStudentReConnectVideo(payload.studentId);
    } catch (error) {
      Logger.error(error);
    }
  },
  async checkAndReconnectVideo({ state }) {
    state.manager?.agoraClient?.checkAndReconnectCamera();
  },
  async makeHelperTheNewTeacher({ commit }) {
    commit("makeHelperTheNewTeacher");
    commit("teacher/setIgnoreHelperRequestJoinClass", false, { root: true });
  },
  async changeLocalExposureItem({ commit }, payload: { id: string; exposureType?: ExposureDetailType }) {
    /**
     * Update new exposure item and clear the whiteboard in local
     */
    commit("whiteboard/resetState", null, { root: true });
    commit("lesson/setCurrentExposureItemMedia", payload, { root: true });
    commit("teacherRoom/setWhiteboard", false, { root: true });
    commit("annotation/setDrawings", [], { root: true });
    commit("annotation/setClearBrush", {}, { root: true });
    commit("annotation/clearPencilPath", null, { root: true });
    commit("lesson/clearZoomState", null, { root: true });
    commit("lesson/setPdfScrollProgress", 0, { root: true });
    commit(vuexName(VuexNames.LESSON.COMMITS.SET_VISIBLE_TARGET_TAGS), [], { root: true });
  },
  async updateCameraDevice({ state }, { blockReopen }: { blockReopen?: boolean }) {
    await state.manager?.updateCameraDevice();
    if (blockReopen) return;
    if (store.getters.platform === VCPlatform.Agora) {
      await state.manager?.setCamera({
        enable: false,
        videoEncoderConfigurationPreset: "480p",
      });
      await state.manager?.setCamera({
        enable: true,
        videoEncoderConfigurationPreset: "480p",
      });
    }
  },
  async updateMicrophoneDevice({ state }) {
    await state.manager?.updateMicrophoneDevice();
  },
  async updateSpeakerDevice({ state }) {
    await state.manager?.updateSpeakerDevice();
  },
  async toggleOneToOneWithHelperIgnoreTeacherVoice({ state, commit, dispatch }, ignore: boolean) {
    try {
      await state.manager?.WSClient.sendRequestOneToOneWithHelperIgnoreTeacherVoice(ignore);
      commit("classTeaching/setOneToOneWithHelperIgnoreTeacherVoice", ignore, { root: true });
      await dispatch("updateAudioAndVideoFeed", {});
    } catch (error) {
      Logger.error(error);
    }
  },
  async updateRemotePdfScrollProgress({ state, getters }, progress: number) {
    const isTeacherUseOnly = getters["isTeacherUseOnly"];
    if (isTeacherUseOnly) {
      await state.manager?.WSClient.sendRequestTeacherUseOnlyUpdatePdfScrollProgress(progress);
    } else {
      await state.manager?.WSClient.sendRequestUpdatePdfScrollProgress(progress);
    }
  },
  async setSignalRConnectionId({ rootGetters, commit }, connectionId: string) {
    if (rootGetters["teacher/isHelper"]) {
      commit("setHelperSignalRConnectionId", connectionId);
    } else {
      commit("setTeacherSignalRConnectionId", connectionId);
    }
  },
  async requestUpdateCanvasObjectAsync({ state, rootState }, payload: CanvasObjectModel) {
    try {
      await RemoteTeachingService.updateCanvasObject({
        canvasObject: payload,
        sessionId: rootState.classTeaching.sessionId,
        isTeacherUseOnly: state.isTeacherUseOnly,
      });
    } catch (e) {
      Logger.error(e);
    }
  },
  async toggleStudentMediaDevicesAsync({ commit, dispatch, rootState }, payload: ToggleStudentMediaDevicesRequestModel) {
    try {
      await RemoteTeachingService.toggleStudentMediaDevices(rootState.classTeaching.sessionId, payload);
      commit(payload.mediaDeviceType === MediaDeviceTypes.Microphone ? "toggleStudentMicrophones" : "toggleStudentCameras", payload);
      await dispatch("updateAudioAndVideoFeed", {});
    } catch (e) {
      Logger.error(e);
    }
  },
  async handleAnotherTeacherToggleStudentMediaDevices({ commit, dispatch }, payload: ToggleStudentMediaDevicesRequestModel) {
    if (payload.mediaDeviceType === MediaDeviceTypes.Microphone) {
      commit("toggleStudentMicrophones", payload);
    }
    if (payload.mediaDeviceType === MediaDeviceTypes.Camera) {
      commit("toggleStudentCameras", payload);
    }
    await dispatch("updateAudioAndVideoFeed", {});
  },
};

export default actions;
