import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WarningMessage = _resolveComponent("WarningMessage")!

  return (_openBlock(), _createElementBlock("a", { href: _ctx.storeLink }, [
    (_ctx.isShowWarningDevice)
      ? (_openBlock(), _createBlock(_component_WarningMessage, {
          key: 0,
          textMsg: _ctx.warningDevicesMsg
        }, null, 8, ["textMsg"]))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}