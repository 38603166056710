import { debounce } from "lodash";
import IconAudioOff from "@/assets/student-class/audio-off.svg";
import IconAudioOn from "@/assets/student-class/audio-on.svg";
import IconVideoOff from "@/assets/student-class/video-off.svg";
import IconVideoOn from "@/assets/student-class/video-on.svg";
import IconCog from "@/assets/teacher-class/cog.svg";
import IconLowWifi from "@/assets/teacher-class/slow-wifi.svg";
import { DeviceSettingsModal, MicrophoneIcon } from "@/components/common";
import { HelperState, TeacherState } from "@/store/room/interface";
import { generateAvatar } from "@/utils/utils";
import { CheckOutlined, LoadingOutlined } from "@ant-design/icons-vue";
import { Button, Modal, Row, Space, Spin } from "ant-design-vue";
import { computed, defineComponent, ref } from "vue";
import { LoginInfo } from "vue-glcommonui";
import { useStore } from "vuex";
import {
  VideoCameraIcon,
  VideoCameraSlashIcon,
  SpeakerWaveIcon,
  SpeakerXMarkIcon,
  XMarkIcon,
  Cog6ToothIcon,
  ArrowsPointingInIcon,
  WifiIcon,
} from "@heroicons/vue/20/solid";
import NameTag from "@/components/common/name-tag/name-tag.vue";
import { useDevicesLocking } from "@/hooks/use-devices-locking";
import { useAgoraVideoSpinner } from "@/hooks/use-agora-video-spinner";

export default defineComponent({
  props: {
    teacher: {
      type: Object as () => TeacherState,
      required: true,
    },
    isMinimized: Boolean,
    onClickMinimized: {
      type: Object as () => void,
      required: true,
    },
  },
  components: {
    LoadingOutlined,
    NameTag,
    Button,
    Row,
    CheckOutlined,
    Space,
    Spin,
    Modal,
    DeviceSettingsModal,
    VideoCameraIcon,
    VideoCameraSlashIcon,
    SpeakerWaveIcon,
    SpeakerXMarkIcon,
    XMarkIcon,
    Cog6ToothIcon,
    ArrowsPointingInIcon,
    WifiIcon,
    MicrophoneIcon,
  },
  setup: function (props) {
    const { getters, dispatch } = useStore();
    const { isCameraLocking, isMicrophoneLocking } = useDevicesLocking(false);
    const { videoRef, spinnerVisible } = useAgoraVideoSpinner();
    const isDeviceSettingsModalShown = ref(false);
    const isInOneToOneMode = computed(() => !!getters["teacherRoom/getStudentModeOneId"]);
    const anotherTeacherIsInOneToOneMode = computed(() => !!getters["teacherRoom/getStudentModeOneWithAnotherTeacherId"]);
    const isOneToOneWithHelperIgnoreTeacherVoice = computed(() => getters["classTeaching/getOneToOneWithHelperIgnoreTeacherVoice"]);
    const isUsingAgora = computed<boolean>(() => getters["isUsingAgora"]);
    const signalRConnected = computed(() => getters["teacherRoom/signalRConnected"]);
    const contextMenuVisibility = ref(false);
    const videoEnabled = computed(() => props.teacher?.videoEnabled);
    const toggleContextMenu = () => {
      contextMenuVisibility.value = !contextMenuVisibility.value;
    };
    const hideContextMenu = () => {
      contextMenuVisibility.value = false;
    };
    const audioIcon = computed(() => (props.teacher?.audioEnabled ? IconAudioOn : IconAudioOff));
    const videoIcon = computed(() => (props.teacher?.videoEnabled ? IconVideoOn : IconVideoOff));
    const updateMicOpen = async (micOpen: boolean) => {
      await dispatch("teacherRoom/setTeacherAudio", {
        id: props.teacher?.id,
        enable: micOpen,
      });
    };
    const toggleAudio = async () => {
      if (isMicrophoneLocking.value) return;
      await dispatch("teacherRoom/setTeacherAudio", {
        id: props.teacher?.id,
        enable: !props.teacher?.audioEnabled,
      });
    };
    const loginInfo: LoginInfo = getters["auth/getLoginInfo"];
    const userId = loginInfo.profile.sub;
    const updateCamOpen = async (camOpen: boolean) => {
      await dispatch("teacherRoom/setTeacherVideo", {
        id: props.teacher?.id,
        enable: camOpen,
      });
    };
    const toggleVideo = async () => {
      if (isCameraLocking.value) return;
      await dispatch("teacherRoom/setTeacherVideo", {
        id: props.teacher?.id,
        enable: !props.teacher?.videoEnabled,
      });
    };
    const localAudios = computed(() => getters["teacherRoom/localAudios"]);
    const onDrop = async (event: any) => {
      event.preventDefault();
      const studentId = event.dataTransfer.getData("studentId");
      await dispatch("teacherRoom/addStudentAudio", {
        id: studentId,
      });
      await dispatch("teacherRoom/clearStudentRaisingHand", {
        id: studentId,
      });
    };
    const onDragOver = (event: any) => {
      event.preventDefault();
    };
    const onClickClearAll = async () => {
      await dispatch("teacherRoom/clearStudentAudio");
    };
    const joinAsHelper = computed(() => getters["teacher/joinAsHelper"]);
    const isLowBandWidth = computed(() => getters["getLowBandWidth"]);
    const avatarTeacher = computed(() => generateAvatar(props.teacher?.avatar));
    const isSupportedVideo = computed(() => !!(window as any).chrome && !(typeof SharedArrayBuffer === "function"));
    const helperInfo = computed<HelperState>(() => getters["teacherRoom/helperInfo"]);
    const currentUserIsHelper = computed<boolean>(() => helperInfo.value?.id === loginInfo.profile.sub);
    const oneToOneStudentId = computed<boolean>(() => getters["teacherRoom/getStudentModeOneId"]);
    const toggleTeacherVoiceShown = computed(() => currentUserIsHelper.value && oneToOneStudentId.value);
    const openDeviceSettingsModal = () => {
      isDeviceSettingsModalShown.value = true;
    };

    const zoomId = computed(() => {
      let id = props.teacher.id;
      if (joinAsHelper.value) {
        id = id + "__sub";
      } else {
        id = id + "__video";
      }
      return id;
    });

    const toggleOneToOneWithHelperIgnoreTeacherVoice = debounce(async () => {
      await dispatch("teacherRoom/toggleOneToOneWithHelperIgnoreTeacherVoice", !isOneToOneWithHelperIgnoreTeacherVoice.value);
    }, 50);
    const teacherVideoHidden = computed(() => joinAsHelper.value && getters["teacherRoom/teacherIsDisconnected"]);

    return {
      audioIcon,
      videoIcon,
      toggleAudio,
      toggleVideo,
      contextMenuVisibility,
      toggleContextMenu,
      hideContextMenu,
      localAudios,
      onDrop,
      onDragOver,
      onClickClearAll,
      isLowBandWidth,
      IconLowWifi,
      IconCog,
      avatarTeacher,
      isUsingAgora,
      isSupportedVideo,
      userId,
      currentUserIsHelper,
      signalRConnected,
      isDeviceSettingsModalShown,
      openDeviceSettingsModal,
      updateMicOpen,
      updateCamOpen,
      joinAsHelper,
      zoomId,
      videoEnabled,
      toggleTeacherVoiceShown,
      isInOneToOneMode,
      isOneToOneWithHelperIgnoreTeacherVoice,
      toggleOneToOneWithHelperIgnoreTeacherVoice,
      anotherTeacherIsInOneToOneMode,
      teacherVideoHidden,
      VideoCameraIcon,
      VideoCameraSlashIcon,
      SpeakerWaveIcon,
      SpeakerXMarkIcon,
      isCameraLocking,
      isMicrophoneLocking,
      videoRef,
      spinnerVisible,
    };
  },
});
