import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-392c6490"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "helper-joining-notify" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createTextVNode(_toDisplayString(_ctx.notifyPart1Text) + " ", 1),
    _createElementVNode("span", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onSubmit('yes')))
    }, _toDisplayString(_ctx.notifyPart2Text), 1),
    _createTextVNode(" " + _toDisplayString(_ctx.notifyPart3Text) + " ", 1),
    _createElementVNode("span", {
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onSubmit('no')))
    }, _toDisplayString(_ctx.notifyPart4Text), 1),
    _createTextVNode(". ")
  ]))
}