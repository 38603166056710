import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00de4798"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "notification-content" }
const _hoisted_2 = { class: "notification-message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (notification) => {
    return (_openBlock(), _createBlock(_Teleport, {
      to: "body",
      key: notification.id
    }, [
      _createElementVNode("div", {
        class: "notification",
        style: _normalizeStyle(notification.style)
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(notification.message), 1),
          _createVNode(_component_BaseIcon, {
            name: "icon-close",
            class: "close-icon",
            onClick: () => _ctx.onClickRemoveNotification(notification)
          }, null, 8, ["onClick"])
        ])
      ], 4)
    ]))
  }), 128))
}