import { AgoraClient, AgoraEventHandler } from "@/agora";
import { store } from "@/store";
import { VCPlatform } from "@/store/app/state";
import { StudentWSClient } from "@/ws";
import { ZoomClient } from "@/zoom";
import { BaseRoomManager, RoomOptions } from "./base.manager";
import { UserModel } from "@/models/user.model";
import { RoomModel } from "@/models";
import { getDeviceId } from "@/utils/utils";

export class StudentRoomManager extends BaseRoomManager<StudentWSClient> {
  constructor(options: RoomOptions) {
    super();
    this.options = options;
    if (options.agora) {
      this.agoraClient = new AgoraClient(options.agora);
    }
    if (options.zoom) {
      this.zoomClient = new ZoomClient(options.zoom);
    }
    this.WSClient = new StudentWSClient({
      url: `${process.env.VUE_APP_REMOTE_TEACHING_SERVICE}/${process.env.VUE_APP_REMOTE_TEACHING_HUB}`,
      reConnectedCallback: async (newConnectionId: string) => {
        await this.handleSuccessfulReconnection(newConnectionId);
      },
    });
    this.WSClient.init();
  }

  async handleSuccessfulReconnection(newConnectionId: string) {
    await store.dispatch("studentRoom/joinWSRoom", true);
    const refreshSessionData = async () => {
      const { getters, dispatch } = store;
      const user = getters["studentRoom/user"] as UserModel;
      const room = getters["studentRoom/info"] as RoomModel;
      await dispatch("studentRoom/initClassRoom", {
        classId: room.classInfo.classId,
        userId: user.id,
        userName: user.name,
        studentId: user.id,
        role: "student",
        deviceId: getDeviceId(),
        callFirstTime: false,
      });
    };
    await refreshSessionData();
    if (store.getters.platform === VCPlatform.Zoom) {
      if (this.zoomClient._joinRoomOptions) {
        await this.zoomClient.joinRTCRoom(this.zoomClient._joinRoomOptions);
        if (this.zoomClient._oneToOneStudentId) {
          await this.zoomClient.studentJoinOneToOneSubSession();
        }
      }
    }
  }

  async join(options: {
    classId?: string;
    studentId?: string;
    teacherId?: string;
    camera?: boolean;
    microphone?: boolean;
    idOne?: string;
    isMirror?: boolean;
    isRemoteMirror?: boolean;
    callingEventHandlers: AgoraEventHandler | null;
  }) {
    if (!options.studentId || !options.classId) throw new Error("Missing Params");
    await this.WSClient.connect();
    if (store.getters.platform === VCPlatform.Agora) {
      await this.agoraClient.joinRTCRoom(options, false, async () => await this.callBackWhenAgoraJoinFailed());
    } else {
      await this.zoomClient.joinRTCRoom(options);
      if (options.idOne && options.studentId === options.idOne) {
        await this.zoomClient.studentJoinOneToOneSubSession();
      }
    }
  }
  async callBackWhenAgoraJoinFailed() {
    this.agoraClient.joinRoomOptions && (await this.agoraClient.joinRTCRoom(this.agoraClient.joinRoomOptions, true));
  }

  async close(end?: boolean) {
    await this.WSClient.disconnect();
    if (store.getters.platform === VCPlatform.Agora) {
      if (end) {
        await this.agoraClient?.reset();
      } else {
        await this.agoraClient.leaveChannel();
      }
    } else {
      await this.zoomClient?.reset(end);
    }
  }

  adjustRenderedVideoPosition() {
    if (store.getters.platform === VCPlatform.Zoom) {
      return this.zoomClient.adjustRenderedVideoPosition();
    }
  }
}
