<template>
  <div class="absolute top-3 left-1/2 -translate-x-1/2 text-slate-600 flex gap-x-1">
    <button
      @click="onClickClearRaisingHand"
      v-if="isRaisingHand"
      class="h-8 w-8 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors"
    >
      <QuestionMarkCircleIcon class="h-[18px] w-[18px]" />
    </button>
    <button
      @click="toggleVideo"
      :disabled="isCameraLocking"
      class="h-8 w-8 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors disabled:cursor-wait"
    >
      <LoadingOutlined class="animate-spin" v-if="isCameraLocking" />
      <component v-else :is="student.videoEnabled ? VideoCameraIcon : VideoCameraSlashIcon" class="h-[18px] w-[18px]" />
    </button>
    <button
      @click="toggleAudio"
      :disabled="isMicrophoneLocking"
      class="h-8 w-8 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors disabled:cursor-wait"
    >
      <LoadingOutlined class="animate-spin" v-if="isMicrophoneLocking" />
      <MicrophoneIcon v-else icon-class="h-[18px] w-[18px]" :enabled="student.audioEnabled" />
    </button>
    <button
      @click="toggleAnnotation"
      :disabled="!isOnePalette"
      class="h-8 w-8 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors disabled:bg-slate-300"
    >
      <img :src="paletteIcon" alt="" :class="!isOnePalette && 'opacity-60'" class="h-[18px] w-[18px]" />
    </button>
    <button
      @click="addABadge"
      class="relative h-8 w-8 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors"
    >
      <img :src="require('@/assets/teacher-class/sticker-star-small.svg')" alt="" class="h-5 w-5" />
      <span
        :class="[student.badge < 10 ? 'text-sm' : student.badge < 100 ? 'text-xs' : 'text-[10px]']"
        class="absolute left-1/2 top-1/2 text-white -translate-x-1/2 -translate-y-1/2"
        >{{ student.badge }}</span
      >
    </button>
    <button
      @click="handleExpand"
      v-if="isShowExpandIcon && !focusDisabled"
      class="h-8 w-8 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors"
    >
      <component :is="focusedStudent ? ArrowsPointingInIcon : ArrowsPointingOutIcon" class="h-[18px] w-[18px]" />
    </button>
  </div>
</template>

<style lang="scss" scoped src="./student-card-actions.scss"></style>
<script lang="ts" src="./student-card-actions.ts"></script>
