// these are from RemoteTeachingHub.cs at server side
export enum TeacherWSCommand {
  STREAM_CONNECT = "TeacherStreamConnect",
  JOIN_CLASS = "TeacherJoinClass",
  MUTE_VIDEO = "TeacherMuteVideo",
  MUTE_AUDIO = "TeacherMuteAudio",
  MUTE_STUDENT_VIDEO = "TeacherMuteStudentVideo",
  MUTE_STUDENT_AUDIO = "TeacherMuteStudentAudio",
  MUTE_ALL_STUDENT_AUDIO = "TeacherMuteAllStudentAudio",
  MUTE_ALL_STUDENT_VIDEO = "TeacherMuteAllStudentVideo",
  TOGGLE_STUDENTS_PALETTES = "ToggleStudentsPalettes",
  RESET_PALETTE_ALL_STUDENT = "ResetPaletteAllStudent",
  SET_STUDENT_PALETTE = "SetStudentPalette",
  SET_TEACHING_MODE = "TeacherSetTeachingMode",
  SET_STUDENT_BADGE = "TeacherSetStudentBadge",
  ADD_STUDENT_AUDIO = "TeacherAddStudentAudio",
  CLEAR_STUDENT_AUDIO = "TeacherClearStudentAudio",
  ADD_GLOBAL_STUDENT_AUDIO = "TeacherAddGlobalStudentAudio",
  CLEAR_GLOBAL_STUDENT_AUDIO = "TeacherClearGlobalStudentAudio",
  START_LESSON_CONTENT = "TeacherSetContent",
  END_LESSON_CONTENT = "TeacherEndContent",
  SET_LESSON_ITEM_CONTENT = "TeacherSetItemContent",
  BLACKOUT_LESSON_CONTENT = "BlackOutScreen",
  CLEAR_RAISING_HAND = "TeacherClearRaisingHand",
  SET_CLASS_ACTION = "TeacherSetClassAction",
  DESIGNATE_TARGET = "TeacherDesignate",
  TEACHER_ANSWER_INTERACTIVE = "TeacherAnswerInteractive",
  TEACHER_ANSWER_ALL = "TeacherAnswerAllTarget",
  TEACHER_SET_POINTER = "TeacherSetPointer",
  TEACHER_USE_ONLY_SET_POINTER = "TeacherUseOnlySetPointer",
  TEACHER_UPDATE_ANNOTATION_MODE = "TeacherUpdateAnnotationMode",
  TEACHER_USE_ONLY_UPDATE_ANNOTATION_MODE = "TeacherUseOnlyUpdateAnnotationMode",
  TEACHER_CLEAR_ALL_BRUSH_STROKES = "TeacherClearAllBrushstrokes",
  TEACHER_USE_ONLY_CLEAR_ALL_BRUSH_STROKES = "TeacherUseOnlyClearAllBrushstrokes",
  TEACHER_UNDO_BRUSH = "TeacherUndoBrushs",
  TEACHER_USE_ONLY_UNDO_BRUSH = "TeacherUseOnlyUndoBrushs",
  TEACHER_SET_STICKERS = "TeacherSetStickers",
  TEACHER_CLEAR_STICKERS = "TeacherClearStickers",
  TEACHER_SET_ONE_TO_ONE = "TeacherSetOneToOne",
  TEACHER_SET_WHITEBOARD = "TeacherSetWhiteBoard",
  TEACHER_DRAW_LASER_PEN = "TeacherDrawLaserPen",
  TEACHER_USE_ONLY_DRAW_LASER_PEN = "TeacherUseOnlyDrawLaserPen",
  TEACHER_DRAW_PENCIL_PEN = "TeacherDrawPencilPen",
  TEACHER_USE_ONLY_DRAW_PENCIL_PEN = "TeacherUseOnlyDrawPencilPen",
  TEACHER_CREATE_FABRIC_OBJECT = "TeacherCreateFabricObject",
  TEACHER_USE_ONLY_CREATE_FABRIC_OBJECT = "TeacherUseOnlyCreateFabricObject",
  TEACHER_MODIFY_FABRIC_OBJECT = "TeacherModifyFabricObject",
  TEACHER_USE_ONLY_MODIFY_FABRIC_OBJECT = "TeacherUseOnlyModifyFabricObject",
  TOGGLE_ALL_SHAPES = "ToggleAllShapes",
  TOGGLE_SHAPE = "ToggleShape",
  CHECK_MESSAGE_VERSION = "TeacherCheckMessageVersion",
  UPDATE_SESSION_LESSON_AND_UNIT = "TeacherUpdateSessionLessonAndUnit",
  TEACHER_UNDO_FABRIC = "TeacherUndoFabrics",
  TEACHER_USE_ONLY_UNDO_FABRIC = "TeacherUseOnlyUndoFabrics",
  TEACHER_UNDO_SHAPE = "TeacherUndoShapes",
  TEACHER_USE_ONLY_UNDO_SHAPE = "TeacherUseOnlyUndoShapes",
  TEACHER_RESET_ZOOM = "TeacherResetZoom",
  TEACHER_ZOOM_SLIDE = "TeacherZoomSlide",
  TEACHER_USE_ONLY_ZOOM_SLIDE = "TeacherUseOnlyZoomSlide",
  TEACHER_MOVE_ZOOMED_SLIDE = "TeacherMoveZoomedSlide",
  TEACHER_USE_ONLY_MOVE_ZOOMED_SLIDE = "TeacherUseOnlyMoveZoomedSlide",
  TEACHER_SEND_REQUEST_CAPTURE_IMAGE = "TeacherSendRequestCaptureImage",
  TEACHER_SET_MEDIA_STATE = "TeacherSetMediaState",
  TEACHER_SET_CURRENT_TIME_MEDIA = "SetCurrentTimeMedia",
  TEACHER_REQUEST_STUDENT_RE_CONNECT_VIDEO = "TeacherRequestStudentReConnectVideo",
  HELPER_UPDATE_IGNORE_TEACHER_AUDIO = "HelperUpdateIgnoreTeacherAudio",
  TEACHER_SCROLL_PDF_VIEW = "TeacherScrollPdfView",
  TEACHER_USE_ONLY_SCROLL_PDF_VIEW = "TeacherUseOnlyScrollPdfView",
}
