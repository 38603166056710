import { debounce } from "lodash";
import { Thumbs } from "swiper/modules";
import Zoom from "@/modules/swiper-zoom/zoom";
import "swiper/css";
import "swiper/css/zoom";
import { Swiper, SwiperSlide } from "swiper/vue";
import { computed, ComputedRef, defineComponent, ref } from "vue";
import LessonNav from "./components/lesson-nav.vue";
import { Howl } from "howler";
import { Alert } from "ant-design-vue";
import { IFocusWordContent } from "@/hooks/use-focus-word";
import { MagnifyingGlassMinusIcon, MagnifyingGlassPlusIcon } from "@heroicons/vue/24/outline";
import { MAX_ZOOM_RATIO, MIN_ZOOM_RATIO, ZOOM_STEP } from "@/utils/constant";

const DEFAULT_ZOOM_PERCENT = 100;
export interface LessonGalleryItem {
  id: string;
  imageUrl: string;
  audioUrl?: string;
  focusWord?: {
    word: string;
    definition: string;
  };
}
export default defineComponent({
  props: {
    items: {
      type: Object as () => LessonGalleryItem[],
      required: true,
    },
    initialImageIndex: {
      type: Number,
      required: true,
    },
  },
  emits: ["handle-item-change"],
  components: {
    MagnifyingGlassPlusIcon,
    MagnifyingGlassMinusIcon,
    Swiper,
    SwiperSlide,
    LessonNav,
    Alert,
  },
  setup(props, { emit }) {
    const swiper = ref<any>(null);
    const onSwiper = (sw: any) => (swiper.value = sw);
    const swipeFirstTime = ref(true);
    const createAudioPlayerByIndex = (index: number): any => {
      const audioUrl = props.items[index]?.audioUrl ?? "";
      if (!audioUrl) return null;
      const audioPlayer = new Howl({
        src: [audioUrl],
        format: ["mp3"],
        loop: false,
        onend: function () {
          if (readerAudioPlaying.value) {
            readerAudioPlaying.value = false;
          }
        },
      });
      return audioPlayer;
    };
    const currentAudioPlayer = ref(createAudioPlayerByIndex(props.initialImageIndex));
    const thumbsSwiper = ref();
    const currentIndex = ref(props.initialImageIndex);
    const focusWordContent: ComputedRef<IFocusWordContent> = computed(() => {
      const focusWord = props.items[currentIndex.value]?.focusWord;
      return {
        condition: !!focusWord,
        word: focusWord ? focusWord.word : "",
        definition: focusWord ? focusWord.definition : "",
      };
    });
    const readerAudioPlaying = ref(false);
    const setThumbsSwiper = (swiper: any) => {
      thumbsSwiper.value = swiper;
    };
    const onSwiperChange = (swiper: any) => {
      currentIndex.value = swiper.realIndex;
      thumbsSwiper.value = swiper;
      if (swipeFirstTime.value) {
        swipeFirstTime.value = false;
        if (props.initialImageIndex > 0) return;
        //** When component mounts with "initialSlide" > 0, swiperjs automatically triggers "slideChange" callback.
        // This behavior is not necessary; we use this condition to avoid it.*/
      }
      onItemChange(swiper.realIndex);
    };
    const updateProgressToRemoteUsers = (index: number, percent: number) => {
      emit("handle-item-change", index, percent);
    };
    const updateAudio = (index: number) => {
      readerAudioPlaying.value = false;
      currentAudioPlayer.value && currentAudioPlayer.value.stop();
      currentAudioPlayer.value = createAudioPlayerByIndex(index);
    };
    const nextBtnRef = ref(null);
    const backBtnRef = ref(null);
    const progress = computed<string>(() => `${currentIndex.value + 1}/${props.items.length}`);
    const onItemChange = debounce((index: number) => {
      const percent = Math.round(((index + 1) / props.items.length) * 100);
      updateProgressToRemoteUsers(index, percent);
      updateAudio(index);
    }, 100);

    const toggleReaderAudio = () => {
      const isPlaying = !readerAudioPlaying.value;
      readerAudioPlaying.value = isPlaying;
      if (isPlaying) {
        currentAudioPlayer.value.play();
      } else {
        currentAudioPlayer.value.pause();
      }
    };
    const zoomPercentage = ref(DEFAULT_ZOOM_PERCENT);
    const zoomIn = () => {
      const zoom = swiper.value?.zoom;
      if (!zoom || zoom.scale >= MAX_ZOOM_RATIO) {
        return;
      }
      const newRatio = Math.min(zoom.scale + ZOOM_STEP, MAX_ZOOM_RATIO);
      zoom.in(newRatio);
    };
    const zoomOut = () => {
      const zoom = swiper.value?.zoom;
      if (!zoom || zoom.scale <= MIN_ZOOM_RATIO) {
        return;
      }
      const newRatio = Math.max(zoom.scale - ZOOM_STEP, MIN_ZOOM_RATIO);
      zoom.in(newRatio);
    };
    const onZoomChange = (_swiper: any, scale: number) => {
      zoomPercentage.value = Math.round(scale * DEFAULT_ZOOM_PERCENT);
    };
    const onWheel = (event: WheelEvent) => {
      if (event.deltaY < 0) {
        zoomIn();
      } else if (event.deltaY > 0) {
        zoomOut();
      }
    };
    return {
      Thumbs,
      Zoom,
      thumbsSwiper,
      setThumbsSwiper,
      onSwiperChange,
      nextBtnRef,
      backBtnRef,
      progress,
      toggleReaderAudio,
      readerAudioPlaying,
      createAudioPlayerByIndex,
      currentAudioPlayer,
      focusWordContent,
      zoomIn,
      zoomOut,
      onSwiper,
      onZoomChange,
      zoomPercentage,
      onWheel,
    };
  },
});
