import { LessonGalleryItem } from "@/components/common/lesson-gallery/lesson-gallery";
import { IndependentLocales, TeacherClassGallery, TeamLocales } from "@/locales/localeid";
import { computed, defineComponent, ref } from "vue";
import { fmtMsg } from "vue-glcommonui";
import { useStore } from "vuex";
import { Tooltip } from "ant-design-vue";
import { MicrophoneIcon } from "@/components/common";
import { PlusIcon, VideoCameraIcon, VideoCameraSlashIcon } from "@heroicons/vue/20/solid";
import { useSendWebSocketMsg } from "@/hooks/use-send-websocket-msg";
import { MediaDeviceTypes } from "@/utils/utils";
import { RoomStore, useRoomStore } from "@/hooks/use-room-store";
import { debounce } from "lodash";

export default defineComponent({
  name: "StudentControls",
  emits: [
    "add-sticker-all",
    "disable-all",
    "enable-all",
    // "capture-all"
  ],
  props: ["isTeamMode"],
  components: {
    Tooltip,
    VideoCameraIcon,
    VideoCameraSlashIcon,
    MicrophoneIcon,
    PlusIcon,
  },
  setup(props, { emit }) {
    const { getters, dispatch } = useStore();
    const { onlineStudentIdsNotInOneToOneMode } = useRoomStore(RoomStore.Teacher);
    const { requestToggleStudentMediaDevices } = useSendWebSocketMsg();
    const loadingIndependent = ref(false);
    const isAllVideoHidden = computed(() => getters["teacherRoom/isAllVideoHidden"]);
    const isAllAudioMuted = computed(() => getters["teacherRoom/isAllAudioMuted"]);
    const isAllPaletteHidden = computed(() => getters["teacherRoom/isAllPaletteHidden"]);
    const stickerAllText = computed(() => fmtMsg(TeacherClassGallery.StickerAll));
    const unmuteAllText = computed(() => fmtMsg(TeacherClassGallery.UnmuteAll));
    const muteAllText = computed(() => fmtMsg(TeacherClassGallery.MuteAll));
    const enableAllText = computed(() => fmtMsg(TeacherClassGallery.EnableAll));
    const disableAllText = computed(() => fmtMsg(TeacherClassGallery.DisableAll));
    const showAllText = computed(() => fmtMsg(TeacherClassGallery.ShowAll));
    const hideAllText = computed(() => fmtMsg(TeacherClassGallery.HideAll));
    const captureAllText = computed(() => fmtMsg(TeacherClassGallery.CaptureAll));
    const toggleDevice = async (deviceType: MediaDeviceTypes, isAllDeviceMuted: boolean) => {
      const isMute = !isAllDeviceMuted;
      const studentIds = onlineStudentIdsNotInOneToOneMode.value;
      const payload = { mediaDeviceType: deviceType, isMute, studentIds };
      await requestToggleStudentMediaDevices(payload);

      // TODO: handle for old mobile app version, remove after force update version released
      await dispatch(
        `teacherRoom/${
          isMute
            ? deviceType === MediaDeviceTypes.Microphone
              ? "muteAllStudents"
              : "hideAllStudents"
            : deviceType === MediaDeviceTypes.Camera
            ? "showAllStudents"
            : "unmuteAllStudents"
        }`,
      );
    };

    const toggleVideo = debounce(() => toggleDevice(MediaDeviceTypes.Camera, isAllVideoHidden.value), 150);
    const toggleAudio = debounce(() => toggleDevice(MediaDeviceTypes.Microphone, isAllAudioMuted.value), 150);

    const onClickStickerAll = () => {
      emit("add-sticker-all");
    };
    const onClickDisableAll = () => {
      if (!isAllPaletteHidden.value) {
        emit("disable-all");
      }
    };
    // const onClickCaptureAll = () => {
    //   emit("capture-all");
    // };
    const toggleIndependentMode = async () => {
      loadingIndependent.value = true;
      await dispatch("teacherTeaching/toggleIndependentMode");
      loadingIndependent.value = false;
    };
    const isIndependentMode = computed<boolean>(() => getters["classTeaching/isIndependentMode"]);
    const independentModeContent = computed<LessonGalleryItem[] | null>(() => {
      return getters["lesson/listReaderItemsOfCurrentExposure"];
    });
    const independentModeShown = computed<boolean>(() => {
      const isHelper = getters["teacher/isHelper"];
      if (isHelper) return false;
      return independentModeContent.value ? independentModeContent.value.length > 0 : false;
    });
    const independentModeDisabled = computed(() => props.isTeamMode || loadingIndependent.value);
    const independentButtonText = computed(() =>
      props.isTeamMode ? MsgPreventToggleIMWarning.value : isIndependentMode.value ? MsgStopIndependentMode.value : MsgStartIndependentMode.value,
    );

    //** Messages */
    const MsgTeamModeName = computed(() => fmtMsg(TeamLocales.TeamMode));
    const MsgPreventToggleIMWarning = computed(() =>
      fmtMsg(IndependentLocales.PreventToggleIndependentModeWarning, { modeName: MsgTeamModeName.value }),
    );
    const MsgStopIndependentMode = computed(() => fmtMsg(IndependentLocales.StopMode));
    const MsgStartIndependentMode = computed(() => fmtMsg(IndependentLocales.StartMode));
    return {
      onClickDisableAll,
      onClickStickerAll,
      toggleVideo,
      toggleAudio,
      isAllVideoHidden,
      isAllAudioMuted,
      isAllPaletteHidden,
      stickerAllText,
      unmuteAllText,
      muteAllText,
      enableAllText,
      disableAllText,
      showAllText,
      hideAllText,
      captureAllText,
      toggleIndependentMode,
      isIndependentMode,
      loadingIndependent,
      MsgStopIndependentMode,
      MsgStartIndependentMode,
      VideoCameraIcon,
      VideoCameraSlashIcon,
      independentModeShown,
      independentModeDisabled,
      independentButtonText,
    };
  },
});
