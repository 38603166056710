import { AgoraClient, AgoraEventHandler } from "@/agora";
import { store } from "@/store";
import { VCPlatform } from "@/store/app/state";
import { Logger } from "@/utils/logger";
import { TeacherWSClient } from "@/ws";
import { ZoomClient } from "@/zoom";
import { BaseRoomManager, RoomOptions } from "./base.manager";
import { RoomModel } from "@/models";
import { LoginInfo, RoleName } from "vue-glcommonui";

export class TeacherRoomManager extends BaseRoomManager<TeacherWSClient> {
  constructor(options: RoomOptions) {
    super();
    this.options = options;
    if (options.agora) {
      this.agoraClient = new AgoraClient(options.agora);
    }
    if (options.zoom) {
      this.zoomClient = new ZoomClient(options.zoom);
    }
    this.WSClient = new TeacherWSClient({
      url: `${process.env.VUE_APP_REMOTE_TEACHING_SERVICE}/${process.env.VUE_APP_REMOTE_TEACHING_HUB}`,
      reConnectedCallback: async (newConnectionId: string) => {
        await this.handleSuccessfulReconnection(newConnectionId);
      },
    });
    this.WSClient.init();
  }

  async handleSuccessfulReconnection(newConnectionId: string) {
    await store.dispatch("teacherRoom/setSignalRConnectionId", newConnectionId);
    await store.dispatch("teacherRoom/joinWSRoom", true);
    const refreshSessionData = async () => {
      const { getters, dispatch } = store;
      const room = getters["teacherRoom/info"] as RoomModel;
      const { groupId, deviceId } = getters["teacherRoom/getParamsToJoinCurSession"];
      const loginInfo: LoginInfo = getters["auth/getLoginInfo"];
      const isHelper = store.getters["teacher/isHelper"];
      await dispatch("teacherRoom/initClassRoom", {
        classId: room.classInfo.classId,
        userId: loginInfo.profile.sub,
        userName: loginInfo.profile.name,
        role: RoleName.teacher,
        deviceId,
        isHelper,
        groupId,
        callFirstTime: false,
      });
    };
    await refreshSessionData();
    if (store.getters.platform === VCPlatform.Zoom) {
      if (this.zoomClient._joinRoomOptions) {
        await this.zoomClient.joinRTCRoom(this.zoomClient._joinRoomOptions);
        if (this.zoomClient._oneToOneStudentId) {
          await this.zoomClient.teacherJoinOneToOneSubSession(this.zoomClient._oneToOneStudentId);
        }
      }
    }
  }

  async join(options: {
    classId?: string;
    studentId?: string;
    teacherId?: string;
    camera?: boolean;
    microphone?: boolean;
    idOne?: string;
    isMirror?: boolean;
    isRemoteMirror?: boolean;
    callingEventHandlers: AgoraEventHandler | null;
  }) {
    if (!options.teacherId || !options.classId) throw new Error("Missing Params");
    await this.WSClient.connect();
    if (store.getters.platform === VCPlatform.Agora) {
      await this.agoraClient.joinRTCRoom({ ...options, videoEncoderConfigurationPreset: "480p" }, false, async () => {
        await this.callBackWhenAgoraJoinFailed();
      });
    } else {
      await this.zoomClient.joinRTCRoom(options);
      if (options.idOne) {
        setTimeout(async () => {
          await this.zoomClient.teacherJoinOneToOneSubSession(options.idOne ?? "");
        }, 5000);
      }
    }
  }

  async callBackWhenAgoraJoinFailed() {
    this.agoraClient.joinRoomOptions && (await this.agoraClient.joinRTCRoom(this.agoraClient.joinRoomOptions, true));
  }

  async close(end?: boolean) {
    await this.WSClient.disconnect();
    if (store.getters.platform === VCPlatform.Agora) {
      if (end) {
        await this.agoraClient.reset();
      } else {
        await this.agoraClient.leaveChannel();
      }
    } else {
      await this.zoomClient.reset(end);
    }
  }

  async adjustRenderedVideoPosition(userId?: string) {
    if (store.getters.platform !== VCPlatform.Zoom) return;
    try {
      await this.zoomClient.adjustRenderedVideoPosition(userId);
    } catch (error) {
      Logger.log(error);
    }
  }

  async removeParticipantVideo(userId: string) {
    if (store.getters.platform === VCPlatform.Zoom) {
      const user = this.zoomClient?.getParticipantByDisplayName(userId);
      if (!user) return;
      await this.zoomClient.removeParticipantVideo(user);
    }
  }
  async renderExpandedVideo(userId: string, isExpanded: boolean) {
    if (store.getters.platform === VCPlatform.Zoom) {
      const user = this.zoomClient?.getParticipantByDisplayName(userId);
      if (!user) return;
      await this.zoomClient.renderExpandedVideo(user, isExpanded);
    }
  }
}
