<template>
  <div
    class="annotation-view-container"
    :class="{
      whiteboard: isWbVisible,
      'pointer-events-none': student?.isPalette !== true,
    }"
    ref="containerRef"
  >
    <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" v-if="isBoardImageFetching">
      <CustomSpinner class="h-8 w-8" />
    </div>
    <div class="annotation-view-container__image">
      <div
        class="cursor"
        v-if="(isPointerMode && !studentOneAndOneId) || (isPointerMode && student.id == studentOneAndOneId)"
        :style="[pointerStyle]"
      >
        <img src="@/assets/icon-select.png" alt="" />
      </div>
      <div v-if="mediaTypeId === undefined && image?.url">
        <CropImage
          v-if="isCropImage"
          class="annotation-img"
          :imageUrl="boardImageUrl"
          :metadata="image.metaData"
          :canvasImage="image"
          @img-load="onLPSlideLoaded"
          @img-error="handleImageLoadError"
          ref="imgCropRef"
          :key="reloadKey"
        />
        <img
          crossorigin="anonymous"
          v-else
          ref="imgRef"
          class="annotation-img"
          :src="boardImageUrl"
          @load="onLPSlideLoaded"
          @error="handleImageLoadError"
          alt=""
          :key="reloadKey"
        />
      </div>
      <div
        v-else-if="mediaTypeId === MediaType.pdf && isValidUrl"
        :style="{
          'z-index': isWbVisible ? 0 : 9000,
        }"
        class="pdf-content"
      >
        <vue-pdf-embed :source="image.url" class="pdf-config nice-scroll" ref="pdfRef" @rendered="onPdfRendered" />
      </div>
      <div
        v-else-if="mediaTypeId === MediaType.mp3 && isValidUrl"
        :style="{
          'z-index': isWbVisible ? 0 : 9000,
        }"
        class="audio-content"
      >
        <img
          crossorigin="anonymous"
          v-if="boardImageUrl"
          ref="imgRef"
          :src="boardImageUrl"
          class="annotation-img"
          @load="onLPSlideLoaded"
          @error="handleImageLoadError"
          alt=""
          :key="reloadKey"
        />
        <audio
          ref="audioAnnotation"
          controlslist="noplaybackrate nodownload"
          class="audio-config"
          @timeupdate="onAudioTimeUpdate"
          :muted="teacherDisconnected"
          :data-slide-id="currentExposureItemMedia?.id"
        >
          <source :src="image.url" type="audio/mp3" />
          {{ WarningAudioTagText }}
        </audio>
      </div>
      <div
        v-else-if="mediaTypeId === MediaType.mp4 && isValidUrl"
        :style="{
          'z-index': isWbVisible ? 0 : 9000,
        }"
        class="video-content"
        :class="[isWbVisible && 'opacity-0']"
      >
        <video
          ref="videoAnnotation"
          controlslist="noplaybackrate nodownload"
          class="video-config"
          :poster="image.thumbnail"
          :muted="teacherDisconnected"
          :data-slide-id="currentExposureItemMedia?.id"
        >
          <source :src="image.url" type="video/mp4" />
          {{ WarningVideoTagText }}
        </video>
      </div>
    </div>
    <canvas class="annotation-view-container__canvas" id="canvasOnStudent" ref="canvasRef" />
    <!--    <canvas width="720" height="480" id="imgCanvas" style="display: none" />-->
    <div class="focus-word" v-if="focusWordContent.condition">
      <Alert :message="focusWordContent.word" :description="focusWordContent.definition" type="info" />
    </div>
  </div>
  <div></div>
</template>
<style lang="scss" scoped src="./annotation-view.scss"></style>
<script lang="ts" src="./annotation-view.ts"></script>
