import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center space-x-1 select-none" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "h-5 w-5 flex items-center justify-center" }
const _hoisted_4 = { class: "h-5 w-5 flex items-center justify-center" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "h-5 w-5 flex items-center justify-center" }
const _hoisted_7 = ["src", "alt"]
const _hoisted_8 = ["disabled"]
const _hoisted_9 = { class: "h-5 w-5 flex items-center justify-center" }
const _hoisted_10 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlusIcon = _resolveComponent("PlusIcon")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_MicrophoneIcon = _resolveComponent("MicrophoneIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Tooltip, {
      title: _ctx.stickerAllText,
      placement: "bottom"
    }, {
      default: _withCtx(() => [
        _createElementVNode("button", {
          type: "button",
          class: "relative rounded-md border bg-white p-2 text-sm font-medium text-gray-900 shadow-sm hover:bg-gray-50",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickStickerAll && _ctx.onClickStickerAll(...args)))
        }, [
          _createElementVNode("img", {
            src: require('@/assets/teacher-class/sticker-star-small.svg'),
            alt: _ctx.stickerAllText,
            class: "h-5 w-5"
          }, null, 8, _hoisted_2),
          _createVNode(_component_PlusIcon, { class: "absolute left-1/2 top-1/2 text-white -translate-x-1/2 -translate-y-1/2 h-4 w-4" })
        ])
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_Tooltip, {
      title: _ctx.isAllAudioMuted ? _ctx.unmuteAllText : _ctx.muteAllText,
      placement: "bottom"
    }, {
      default: _withCtx(() => [
        _createElementVNode("button", {
          type: "button",
          class: "rounded-md border bg-white p-2 text-sm font-medium text-gray-900 shadow-sm hover:bg-gray-50",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleAudio && _ctx.toggleAudio(...args)))
        }, [
          _createElementVNode("span", _hoisted_3, [
            _createVNode(_component_MicrophoneIcon, {
              "icon-class": "h-5 w-5 text-slate-600",
              enabled: _ctx.isAllAudioMuted
            }, null, 8, ["enabled"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_Tooltip, {
      title: _ctx.isAllVideoHidden ? _ctx.showAllText : _ctx.hideAllText,
      placement: "bottom"
    }, {
      default: _withCtx(() => [
        _createElementVNode("button", {
          type: "button",
          class: "rounded-md border bg-white p-2 text-sm font-medium text-gray-900 shadow-sm hover:bg-gray-50",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleVideo && _ctx.toggleVideo(...args)))
        }, [
          _createElementVNode("span", _hoisted_4, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.isAllVideoHidden ? _ctx.VideoCameraIcon : _ctx.VideoCameraSlashIcon), {
              class: "h-5 w-5 text-slate-600",
              alt: _ctx.isAllVideoHidden ? _ctx.showAllText : _ctx.hideAllText
            }, null, 8, ["alt"]))
          ])
        ])
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_Tooltip, {
      title: _ctx.disableAllText,
      placement: "bottom"
    }, {
      default: _withCtx(() => [
        _createElementVNode("button", {
          type: "button",
          class: "rounded-md border bg-white p-2 text-sm font-medium text-gray-900 shadow-sm hover:bg-gray-50 disabled:bg-slate-200 disabled:grayscale",
          disabled: _ctx.isAllPaletteHidden,
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onClickDisableAll && _ctx.onClickDisableAll(...args)))
        }, [
          _createElementVNode("span", _hoisted_6, [
            _createElementVNode("img", {
              class: _normalizeClass(["h-5 w-5 text-slate-600", [_ctx.isAllPaletteHidden && 'opacity-60']]),
              src: 
              _ctx.isAllPaletteHidden
                ? require(`@/assets/teacher-class/touch-on-small-blue.svg`)
                : require(`@/assets/teacher-class/touch-off-small-blue.svg`)
            ,
              alt: _ctx.disableAllText
            }, null, 10, _hoisted_7)
          ])
        ], 8, _hoisted_5)
      ]),
      _: 1
    }, 8, ["title"]),
    (_ctx.independentModeShown)
      ? (_openBlock(), _createBlock(_component_Tooltip, {
          key: 0,
          title: _ctx.independentButtonText,
          placement: "bottomRight"
        }, {
          default: _withCtx(() => [
            _createElementVNode("button", {
              type: "button",
              class: "rounded-md border bg-white p-2 text-sm font-medium text-gray-900 shadow-sm hover:bg-gray-50 disabled:bg-slate-200 disabled:grayscale",
              disabled: _ctx.independentModeDisabled,
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.toggleIndependentMode && _ctx.toggleIndependentMode(...args)))
            }, [
              _createElementVNode("span", _hoisted_9, [
                _createElementVNode("img", {
                  class: _normalizeClass(["h-5 w-5 text-slate-600", [_ctx.independentModeDisabled && 'opacity-60']]),
                  src: 
              _ctx.isIndependentMode
                ? require(`@/assets/images/student-control/independent-mode-end.png`)
                : require(`@/assets/images/student-control/independent-mode.png`)
            ,
                  alt: _ctx.isIndependentMode ? _ctx.MsgStopIndependentMode : _ctx.MsgStartIndependentMode
                }, null, 10, _hoisted_10)
              ])
            ], 8, _hoisted_8)
          ]),
          _: 1
        }, 8, ["title"]))
      : _createCommentVNode("", true)
  ]))
}