import IconPrev from "@/assets/images/arrow-back.png";
import IconPrevDisable from "@/assets/images/arrow-disable-back.png";
import IconNextDisable from "@/assets/images/arrow-disable-forward.png";
import IconNext from "@/assets/images/arrow-forward.png";
import { PinningModal } from "@/components/common";
import { PINNING_MODAL_CONTAINER } from "@/components/common/pinning-modal/pinning-modal";
import { TeacherClassLessonPlan } from "@/locales/localeid";
import { TeacherRoomManager } from "@/manager/room/teacher.manager";
import { Exposure, ExposureItemMedia, ExposureType } from "@/store/lesson/state";
import { ClassView } from "@/store/room/interface";
import { NEXT_EXPOSURE, PREV_EXPOSURE } from "@/utils/constant";
import { getSeconds, secondsToTimeStr } from "@/utils/convertDuration";
import { CloseOutlined, PushpinOutlined } from "@ant-design/icons-vue";
import { Badge, Empty } from "ant-design-vue";
import { debounce } from "lodash";
import { computed, defineComponent, onMounted, onUnmounted, provide, ref, watch, nextTick } from "vue";
import { fmtMsg } from "vue-glcommonui";
import { useStore } from "vuex";
import ExposureDetail from "./exposure-detail/exposure-detail.vue";
import LessonActivity from "./lesson-activity/lesson-activity.vue";
import { ChevronLeftIcon, InformationCircleIcon, ChevronRightIcon } from "@heroicons/vue/24/outline";
import { useClassView } from "@/hooks/use-class-view";
import { isAntdModalOpen, isAntdSelectOpen, isInputBeingEdited } from "@/utils/utils";

export enum ExposureDetailType {
  TRANSITION_BLOCK = "TRANSITION_BLOCK",
  LP_COMPLETE_BLOCK = "LP_COMPLETE_BLOCK",
  VCP_BLOCK = "VPC_BLOCK",
  CONTENT_BLOCK = "CONTENT_BLOCK",
  TEACHING_ACTIVITY_BLOCK = "TEACHING_ACTIVITY_BLOCK",
  ALTERNATE_MEDIA_BLOCK = "ALTERNATE_MEDIA_BLOCK",
}

export const getExposureFirstSlide = (exposure: Exposure, includingAlternative: boolean): ExposureItemMedia | undefined =>
  [
    ...exposure.items,
    ...exposure.contentBlockItems,
    ...exposure.teachingActivityBlockItems,
    ...(includingAlternative ? exposure.alternateMediaBlockItems.flat() : []),
  ].filter((item) => item.media[0]?.image?.url)[0]?.media[0];

const INFO_BUTTON_ID = "lp-info";

export enum PopupStatus {
  Pinned = "Pinned",
  Showed = "Showed",
  Hided = "Hided",
}

export default defineComponent({
  components: {
    LessonActivity,
    ExposureDetail,
    Empty,
    Badge,
    PushpinOutlined,
    CloseOutlined,
    ChevronLeftIcon,
    InformationCircleIcon,
    ChevronRightIcon,
    // LoadingIcon,
  },
  props: ["isHelper"],
  setup(props) {
    const { getters, dispatch } = useStore();
    const { isLpSidebarShrink } = useClassView();
    const exposures = computed<Exposure[]>(() => getters["lesson/exposures"]);
    const activityStatistic = computed(() => getters["lesson/activityStatistic"]);
    const currentExposure = computed(() => getters["lesson/currentExposure"]);
    const currentLesson = computed(() => getters["teacherRoom/currentLesson"]);
    const currentUnit = computed(() => getters["teacherRoom/currentUnit"]);
    const progress = computed(() => getters["lesson/progressStatistic"]);
    const remainingTime = computed(() => getters["lesson/remainingTimeStatistic"]);
    const currentExposureItemMedia = computed<ExposureItemMedia | undefined>(() => getters["lesson/currentExposureItemMedia"]);
    const nextExposureItemMedia = computed(() => getters["lesson/nextExposureItemMedia"]);
    const prevExposureItemMedia = computed(() => getters["lesson/prevExposureItemMedia"]);
    const page = computed(() => getters["lesson/getPage"]);
    const isIndependentMode = computed<boolean>(() => getters["classTeaching/isIndependentMode"]);
    const isSignalRConnected = computed(() => (getters["teacherRoom/roomManager"] as TeacherRoomManager)?.WSClient?.isConnected);
    const nextCurrentExposure = computed(() => getters["lesson/nextExposure"]);
    const prevCurrentExposure = computed(() => getters["lesson/previousExposure"]);
    const infoPopupStatus = ref<PopupStatus>(PopupStatus.Hided);
    const infoIconRef = ref();
    const canNext = computed(() => !!(nextExposureItemMedia.value || nextCurrentExposure.value));
    const canPrev = computed(() => !!(prevExposureItemMedia.value || prevCurrentExposure.value));
    const iconNext = computed(() => (canNext.value ? IconNext : IconNextDisable));
    const iconPrev = computed(() => (canPrev.value ? IconPrev : IconPrevDisable));
    const exposureTitle = computed(() => {
      const exposure = getters["lesson/currentExposure"];
      if (!exposure) {
        return "";
      }
      switch (exposure.type) {
        case ExposureType.TRANSITION:
          return transitionText.value;
        case ExposureType.COMPLETE:
          return lessonCompleteText.value;
        default:
          return `${exposure.name} (${secondsToTimeStr(getSeconds(exposure.duration))})`;
      }
    });
    const teachingIconPosition = ref({ left: 0, top: 0 });
    const lessonContainer = ref();
    const scrollPosition = ref(0);
    const showInfo = ref(false);
    const isTransitionBlock = computed(() => currentExposure.value?.type === ExposureType.TRANSITION);
    const teachingContentEmpty = computed(() => {
      return currentExposure.value?.teachingActivityBlockItems?.findIndex((teachingItem: any) => teachingItem.textContent) <= -1;
    });
    const isOneOneMode = ref("");
    const oneToOneStudentId = computed(() => getters["teacherRoom/getStudentModeOneId"]);
    watch(oneToOneStudentId, (value) => {
      if (value === "" || value === null) {
        isOneOneMode.value = "";
      } else {
        isOneOneMode.value = value;
      }
      infoPopupStatus.value = PopupStatus.Hided;
    });

    const toggleLpSidebar = async () => {
      if (props.isHelper) return;
      await dispatch("teacherRoom/setClassViewAsync", { classView: isLpSidebarShrink.value ? ClassView.LESSON_PLAN : ClassView.GALLERY });
    };

    const onClickExposure = async (exposure: Exposure | null, force = false) => {
      if (!exposure || (exposure.id === currentExposure.value?.id && !force)) return;
      if (currentExposure.value && currentExposure.value.type === ExposureType.TRANSITION) {
        await dispatch("teacherRoom/endExposure", {
          id: currentExposure.value.id,
        });
      }
      await dispatch("teacherRoom/setBlackOut", {
        isBlackOut: exposure.type === ExposureType.TRANSITION,
      });
      await dispatch("teacherRoom/setCurrentExposure", {
        id: exposure.id,
      });
      await dispatch("teacherRoom/setMode", {
        mode: 1,
      });
      const exposureFirstSlide = getExposureFirstSlide(exposure, isAlternateMediaShown.value);
      await handleLessonItemChange(exposureFirstSlide?.id ?? "", exposureFirstSlide?.exposureType);
    };

    const handleClickCloseExposure = async () => {
      await dispatch("teacherRoom/changeLocalExposureItem", { id: "" });
      await dispatch("teacherRoom/changeRemoteExposureItem", { id: "" });
      await dispatch("teacherRoom/endExposure", {
        id: currentExposure.value?.id,
      });
      await dispatch("lesson/endCurrentContent");
      await dispatch("teacherRoom/setBlackOut", {
        isBlackOut: true,
      });
      await dispatch("teacherRoom/setMode", {
        mode: 0,
      });
    };

    const checkIdpModeBeforeChangeExposure = async (callback: () => Promise<void>) => {
      if (isIndependentMode.value && !oneToOneStudentId.value) {
        await dispatch("modal/warningIdpModeBeforeExecute", callback);
      } else {
        await callback();
      }
    };

    const onClickCloseExposure = async () => {
      if (props.isHelper) {
        return;
      }
      await checkIdpModeBeforeChangeExposure(handleClickCloseExposure);
    };

    const onClickPrevNextMedia = async (nextOrBack: number) => {
      if (isLpSidebarShrink.value || props.isHelper) return;
      const scrollLimitPosition = Math.max(
        document.body.scrollHeight,
        lessonContainer.value.scrollHeight,
        document.body.offsetHeight,
        lessonContainer.value.offsetHeight,
        document.body.clientHeight,
        lessonContainer.value.clientHeight,
      );
      scrollPosition.value = lessonContainer.value.scrollTop;
      //* click next
      if (nextOrBack === NEXT_EXPOSURE) {
        if (!canNext.value) return;
        if (nextExposureItemMedia.value !== undefined) {
          await handleLessonItemChange(nextExposureItemMedia.value.id, nextExposureItemMedia.value.exposureType);
          scrollPosition.value = scrollPosition.value < scrollLimitPosition ? scrollPosition.value + 50 : scrollLimitPosition;
        } else {
          await checkIdpModeBeforeChangeExposure(async () => {
            await dispatch("teacherRoom/endExposure", {
              id: currentExposure?.value?.id,
            });
            await onClickExposure(nextCurrentExposure.value);
            scrollPosition.value = 0;
          });
        }
        lessonContainer.value.scrollTo(0, scrollPosition.value);
      } else {
        //* click back
        if (!canPrev.value) return;
        if (prevExposureItemMedia.value !== undefined) {
          await handleLessonItemChange(prevExposureItemMedia.value.id, prevExposureItemMedia.value.exposureType);
          scrollPosition.value = scrollPosition.value <= 0 ? 0 : scrollPosition.value - 50;
        } else {
          await checkIdpModeBeforeChangeExposure(async () => {
            await dispatch("teacherRoom/endExposure", {
              id: currentExposure?.value?.id,
            });
            await onClickExposure(prevCurrentExposure.value);
            scrollPosition.value = 0;
          });
        }
        lessonContainer.value.scrollTo(0, scrollPosition.value);
      }
      await dispatch("teacherRoom/setWhiteboard", {
        isShowWhiteBoard: false,
      });
    };

    const isShowExposureDetail = computed(() => {
      const exposure = getters["lesson/currentExposure"];
      return exposure !== undefined;
    });

    watch(isShowExposureDetail, (val) => {
      if (!val) {
        infoPopupStatus.value = PopupStatus.Hided;
      }
    });
    const isTransitionType = computed(() => {
      const exposure = getters["lesson/currentExposure"];
      return exposure.type === ExposureType.TRANSITION;
    });

    const isCompleteType = computed(() => {
      const exposure = getters["lesson/currentExposure"];
      return exposure.type === ExposureType.COMPLETE;
    });
    const handleKeyDown = async (e: any) => {
      if (isAntdModalOpen() || isAntdSelectOpen() || isInputBeingEdited()) {
        return;
      }
      if (e.key == "ArrowRight" || e.key == "ArrowDown") {
        e.preventDefault();
        await onClickPrevNextMedia(NEXT_EXPOSURE);
      } else if (e.key == "ArrowLeft" || e.key == "ArrowUp") {
        e.preventDefault();
        await onClickPrevNextMedia(PREV_EXPOSURE);
      }
    };

    const hasLongShortcutHeader = computed(() => {
      return currentLesson.value >= 10 || currentUnit.value >= 10;
    });

    const handleMouseMove = debounce(() => {
      if (infoPopupStatus.value === PopupStatus.Pinned) return;
      const editableModalEl = document.querySelector<HTMLElement>(`.${PINNING_MODAL_CONTAINER}`);
      if (!editableModalEl) return;
      const infoButtonEl = document.getElementById(INFO_BUTTON_ID);
      if (!editableModalEl || !infoButtonEl) {
        return (infoPopupStatus.value = PopupStatus.Hided);
      }
      const finalModalHovered = editableModalEl.matches(":hover");
      const noteInfoTriggerHovered = infoButtonEl.matches(":hover");
      if (!noteInfoTriggerHovered && !finalModalHovered) {
        infoPopupStatus.value = PopupStatus.Hided;
      }
    }, 10);
    onMounted(() => {
      window.addEventListener("keydown", handleKeyDown);
    });

    onUnmounted(() => {
      window.removeEventListener("keydown", handleKeyDown);
    });

    const handleMouseOver = () => {
      if (infoPopupStatus.value === PopupStatus.Pinned) return;
      teachingIconPosition.value = {
        left: infoIconRef.value.getBoundingClientRect().left,
        top: infoIconRef.value.getBoundingClientRect().top,
      };
      infoPopupStatus.value = PopupStatus.Showed;
    };
    watch(infoPopupStatus, (val) => {
      if (val === PopupStatus.Showed) {
        window.addEventListener("mousemove", handleMouseMove);
      }
      if (val === PopupStatus.Hided || val === PopupStatus.Pinned) {
        window.removeEventListener("mousemove", handleMouseMove);
      }
    });
    const editableModalRef = ref<InstanceType<typeof PinningModal>>();
    const handlePinOrHide = (status: PopupStatus) => {
      infoPopupStatus.value = status;
    };
    const visible = computed(() => infoPopupStatus.value === PopupStatus.Showed);

    const updateToRemoteUsers = debounce(async (id: string) => {
      //* Update new exposure item and clear the whiteboard in to session
      await dispatch("teacherRoom/changeRemoteExposureItem", { id });
    }, 100);
    const handleLessonItemChange = async (id: string, exposureType?: ExposureDetailType) => {
      /** Reset whiteboard's state and update new item in local */
      await dispatch("teacherRoom/changeLocalExposureItem", { id, exposureType });
      /** Then send the message to students and helper */
      await updateToRemoteUsers(id);
    };
    const autoScrollLessonContainer = () => {
      const activeItem: HTMLElement | null = document.querySelector(".item-media.item-active");
      if (activeItem) {
        const isActiveElementScrolledPast = activeItem.offsetTop < lessonContainer.value.scrollTop;
        const isActiveElementDownBelow =
          activeItem.offsetTop + activeItem.offsetHeight > lessonContainer.value.scrollTop + lessonContainer.value.offsetHeight;
        if (isActiveElementDownBelow || isActiveElementScrolledPast) {
          lessonContainer.value.scrollTo(0, activeItem.offsetTop);
        }
      }
    };
    watch(currentExposureItemMedia, (val) => {
      if (val && props.isHelper) {
        nextTick().then(autoScrollLessonContainer);
      }
    });
    const isAlternateMediaShown = computed(() => getters["lesson/isAlternateMediaShown"]);
    provide("lesson-item-change", handleLessonItemChange);

    const unitText = computed(() => fmtMsg(TeacherClassLessonPlan.Unit));
    const lessonText = computed(() => fmtMsg(TeacherClassLessonPlan.Lesson));
    const remainingText = computed(() => fmtMsg(TeacherClassLessonPlan.Remaining));
    const itemText = computed(() => fmtMsg(TeacherClassLessonPlan.Item));
    const noDataText = computed(() => fmtMsg(TeacherClassLessonPlan.NoData));
    const pageText = computed(() => fmtMsg(TeacherClassLessonPlan.Page));
    const transitionText = computed(() => fmtMsg(TeacherClassLessonPlan.Transition));
    const lessonCompleteText = computed(() => fmtMsg(TeacherClassLessonPlan.LessonComplete));

    return {
      isLpSidebarShrink,
      exposures,
      currentExposure,
      progress,
      remainingTime,
      isShowExposureDetail,
      isTransitionType,
      isCompleteType,
      activityStatistic,
      onClickExposure,
      onClickCloseExposure,
      toggleLpSidebar,
      page,
      onClickPrevNextMedia,
      nextExposureItemMedia,
      iconNext,
      iconPrev,
      NEXT_EXPOSURE,
      PREV_EXPOSURE,
      exposureTypes: ExposureDetailType,
      currentLesson,
      currentUnit,
      unitText,
      lessonText,
      remainingText,
      itemText,
      pageText,
      lessonContainer,
      isOneOneMode,
      exposureTitle,
      showInfo,
      teachingContentEmpty,
      isTransitionBlock,
      hasLongShortcutHeader,
      editableModalRef,
      infoIconRef,
      teachingIconPosition,
      handleMouseOver,
      visible,
      handlePinOrHide,
      infoPopupStatus,
      PopupStatus,
      noDataText,
      isSignalRConnected,
      canNext,
      canPrev,
      isAlternateMediaShown,
      ChevronLeftIcon,
      ChevronRightIcon,
    };
  },
});
