import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6ac7cba3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "absolute inset-0 flex justify-center items-center bg-white" }
const _hoisted_2 = { class: "w-32 max-w-[42%]" }
const _hoisted_3 = { class: "w-full aspect-w-1 aspect-h-1" }
const _hoisted_4 = { class: "w-full h-full rounded-full overflow-hidden border-2 border-white shadow" }
const _hoisted_5 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("img", {
            src: _ctx.src,
            class: "w-full h-full object-cover",
            alt: _ctx.alt
          }, null, 8, _hoisted_5)
        ])
      ])
    ])
  ]))
}