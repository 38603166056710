import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "space-y-2 leading-relaxed text-sm" }
const _hoisted_2 = { class: "text-left" }
const _hoisted_3 = { class: "flex justify-center space-x-3" }
const _hoisted_4 = { class: "text-right border-gray-300 mt-4" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.MsgQuestion), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          type: "button",
          class: "text-base px-2 py-1 text-center rounded-md text-gray-900 shadow-sm focus:ring-gs-purple/80 focus:ring-1 focus:border-gs-purple border border-gray-300 hover:bg-gray-50 w-full",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleYesClick && _ctx.handleYesClick(...args)))
        }, _toDisplayString(_ctx.MsgResponseYes), 1),
        _createElementVNode("button", {
          type: "button",
          class: "text-base px-2 py-1 text-center rounded-md text-gray-900 shadow-sm focus:ring-gs-purple/80 focus:ring-1 focus:border-gs-purple border border-gray-300 hover:bg-gray-50 w-full",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleNoClick && _ctx.handleNoClick(...args)))
        }, _toDisplayString(_ctx.MsgResponseNo), 1)
      ])
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", {
        class: "text-left",
        innerHTML: _ctx.MsgFeedbackPlaceholder
      }, null, 8, _hoisted_5),
      _withDirectives(_createElementVNode("textarea", {
        ref: "textarea",
        type: "text",
        class: "p-3 mb-5 h-36 w-full overflow-hidden text-gray-900 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gs-purple ring-gray-300",
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.feedback) = $event)),
        onInput: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.adjustTextareaHeight && _ctx.adjustTextareaHeight(...args)))
      }, null, 544), [
        [_vModelText, _ctx.feedback]
      ]),
      _createElementVNode("button", {
        type: "button",
        class: "text-white bg-gs-purple hover:bg-gs-purple/90 focus:outline-none rounded-md text-base px-6 py-2 text-center",
        onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)))
      }, _toDisplayString(_ctx.MsgButtonSubmit), 1)
    ], 512), [
      [_vShow, _ctx.showResponse]
    ])
  ], 64))
}