<template>
  <div class="flex flex-col flex-1">
    <!-- One to one control -->
    <div class="flex items-center justify-between" v-if="oneAndOneStatus">
      <button class="text-gs-purple flex items-center gap-x-1" @click="backToClass">
        <ArrowLeftIcon class="h-4 w-4" />
        <span>{{ returnText }}</span>
      </button>
      <div class="text-slate-600">{{ timeCount }}</div>
    </div>
    <!-- One to one control -->
    <div v-else class="flex items-center justify-between">
      <!--      Team control-->
      <div class="flex items-center space-x-2">
        <Tooltip :title="canToggleTeamMode ? null : MsgPreventToggleTeamModeWarning" placement="bottom">
          <div class="flex items-center space-x-2 px-3 h-[38px] border bg-white shadow-sm rounded-md">
            <span class="font-medium">{{ TeamsLocale }}</span>
            <Switch :checked="isTeamMode" :disabled="!canToggleTeamMode" @click="canToggleTeamMode ? handleToggleTeamMode() : null" />
          </div>
        </Tooltip>
        <Tooltip :title="canToggleTeamMode ? MsgEditTeam : MsgPreventToggleTeamModeWarning" placement="bottom">
          <button
            :disabled="!canToggleTeamMode"
            type="button"
            class="rounded-md border bg-white p-2 text-sm font-medium text-gray-900 shadow-sm hover:bg-gray-50"
            @click="handleSettingTeams()"
          >
            <span class="h-5 w-5 flex items-center justify-center">
              <PencilIcon class="h-5 w-5 text-slate-600" />
            </span>
          </button>
        </Tooltip>
      </div>
      <!-- Team control-->
      <StudentControls
        @disable-all="onClickDisableAll"
        @enable-all="onClickEnableAll"
        @add-sticker-all="onClickStickerAll"
        @capture-all="onClickCaptureAll"
        :isTeamMode="isTeamMode"
      />
    </div>
    <StudentAll :students="students" />
  </div>
</template>
<!--<style lang="scss" scoped src="./student-gallery.scss"></style>-->
<script lang="ts" src="./student-gallery.ts"></script>
<style lang="scss" scoped>
.ant-switch-checked {
  @apply bg-gs-purple;
}
</style>
