<template>
  <div class="space-y-2 leading-relaxed text-sm">
    <p class="text-left">
      {{ MsgQuestion }}
    </p>
    <div class="flex justify-center space-x-3">
      <button
        type="button"
        class="text-base px-2 py-1 text-center rounded-md text-gray-900 shadow-sm focus:ring-gs-purple/80 focus:ring-1 focus:border-gs-purple border border-gray-300 hover:bg-gray-50 w-full"
        @click="handleYesClick"
      >
        {{ MsgResponseYes }}
      </button>
      <button
        type="button"
        class="text-base px-2 py-1 text-center rounded-md text-gray-900 shadow-sm focus:ring-gs-purple/80 focus:ring-1 focus:border-gs-purple border border-gray-300 hover:bg-gray-50 w-full"
        @click="handleNoClick"
      >
        {{ MsgResponseNo }}
      </button>
    </div>
  </div>

  <div v-show="showResponse" class="text-right border-gray-300 mt-4">
    <p class="text-left" v-html="MsgFeedbackPlaceholder"></p>
    <textarea
      ref="textarea"
      type="text"
      class="p-3 mb-5 h-36 w-full overflow-hidden text-gray-900 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gs-purple ring-gray-300"
      v-model="feedback"
      @input="adjustTextareaHeight"
    ></textarea>
    <button
      type="button"
      class="text-white bg-gs-purple hover:bg-gs-purple/90 focus:outline-none rounded-md text-base px-6 py-2 text-center"
      @click="handleSubmit"
    >
      {{ MsgButtonSubmit }}
    </button>
  </div>
</template>
<script lang="ts" src="./calling-quality-survey.ts"></script>
