import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fb113164"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "independent-progress" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Progress = _resolveComponent("Progress")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Progress, {
      percent: _ctx.currentPercent,
      strokeColor: _ctx.barColor,
      strokeWidth: 8,
      showInfo: false
    }, null, 8, ["percent", "strokeColor"])
  ]))
}