<template>
  <div class="flex items-center space-x-1 select-none">
    <Tooltip :title="stickerAllText" placement="bottom">
      <button
        type="button"
        class="relative rounded-md border bg-white p-2 text-sm font-medium text-gray-900 shadow-sm hover:bg-gray-50"
        @click="onClickStickerAll"
      >
        <img :src="require('@/assets/teacher-class/sticker-star-small.svg')" :alt="stickerAllText" class="h-5 w-5" />
        <PlusIcon class="absolute left-1/2 top-1/2 text-white -translate-x-1/2 -translate-y-1/2 h-4 w-4" />
      </button>
    </Tooltip>
    <Tooltip :title="isAllAudioMuted ? unmuteAllText : muteAllText" placement="bottom">
      <button type="button" class="rounded-md border bg-white p-2 text-sm font-medium text-gray-900 shadow-sm hover:bg-gray-50" @click="toggleAudio">
        <span class="h-5 w-5 flex items-center justify-center">
          <MicrophoneIcon icon-class="h-5 w-5 text-slate-600" :enabled="isAllAudioMuted" />
        </span>
      </button>
    </Tooltip>
    <Tooltip :title="isAllVideoHidden ? showAllText : hideAllText" placement="bottom">
      <button type="button" class="rounded-md border bg-white p-2 text-sm font-medium text-gray-900 shadow-sm hover:bg-gray-50" @click="toggleVideo">
        <span class="h-5 w-5 flex items-center justify-center">
          <component
            class="h-5 w-5 text-slate-600"
            :is="isAllVideoHidden ? VideoCameraIcon : VideoCameraSlashIcon"
            :alt="isAllVideoHidden ? showAllText : hideAllText"
          />
        </span>
      </button>
    </Tooltip>
    <Tooltip :title="disableAllText" placement="bottom">
      <button
        type="button"
        class="rounded-md border bg-white p-2 text-sm font-medium text-gray-900 shadow-sm hover:bg-gray-50 disabled:bg-slate-200 disabled:grayscale"
        :disabled="isAllPaletteHidden"
        @click="onClickDisableAll"
      >
        <span class="h-5 w-5 flex items-center justify-center">
          <img
            class="h-5 w-5 text-slate-600"
            :class="[isAllPaletteHidden && 'opacity-60']"
            :src="
              isAllPaletteHidden
                ? require(`@/assets/teacher-class/touch-on-small-blue.svg`)
                : require(`@/assets/teacher-class/touch-off-small-blue.svg`)
            "
            :alt="disableAllText"
          />
        </span>
      </button>
    </Tooltip>
    <Tooltip :title="independentButtonText" placement="bottomRight" v-if="independentModeShown">
      <button
        type="button"
        class="rounded-md border bg-white p-2 text-sm font-medium text-gray-900 shadow-sm hover:bg-gray-50 disabled:bg-slate-200 disabled:grayscale"
        :disabled="independentModeDisabled"
        @click="toggleIndependentMode"
      >
        <span class="h-5 w-5 flex items-center justify-center">
          <img
            class="h-5 w-5 text-slate-600"
            :class="[independentModeDisabled && 'opacity-60']"
            :src="
              isIndependentMode
                ? require(`@/assets/images/student-control/independent-mode-end.png`)
                : require(`@/assets/images/student-control/independent-mode.png`)
            "
            :alt="isIndependentMode ? MsgStopIndependentMode : MsgStartIndependentMode"
          />
        </span>
      </button>
    </Tooltip>
  </div>
</template>

<!--<style lang="scss" scoped src="./student-controls.scss"></style>-->
<script lang="ts" src="./student-controls.ts"></script>
