<template>
  <div
    v-if="!hiddenByOneOneMode"
    class="relative rounded-xl group bg-white overflow-hidden"
    :class="[
      'w-full transition-all',
      focusedStudent && 'z-[15] shadow',
      isOneToOneStudent && oneAndOne && 'one-student-mode animate__animated animate__zoomIn',
      !isOneToOneStudent && oneAndOne && 'animate__animated animate__zoomOut',
      !isTurnOnCamera && 'shadow',
    ]"
    :id="student.id + '__sub-wrapper'"
    @mouseleave="onMouseChange(false)"
    ref="studentRef"
    :style="{
      transform: focusedStudent && !isOneToOneStudent ? `scale(${actualScaleRatio})` : '',
    }"
  >
    <div class="relative aspect-w-16 aspect-h-9" :id="student.id + '__wrapper'">
      <div class="w-full h-full" @mouseover="onMouseChange(true)">
        <div
          v-show="isTurnOnCamera && !isStudentOneToOneWithAnotherTeacher"
          class="w-full h-full flex justify-center [&_canvas]:object-cover"
          :id="student.id"
          ref="agoraVideoContainerRef"
        />
        <div v-show="!isTurnOnCamera || isStudentOneToOneWithAnotherTeacher" class="w-full h-full flex justify-center items-center">
          <div :class="[scaleOption === 1 ? 'w-36 h-36' : 'w-24 h-24']" class="rounded-full overflow-hidden border-2 border-white shadow">
            <img :class="['w-full h-full object-cover']" :alt="student.englishName" :src="avatarUrl" />
          </div>
        </div>
        <div :class="['absolute inset-0 rounded-xl border-4', borderLayers[0]]" v-if="borderLayers[0]" />
        <div :class="['absolute inset-1 rounded-lg border-4', borderLayers[1]]" v-if="borderLayers[1]" />
        <div :class="['absolute inset-2 rounded border-4', borderLayers[2]]" v-if="borderLayers[2]" />
        <InOneIcon class="absolute right-3 top-2 w-8 h-8" v-if="isStudentOneToOneWithAnotherTeacher" />
        <div v-if="isLowBandWidth" class="absolute right-3 z-[1]" :class="[isIndependentMode ? 'bottom-8' : 'bottom-2']">
          <WifiIcon class="animate-pulse h-8 w-8 text-red-600" />
        </div>
        <div
          class="absolute bottom-2 inset-x-3 space-y-1"
          :class="[focusedStudent && !isOneToOneStudent && 'origin-bottom-left']"
          @mouseover="onMouseChange(true)"
          :style="{
            transform: focusedStudent && !isOneToOneStudent ? `scale(${1 / actualScaleRatio})` : '',
          }"
        >
          <span
            class="inline-flex space-x-1 flex-nowrap items-center max-w-[75%] text-white text-xs px-2 py-1 rounded transition bg-gs-green/80 z-[1]"
            :class="[!isNotJoinedWS ? 'cursor-pointer' : 'pointer-events-none', studentTeam && 'shadow']"
            @click="enterOneToOneWithStudent"
            :title="student.englishName"
            :style="[studentTeam && `background: ${studentTeam.color}; color: ${calColorByBackground(studentTeam.color)}`]"
          >
            <span class="truncate max-w[90%]" :class="[studentTeam && 'font-bold']">{{ student.englishName }}</span>
            <span class="flex-1 truncate" v-if="studentTeam"> | {{ studentTeam?.name }}</span>
            <MicrophoneIcon v-if="!student?.audioEnabled" icon-class="h-4 w-4" class="flex-shrink-0" />
          </span>
          <IndependentProgress v-if="isIndependentMode && student && !oneAndOne" :studentId="student.id" />
        </div>
        <StudentCardActions
          v-if="!isNotJoinedWS && !isStudentOneToOneWithAnotherTeacher"
          :student="student"
          :focus-disabled="scaleOption === 1"
          :class="['z-[1] opacity-0 transition-opacity', isMouseEntered && 'opacity-100']"
          :focusedStudent="focusedStudent"
          :style="{
            transform: focusedStudent && !isOneToOneStudent ? `scale(${1 / actualScaleRatio}) translateX(-${50 * actualScaleRatio}%)` : '',
          }"
        />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped src="./student-card.scss"></style>
<script lang="ts" src="./student-card.ts"></script>
