import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, createSlots as _createSlots, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mask-grand-access"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DynamicModal = _resolveComponent("DynamicModal")!
  const _component_SettingTeamsModal = _resolveComponent("SettingTeamsModal")!
  const _component_LoadingPage = _resolveComponent("LoadingPage")!
  const _component_NotFoundPage = _resolveComponent("NotFoundPage")!
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_AccessDeniedPage = _resolveComponent("AccessDeniedPage")!
  const _component_AppFooter = _resolveComponent("AppFooter")!
  const _component_MainLayout = _resolveComponent("MainLayout")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Spin = _resolveComponent("Spin")!
  const _component_LostConnectionWarning = _resolveComponent("LostConnectionWarning")!
  const _component_DeviceWarning = _resolveComponent("DeviceWarning")!
  const _component_UnsupportedBrowserWarning = _resolveComponent("UnsupportedBrowserWarning")!
  const _component_Toast = _resolveComponent("Toast")!
  const _component_Notification = _resolveComponent("Notification")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DynamicModal),
    _createVNode(_component_SettingTeamsModal),
    (_ctx.appView === _ctx.AppView.Blank)
      ? (_openBlock(), _createBlock(_component_LoadingPage, { key: 0 }))
      : (_ctx.appView === _ctx.AppView.NotFound)
        ? (_openBlock(), _createBlock(_component_NotFoundPage, { key: 1 }))
        : (_ctx.appView === _ctx.AppView.UnAuthorized)
          ? (_openBlock(), _createBlock(_component_MainLayout, { key: 2 }, {
              header: _withCtx(() => [
                _createVNode(_component_AppHeader, { title: _ctx.siteTitle }, null, 8, ["title"])
              ]),
              footer: _withCtx(() => [
                _createVNode(_component_AppFooter)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_AccessDeniedPage)
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_MainLayout, { key: 3 }, _createSlots({
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_component_router_view, {
                  key: _ctx.$route.fullPath
                })),
                (_ctx.isMaskGrandAccess)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      _createVNode(_component_Spin, { class: "ant-custom-home" })
                    ]))
                  : _createCommentVNode("", true),
                _createVNode(_component_LostConnectionWarning),
                _createVNode(_component_DeviceWarning),
                _createVNode(_component_UnsupportedBrowserWarning)
              ]),
              _: 2
            }, [
              (_ctx.isHeaderVisible)
                ? {
                    name: "header",
                    fn: _withCtx(() => [
                      _createVNode(_component_AppHeader, { title: _ctx.siteTitle }, null, 8, ["title"])
                    ]),
                    key: "0"
                  }
                : undefined,
              (_ctx.isFooterVisible)
                ? {
                    name: "footer",
                    fn: _withCtx(() => [
                      _createVNode(_component_AppFooter)
                    ]),
                    key: "1"
                  }
                : undefined
            ]), 1024)),
    _createVNode(_component_Toast),
    _createVNode(_component_Notification)
  ], 64))
}