import { computed, defineComponent } from "vue";
import WarningMessage from "../warning-message/warning-message.vue";
import { fmtMsg } from "vue-glcommonui";
import { CommonLocale } from "@/locales/localeid";
import { isChromeBrowser, isDesktopBrowser, isEdgeBrowser, isOperaBrowser, isSafariBrowser, isYandexBrowser } from "@/utils/utils";
import { useRoute } from "vue-router";
import { Paths } from "@/utils/paths";
export default defineComponent({
  components: { WarningMessage },
  setup() {
    const route = useRoute();
    const appPath = computed(() => route.path);
    const appFullPath = computed(() => route.fullPath);
    const isCorrectPageToShow = computed(
      () =>
        //dashboard and class setup of both teacher & parent
        appFullPath.value === Paths.Parent ||
        appFullPath.value === Paths.Teacher ||
        appPath.value === Paths.ParentClassSetup ||
        appPath.value === Paths.TeacherClassSetup,
    );
    const isSupportedBrowser = computed(() => {
      return isChromeBrowser() || isSafariBrowser() || isOperaBrowser() || isEdgeBrowser() || isYandexBrowser();
    });
    const isNotSupportedBrowser = computed(() => {
      return isDesktopBrowser && isCorrectPageToShow.value && !isSupportedBrowser.value;
    });
    const chromeLink = "https://www.google.com/intl/en_us/chrome/";

    const MsgBrowserNotSupported = computed(() => fmtMsg(CommonLocale.BrowserNotSupported));
    return {
      isNotSupportedBrowser,
      MsgBrowserNotSupported,
      chromeLink,
    };
  },
});
