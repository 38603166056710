// Follow Agora docs, the volume is an integer ranging from 0 to 100. Usually a user with volume above 60 is a speaking user.
// https://api-ref.agora.io/en/video-sdk/web/4.x/interfaces/iagorartcclient.html#event_volume_indicator:~:text=The%20volume%20is%20an%20integer%20ranging%20from%200%20to%20100.%20Usually%20a%20user%20with%20volume%20above%2060%20is%20a%20speaking%20user.
export const AGORA_MINIMUM_USER_VOLUME = 45;

export const NEXT_EXPOSURE = 1;
export const PREV_EXPOSURE = 2;
export const SESSION_MAXIMUM_IMAGE = 10;
export const MAX_ZOOM_RATIO = 4;
export const MIN_ZOOM_RATIO = 1;
export const IMAGE_QUALITY = 1.0;
export const ZOOM_STEP = 0.1;
export const DEFAULT_BEAR_DISCONNECTED_VIDEO = `https://d2tdsihk8dajp3.cloudfront.net/f0b49b43-e9db-48f9-a363-76e21f0fb4e4/GSv4-U15-REP-Jonny and Jenny Bear Fun On the Farm.m3u8`;
export const HELPER_BECOME_TEACHER_NOTIFY_DURATION = 3000;
export enum Colors {
  Black = "black",
  Red = "red",
  Orange = "orange",
  Yellow = "yellow",
  Green = "green",
  Blue = "blue",
  Purple = "purple",
  White = "white",
}
export enum StrokeSizes {
  Small = 2,
  Large = 4,
}
const genLocalStorageKey = (key: string) => `__RT_${key}__`;

//localstorage key
export const CAMERA_ID_KEY = genLocalStorageKey("CAMERA_ID");
export const MICROPHONE_ID_KEY = genLocalStorageKey("MICROPHONE_ID");
export const SPEAKER_ID_KEY = genLocalStorageKey("SPEAKER_ID");
export const I18N_LOCALE = genLocalStorageKey("I18N_LOCALE");
export const CONTENT_SIGNATURE = genLocalStorageKey("CONTENT_SIGNATURE");
export const SESSION_ID = genLocalStorageKey("SESSION_ID");

// session storage key
export const DEVICE_ID_KEY = genLocalStorageKey("DEVICE_ID");

export const PARENT_PATH_REGEX = /\/parent/;
export const TEACHER_PATH_REGEX = /\/teacher/;
export const TEACHER_CALENDAR_PATH_REGEX = /\/teacher-calendars/;
export const TEACHER_SCHEDULE_INFO_PATH_REGEX = /\/teacher-schedule-info/;
