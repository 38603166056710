import { computed, defineComponent } from "vue";
import WarningMessage from "../warning-message/warning-message.vue";
import { useRoute } from "vue-router";
import { Paths } from "@/utils/paths";
import { fmtMsg } from "vue-glcommonui";
import { CommonLocale } from "@/locales/localeid";
import { isAndroidDevice, isDesktopBrowser, isUnknownDevice } from "@/utils/utils";
import { useStore } from "vuex";
export default defineComponent({
  components: { WarningMessage },
  setup() {
    const { getters } = useStore();
    const route = useRoute();
    const appFullPath = computed(() => route.fullPath);
    const appPath = computed(() => route.path);
    const isParent = computed(() => getters["auth/isParent"]);
    const isCorrectPageToShow = computed(
      () =>
        //dashboard and class setup of both teacher & parent
        appFullPath.value === Paths.Parent ||
        appFullPath.value === Paths.Teacher ||
        appPath.value === Paths.ParentClassSetup ||
        appPath.value === Paths.TeacherClassSetup,
    );
    enum StoreLinks {
      iOS = "https://apps.apple.com/app/id1638056937",
      Android = "https://play.google.com/store/apps/details?id=com.gsconnect.prod",
      None = "#",
    }
    const isShowWarningDevice = computed(() => isCorrectPageToShow.value && !isDesktopBrowser);
    const storeLink = computed(() => (isUnknownDevice || !isParent.value ? StoreLinks.None : isAndroidDevice ? StoreLinks.Android : StoreLinks.iOS));

    const MsgWarningDeviceTeacher = computed(() => fmtMsg(CommonLocale.CommonWarningDeviceTeacher));
    const MsgWarningDeviceStudent = computed(() => fmtMsg(CommonLocale.CommonWarningDeviceStudent));
    const warningDevicesMsg = computed(() => (isParent.value ? MsgWarningDeviceStudent.value : MsgWarningDeviceTeacher.value));

    return { warningDevicesMsg, isShowWarningDevice, storeLink };
  },
});
