import { ref, onUnmounted } from "vue";
import { Logger } from "@/utils/logger";

export const MAX_RELOAD_ATTEMPTS = 20;
export const RELOAD_TIMEOUT = 4000;
export const useImageLoader = () => {
  let timeoutId: any;
  const reloadKey = ref(0);
  const handleImageLoadError = () => {
    if (reloadKey.value > MAX_RELOAD_ATTEMPTS) return;
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      Logger.log(`Retrying... (Attempt ${reloadKey.value})`);
      reloadKey.value += 1;
    }, RELOAD_TIMEOUT);
  };

  const resetReloadKey = () => {
    if (reloadKey.value === 0) return;
    reloadKey.value = 0;
  };
  // Clear the timeout when the component unmounts
  onUnmounted(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  });
  return {
    reloadKey,
    handleImageLoadError,
    resetReloadKey,
  };
};
