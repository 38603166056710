import IconImage from "@/assets/images/image.png";
import PhotoCamera from "@/assets/images/photo-camera.png";
import IconPaletteOff from "@/assets/teacher-class/touch-off-small-blue.svg";
import IconPaletteOn from "@/assets/teacher-class/touch-on-small-blue.svg";
import { CaptureNotification } from "@/locales/localeid";
import { StudentState } from "@/store/room/interface";
import { SESSION_MAXIMUM_IMAGE } from "@/utils/constant";
import { defineComponent } from "@vue/runtime-core";
import { notification } from "ant-design-vue";
import { gsap } from "gsap";
import { debounce } from "lodash";
import { computed, ComputedRef, inject, ref, watch } from "vue";
import { fmtMsg, MatIcon } from "vue-glcommonui";
import { useStore } from "vuex";
import { MicrophoneIcon } from "@/components/common";
import { LoadingOutlined } from "@ant-design/icons-vue";
import { VideoCameraIcon, VideoCameraSlashIcon, QuestionMarkCircleIcon, ArrowsPointingOutIcon, ArrowsPointingInIcon } from "@heroicons/vue/20/solid";
import { useDevicesLocking } from "@/hooks/use-devices-locking";

export default defineComponent({
  components: {
    MatIcon,
    MicrophoneIcon,
    QuestionMarkCircleIcon,
    LoadingOutlined,
  },
  props: {
    student: { type: Object as () => StudentState, required: true },
    show: Boolean,
    allowExpend: Boolean,
    isExpended: Boolean,
    focusedStudent: Boolean,
    focusDisabled: Boolean,
  },
  setup(props) {
    const { isCameraLocking, isMicrophoneLocking } = useDevicesLocking();
    const store = useStore();
    const paletteIcon = computed(() => (props.student.isPalette ? IconPaletteOn : IconPaletteOff));
    const isRaisingHand = computed(() => props.student.raisingHand);
    const isShowExpandIcon = computed(() => store.getters["teacherRoom/getStudentModeOneId"] !== props.student.id);
    const isAllPaletteHidden: ComputedRef<boolean> = computed(() => store.getters["teacherRoom/isAllPaletteHidden"]);
    const currentSchoolId = computed(() => store.getters["teacher/currentSchoolId"]);
    const isOnePalette = computed(() => {
      if (isAllPaletteHidden.value) {
        return true;
      }
      return props.student.isPalette;
    });
    const enableVideoText = computed(() =>
      fmtMsg(CaptureNotification.EnableStudentVideo, {
        studentName: props.student.englishName,
      }),
    );
    const reachedMaximumText = computed(() =>
      fmtMsg(CaptureNotification.ReachedMaximum, {
        studentName: props.student.englishName,
      }),
    );
    const onClickClearRaisingHand = async () => {
      await store.dispatch("teacherRoom/clearStudentRaisingHand", {
        id: props.student.id,
      });
    };
    const toggleAudio = debounce(async () => {
      if (isMicrophoneLocking.value) return;
      await store.dispatch("teacherRoom/setStudentAudio", {
        id: props.student.id,
        enable: !props.student.audioEnabled,
      });
    }, 250);
    const toggleVideo = debounce(async () => {
      if (isCameraLocking.value) return;
      await store.dispatch("teacherRoom/setStudentVideo", {
        id: props.student.id,
        enable: !props.student.videoEnabled,
      });
    }, 250);
    const toggleAnnotation = async () => {
      if (isOnePalette.value) {
        if (!props.student.isPalette) {
          await store.dispatch("teacherRoom/disableAllStudentsPalette");
        }
        await store.dispatch("teacherRoom/toggleAnnotation", {
          studentId: props.student.id,
          isEnable: !props.student.isPalette,
        });
      }
    };
    const addABadge = async () => {
      await store.dispatch("teacherRoom/setStudentBadge", {
        id: props.student.id, // studentId
        badge: 1, // increase by 1
      });
    };
    const toolEnter = (element: HTMLElement) => {
      gsap.from(element.children[0], {
        translateX: 0,
        translateY: 0,
        opacity: 0,
        clearProps: "all",
        ease: "Power2.easeInOut",
      });
    };
    const updateFocusStudent: any = inject("updateFocusStudent");
    const handleExpand = () => {
      if (props.focusedStudent) {
        return updateFocusStudent();
      }
      updateFocusStudent(props.student.id);
    };

    const captureImage = async () => {
      if (!props.student.videoEnabled) {
        notification.info({
          message: enableVideoText.value,
          duration: 3,
        });
        return;
      }
      if (props.student.imageCapturedCount >= SESSION_MAXIMUM_IMAGE) {
        notification.info({
          message: reachedMaximumText.value,
          duration: 3,
        });
        return;
      }
      await store.dispatch("teacherRoom/sendRequestCaptureImage", {
        isCaptureAll: false,
        studentId: props.student.id,
      });
    };

    return {
      isRaisingHand,
      paletteIcon,
      onClickClearRaisingHand,
      toggleAudio,
      toggleVideo,
      toggleAnnotation,
      addABadge,
      toolEnter,
      handleExpand,
      isShowExpandIcon,
      isOnePalette,
      captureImage,
      IconImage,
      PhotoCamera,
      currentSchoolId,
      VideoCameraIcon,
      VideoCameraSlashIcon,
      ArrowsPointingOutIcon,
      ArrowsPointingInIcon,
      isMicrophoneLocking,
      isCameraLocking,
    };
  },
});
