<template>
  <div :class="isLpSidebarShrink ? 'w-6 mr-4' : 'w-60 2xl:w-80'" class="pt-24 3xl:pt-28 relative transition-all bg-slate-200">
    <div
      :class="[isLpSidebarShrink ? 'opacity-0 duration-75' : 'duration-500']"
      class="transition-opacity relative overflow-hidden flex flex-col gap-y-4 h-full"
    >
      <div class="px-3 2xl:px-5">
        <transition
          enter-active-class="transition ease-out duration-200 delay-200"
          enter-from-class="opacity-0 translate-x-1"
          enter-to-class="opacity-100 translate-x-0"
          leave-active-class="transition ease-in duration-100"
          leave-from-class="opacity-100 translate-x-0"
          leave-to-class="opacity-0 translate-x-1"
        >
          <div v-if="isShowExposureDetail" class="flex text-gs-purple justify-between items-center gap-x-6 pb-4">
            <div class="flex items-center min-w-0 gap-x-2 2xl:gap-x-4">
              <ChevronLeftIcon
                :class="isHelper ? 'cursor-not-allowed' : 'cursor-pointer transition-colors hover:text-gs-purple/60'"
                class="flex-shrink-0 h-4 w-4 2xl:h-6 2xl:w-6"
                @click="onClickCloseExposure"
              />
              <div class="min-w-0 flex-auto">
                <p class="text-sm leading-6 font-medium">{{ exposureTitle }}</p>
              </div>
            </div>
            <div
              @mouseover="handleMouseOver"
              :class="[infoPopupStatus !== PopupStatus.Pinned && 'cursor-pointer']"
              class="min-h-[28px] flex justify-center items-center"
              id="lp-info"
            >
              <InformationCircleIcon ref="infoIconRef" class="h-4 w-4 2xl:h-5 2xl:w-5" />
            </div>
          </div>
        </transition>
        <div class="bg-slate-100 text-slate-600 rounded-xl px-4 pt-3 pb-2 2xl:pt-4 2xl:pb-3">
          <div class="w-full h-2 bg-slate-300 rounded overflow-hidden">
            <div
              class="bg-gs-purple h-full rounded text-xs text-white flex justify-center items-center w-full origin-left transition-all duration-500"
              :style="{ transform: `scaleX(${progress})` }"
            />
          </div>
          <div class="text-sm mt-1 relative text-center">{{ remainingText }} {{ remainingTime }}</div>
        </div>
        <transition
          enter-active-class="transition ease-out duration-200 delay-200"
          enter-from-class="opacity-0 translate-x-1"
          enter-to-class="opacity-100 translate-x-0"
          leave-active-class="transition ease-in duration-100"
          leave-from-class="opacity-100 translate-x-0"
          leave-to-class="opacity-0 translate-x-1"
        >
          <div v-if="isShowExposureDetail" class="flex text-slate-600 justify-between items-center mt-2">
            <div class="pl-4 flex space-x-1">
              <div class="text-xs text-slate-500 space-x-4">
                <span>{{ itemText }} {{ activityStatistic }}</span>
                <span>{{ pageText }} {{ page }}</span>
              </div>
            </div>
            <div class="flex space-x-1">
              <div
                class="h-6 w-6 rounded-lg bg-slate-100 flex justify-center items-center"
                :class="[
                  canPrev ? 'bg-slate-100 text-slate-600 transition-colors' : 'bg-slate-300 text-slate-400',
                  isHelper && 'cursor-not-allowed',
                  canPrev && !isHelper && 'hover:bg-white cursor-pointer',
                ]"
                @click="onClickPrevNextMedia(PREV_EXPOSURE)"
              >
                <ChevronLeftIcon class="h-4 w-4" />
              </div>
              <div
                class="h-6 w-6 rounded-lg bg-slate-100 flex justify-center items-center"
                :class="[
                  canNext ? 'bg-slate-100 text-slate-600 transition-colors' : 'bg-slate-300 text-slate-400',
                  isHelper && 'cursor-not-allowed',
                  canNext && !isHelper && 'hover:bg-white cursor-pointer',
                ]"
                @click="onClickPrevNextMedia(NEXT_EXPOSURE)"
              >
                <ChevronRightIcon class="h-4 w-4" />
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div ref="lessonContainer" class="relative flex-1 overflow-auto pb-8 nice-scroll">
        <transition
          enter-active-class="transition ease-out duration-200 delay-200"
          enter-from-class="opacity-0 translate-x-1"
          enter-to-class="opacity-100 translate-x-0"
          leave-active-class="transition ease-in duration-100"
          leave-from-class="opacity-100 translate-x-0"
          leave-to-class="opacity-0 translate-x-1"
        >
          <div class="space-y-3 2xl:space-y-4 mt-2" v-if="isShowExposureDetail">
            <ExposureDetail
              :type="exposureTypes.TRANSITION_BLOCK"
              v-if="isTransitionType"
              :exposure="currentExposure"
              @click-back="onClickCloseExposure"
            />
            <ExposureDetail
              :type="exposureTypes.LP_COMPLETE_BLOCK"
              v-else-if="isCompleteType"
              :exposure="currentExposure"
              @click-back="onClickCloseExposure"
            />
            <template v-else>
              <ExposureDetail :type="exposureTypes.VCP_BLOCK" :exposure="currentExposure" @click-back="onClickCloseExposure" />
              <ExposureDetail :type="exposureTypes.CONTENT_BLOCK" :exposure="currentExposure" @click-back="onClickCloseExposure" />
              <ExposureDetail :type="exposureTypes.TEACHING_ACTIVITY_BLOCK" :exposure="currentExposure" @click-back="onClickCloseExposure" />
              <ExposureDetail
                :type="exposureTypes.ALTERNATE_MEDIA_BLOCK"
                v-show="isAlternateMediaShown"
                :exposure="currentExposure"
                @click-back="onClickCloseExposure"
              />
            </template>
          </div>
        </transition>
        <transition
          enter-active-class="transition ease-out duration-200 delay-200"
          enter-from-class="opacity-0 -translate-x-1"
          enter-to-class="opacity-100 translate-x-0"
          leave-active-class="transition ease-in duration-100"
          leave-from-class="opacity-100 translate-x-0"
          leave-to-class="opacity-0 -translate-x-1"
        >
          <div class="px-3 2xl:px-5 cursor-pointer text-slate-600" v-if="!isShowExposureDetail">
            <LessonActivity
              v-for="exposure in exposures"
              :key="exposure.id"
              :id="exposure.id"
              :title="exposure.name"
              :type="exposure.type"
              :duration="exposure.duration"
              :status="exposure.status"
              :content-root-type="exposure.contentRootType"
              @click="() => onClickExposure(exposure)"
            />
          </div>
        </transition>
        <div v-if="isHelper" class="absolute inset-0 cursor-not-allowed z-30" />
      </div>
      <div class="absolute bottom-0 inset-x-5 h-8 bg-gradient-to-t from-slate-200 via-slate-200/60 to-transparent pointer-events-none" />
    </div>
    <div
      :class="[
        isLpSidebarShrink ? 'bg-gs-purple hover:bg-gs-purple/90 text-white' : 'bg-slate-100 hover:bg-white text-slate-600',
        isSignalRConnected && !isHelper && 'cursor-pointer',
        isHelper && 'cursor-not-allowed',
      ]"
      @click="isSignalRConnected && toggleLpSidebar()"
      class="absolute top-32 right-0 shadow translate-x-1/2 h-6 w-6 2xl:h-7 2xl:w-7 flex items-center justify-center rounded-md transition-colors"
    >
      <component :is="isLpSidebarShrink ? ChevronRightIcon : ChevronLeftIcon" class="h-4 w-4" />
    </div>
    <PinningModal :status="infoPopupStatus" :position="teachingIconPosition" :onPinOrHide="handlePinOrHide">
      <template #final-modal-content>
        <div v-if="currentExposure">
          <div v-if="!teachingContentEmpty">
            <ul>
              <li v-for="{ id, textContent } in currentExposure.teachingActivityBlockItems" :key="id">
                <div v-html="textContent" />
              </li>
            </ul>
          </div>
          <div v-if="isTransitionBlock">
            <ul>
              <li>
                <div v-html="currentExposure.name" />
              </li>
            </ul>
          </div>
          <div v-if="!isTransitionBlock && teachingContentEmpty" class="flex flex-col items-center">
            <Empty imageStyle="max-height: 45px" :description="''" />
            <div>{{ noDataText }}</div>
          </div>
        </div>
      </template>
    </PinningModal>
  </div>
</template>
<!--<style lang="scss" scoped src="./lesson-plan.scss"></style>-->
<script lang="ts" src="./lesson-plan.ts"></script>
<style scoped>
ul {
  list-style: disc;
}
</style>
