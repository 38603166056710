import { ExposureContentModel, ExposureItemMediaModel, ExposureItemModel, LessonPlanModel, MediaSlot } from "@/models";
import { LessonService } from "@/services";
import { ActionContext, ActionTree } from "vuex";
import {
  ContentRootTypeFromValue,
  LPItemMetadata,
  Exposure,
  ExposureItem,
  ExposureItemMedia,
  ExposureStatus,
  ExposureTypeFromValue,
  LessonState,
  TeachingActivityTarget,
} from "./state";
import { fromReader } from "@/utils/exposure-item-generator";
import {
  DEFAULT_ALTERNATE_MEDIA_BLOCK_ITEM_NAME,
  DEFAULT_CONTENT_BLOCK_ITEM_NAME,
  DEFAULT_RESOLUTION,
  DEFAULT_TEACHING_ACTIVITY_BLOCK_ITEM_NAME,
  getContentSignature,
} from "./utils";
import { ExposureDetailType, getExposureFirstSlide } from "@/views/teacher-class/components/lesson-plan/lesson-plan";
import { defineVuexName, vuexName, VuexNames } from "@/store/utils";
import { ToggleTargetModel } from "@/ws";
import { TeacherRoomManager } from "@/manager/room/teacher.manager";
import { StudentRoomManager } from "@/manager/room/student.manager";

interface LessonActionsInterface<S, R> {
  setInfo(store: ActionContext<S, R>, payload: any): any;
  setExposures(
    store: ActionContext<S, R>,
    payload: {
      exposures: Exposure[];
    },
  ): any;
  setCurrentExposure(store: ActionContext<S, R>, payload: { id: string }): any;
  setCurrentExposureItemMedia(store: ActionContext<S, R>, payload: { id: string; exposureType?: ExposureDetailType }): any;
  setExposureStatus(store: ActionContext<S, R>, payload: { id: string; status: ExposureStatus }): any;
  setIsBlackOut(store: ActionContext<S, R>, p: { IsBlackOut: boolean }): any;
  setClickedExposureItem(store: ActionContext<S, R>, payload: { id: string }): any;
}

interface LessonActions<S, R> extends ActionTree<S, R>, LessonActionsInterface<S, R> {}
export enum StorageContainerEnum {
  Audio = 1, // for audio
  Page = 2, // for image
}

const actions: LessonActions<LessonState, any> = {
  async setInfo(store: ActionContext<LessonState, any>, payload: { lessonPlan: LessonPlanModel; isSetCurrentExposure: boolean }) {
    if (!payload) return;
    const signature = await getContentSignature({
      userCountryCode: store.rootGetters["auth/userCountryCode"],
    });
    const { url, data } = signature;
    const buildUrl = (src: string, type: StorageContainerEnum): string => {
      const containerData = data.find((item) => item.type === type);
      if (!containerData) return "";
      const { container, signature } = containerData;
      return `${url}${container}/${src}${signature}`;
    };
    const exposures: Array<Exposure> = [];
    const imagesToPreLoad: string[] = [];
    for (const e of payload.lessonPlan.contents) {
      const items: Array<ExposureItem> = e.contents.map((c: ExposureItemModel) => {
        const media: Array<ExposureItemMedia> = c.page.map((p: ExposureItemMediaModel) => {
          const imageUrl = buildUrl(p.url, StorageContainerEnum.Page);
          imagesToPreLoad.push(imageUrl);
          return {
            id: p.id,
            image: {
              url: imageUrl,
              width: p.resolution ? parseInt(p.resolution.split("X")[0]) : parseInt(DEFAULT_RESOLUTION.split("X")[0]),
              height: p.resolution ? parseInt(p.resolution.split("X")[1]) : parseInt(DEFAULT_RESOLUTION.split("X")[1]),
            },
            teacherUseOnly: p.teacherUseOnly,
            exposureType: ExposureDetailType.VCP_BLOCK,
          };
        });
        return {
          id: c.id,
          name: c.title,
          media: media,
        };
      });

      const newPage = e.page ? e.page.map((p: any) => ({ ...p, page: [{ ...p }] })) : [];
      const contentBlockItems: Array<ExposureItem> = newPage.map((c: any) => {
        const media: Array<ExposureItemMedia> = c.page.map((p: any) => {
          const imageUrl = buildUrl(p.url, StorageContainerEnum.Page);
          imagesToPreLoad.push(imageUrl);
          return {
            id: p.id,
            image: {
              url: imageUrl,
              width: p.resolution ? parseInt(p.resolution.split("X")[0]) : parseInt(DEFAULT_RESOLUTION.split("X")[0]),
              height: p.resolution ? parseInt(p.resolution.split("X")[1]) : parseInt(DEFAULT_RESOLUTION.split("X")[1]),
            },
            teacherUseOnly: p.teacherUseOnly,
            exposureType: ExposureDetailType.CONTENT_BLOCK,
          };
        });
        return {
          id: c.id,
          name: DEFAULT_CONTENT_BLOCK_ITEM_NAME,
          media: media,
          teacherUseOnly: c.teacherUseOnly,
          isClicked: false,
        };
      });
      const includedIndependentMode = e.readerContents && Array.isArray(e.readerContents) && e.readerContents.length > 0;
      //handle alternate media block
      const alternateMediaBlockItems: ExposureItem[][] = [];
      if (!includedIndependentMode) {
        const newMedia = e.media ? e.media : [];
        for (const i of newMedia) {
          // do not show Thumbnail and Audio Background media slots in lesson plan container
          if (i.mediaSlotId === MediaSlot.Thumbnail || i.mediaSlotId === MediaSlot.AudioBackground) {
            continue;
          }

          // handle audio background slot
          const audioBackground =
            i.mediaSlotId === MediaSlot.Audio
              ? newMedia
                  .find((media) => media.mediaSlotId === MediaSlot.AudioBackground)
                  ?.page.map((p) => {
                    const imageUrl = buildUrl(p.url, StorageContainerEnum.Page);
                    imagesToPreLoad.push(imageUrl);
                    return {
                      ...p,
                      image: {
                        url: imageUrl,
                        width: p.resolution ? parseInt(p.resolution.split("X")[0]) : parseInt(DEFAULT_RESOLUTION.split("X")[0]),
                        height: p.resolution ? parseInt(p.resolution.split("X")[1]) : parseInt(DEFAULT_RESOLUTION.split("X")[1]),
                      },
                    };
                  })
                  .sort((a, b) =>
                    a.pageTimingToMillisecond && b.pageTimingToMillisecond && a.pageTimingToMillisecond > b.pageTimingToMillisecond ? 1 : -1,
                  )
              : undefined;

          // handle thumbnail slot
          const thumbnailSlot =
            i.mediaSlotId === MediaSlot.StreamingVideo ? newMedia.find((media) => media.mediaSlotId === MediaSlot.Thumbnail) : undefined;
          const thumbnail = thumbnailSlot?.url ? buildUrl(thumbnailSlot?.url, StorageContainerEnum.Page) : undefined;
          if (thumbnail) {
            imagesToPreLoad.push(thumbnail);
          }
          const alternateMedia: Array<ExposureItemMedia> = [
            {
              id: i.id,
              image: {
                url: "default",
                audioBackground,
                thumbnail,
              },
              teacherUseOnly: false,
              exposureType: ExposureDetailType.ALTERNATE_MEDIA_BLOCK,
            },
          ];
          const alternateMediaItems: Array<ExposureItem> = [
            {
              id: i.id,
              name: DEFAULT_ALTERNATE_MEDIA_BLOCK_ITEM_NAME,
              media: alternateMedia,
              mediaTypeId: i.mediaTypeId,
              mediaSlotId: i.mediaSlotId,
              teacherUseOnly: false,
            },
          ];
          const newImg = i.page ? i.page.map((p: any) => ({ ...p, page: [{ ...p }] })) : [];
          const alternateImgItems: Array<ExposureItem> = newImg.map((c: any) => {
            const media: Array<ExposureItemMedia> = c.page.map((p: any) => {
              const imageUrl = buildUrl(p.url, StorageContainerEnum.Page);
              imagesToPreLoad.push(imageUrl);
              return {
                id: p.id,
                image: {
                  url: imageUrl,
                  width: p.resolution ? parseInt(p.resolution.split("X")[0]) : parseInt(DEFAULT_RESOLUTION.split("X")[0]),
                  height: p.resolution ? parseInt(p.resolution.split("X")[1]) : parseInt(DEFAULT_RESOLUTION.split("X")[1]),
                },
                teacherUseOnly: p.teacherUseOnly,
                exposureType: ExposureDetailType.ALTERNATE_MEDIA_BLOCK,
              };
            });
            return {
              id: c.id,
              name: DEFAULT_ALTERNATE_MEDIA_BLOCK_ITEM_NAME,
              media: media,
              teacherUseOnly: c.teacherUseOnly,
              mediaTypeId: undefined,
            };
          });
          const item = alternateImgItems.concat(alternateMediaItems);
          alternateMediaBlockItems.push(item);
        }
      }

      //handle teaching activity block
      const newContentExposureTeachingActivity = e.contentExposureTeachingActivity
        ? e.contentExposureTeachingActivity?.map((c: any) => ({
            ...c,
            page: [
              {
                id: c.teachingActivityId,
                resolution: DEFAULT_RESOLUTION,
                sequence: c.sequence,
                url: c.imageUrl,
                metaData: JSON.parse(c.metaData),
              },
            ],
          }))
        : [];
      const teachingActivityBlockItems: Array<ExposureItem> = newContentExposureTeachingActivity?.map((c: any) => {
        const media: Array<ExposureItemMedia> = c.page.map((p: any) => {
          const url = p.url ? buildUrl(p.url, StorageContainerEnum.Page) : "";
          imagesToPreLoad.push(url);
          return {
            id: p.id, // need to confirm is contentExposureId or teachingActivity.id
            image: {
              url,
              width: p.resolution ? parseInt(p.resolution.split("X")[0]) : parseInt(DEFAULT_RESOLUTION.split("X")[0]),
              height: p.resolution ? parseInt(p.resolution.split("X")[1]) : parseInt(DEFAULT_RESOLUTION.split("X")[1]),
              metaData: p.metaData,
            },
            teacherUseOnly: e.page.find((page) => page.id === c.pageId)?.teacherUseOnly,
            exposureType: ExposureDetailType.TEACHING_ACTIVITY_BLOCK,
          };
        });
        return {
          id: c.contentExposureId,
          name: c.imageName || DEFAULT_TEACHING_ACTIVITY_BLOCK_ITEM_NAME,
          media,
          textContent: c?.teachingActivity?.text,
        };
      });
      const readerItems = includedIndependentMode ? fromReader(e.readerContents, buildUrl, imagesToPreLoad) : [];
      const exposure: Exposure = {
        id: e.id,
        name: e.title,
        duration: e.maxDuration,
        status: e.played ? ExposureStatus.COMPLETED : ExposureStatus.DEFAULT,
        type: ExposureTypeFromValue(e.contentType.id, e.contentType.name),
        includedIndependentMode,
        thumbnailURL: e.thumbnailUrl ? buildUrl(e.thumbnailUrl, StorageContainerEnum.Page) : "",
        contentRootType: ContentRootTypeFromValue(e.contentRootType),
        //* related slides
        items,
        //* component slides
        contentBlockItems: includedIndependentMode ? readerItems : contentBlockItems,
        //* teaching activities slides
        teachingActivityBlockItems,
        //* alternative slides
        alternateMediaBlockItems,
      };
      exposures.push(exposure);
    }

    // Commenting as perloading images while formatting data
    // const listUrl = exposures
    //   .map((expo) => {
    //     const url = expo.items.map((item) => {
    //       const urlImage = item.media.map((img) => {
    //         return img.image.url;
    //       });
    //       return urlImage;
    //     });
    //     return url;
    //   })
    //   .flat(2);
    // preloadImage(listUrl, 5000);

    const stopSpin = async () => {
      if (store.rootGetters["spin/getSplash"]) {
        await store.dispatch("spin/setSplash", false, { root: true });
      }
    };
    const imageCount = imagesToPreLoad.length;
    if (imageCount == 0) {
      await stopSpin();
    }

    let loadedCount = 0,
      errorCount = 0;

    const checkAllLoaded = async () => {
      if (loadedCount + errorCount == imageCount) {
        await stopSpin();
      }
    };

    const onload = async () => {
      loadedCount++;
      await checkAllLoaded();
    };

    const onerror = async () => {
      errorCount++;
      await checkAllLoaded();
    };

    imagesToPreLoad.forEach((im_url) => {
      const img = new Image();
      img.src = im_url;
      img.crossOrigin = "anonymous";
      img.onload = onload;
      img.onerror = onerror;
    });

    store.commit("setIsBlackOut", {
      IsBlackOut: payload.lessonPlan.isBlackout,
    });
    store.commit("setExposures", { exposures: exposures });
    store.commit("setPlayedTime", { time: payload.lessonPlan.playedTime });
    store.commit("setTotalTime", { time: payload.lessonPlan.totalTime });
    if (payload.isSetCurrentExposure) {
      await store.dispatch("setBothExposureAndSlide", payload.lessonPlan);
    }
  },
  setBothExposureAndSlide({ state, commit, getters }, ls: LessonPlanModel) {
    commit("setCurrentExposure", {
      id: ls.contentSelected,
      preventSelectFirstSlideAutomatically: true,
    });
    const pageSelected = ls.contents.find((e: ExposureContentModel) => e.id === ls.contentSelected)?.pageSelected;
    const exposure = state.exposures.find((ex) => ex.id === ls.contentSelected);
    if (exposure) {
      const exposureFirstSlide = getExposureFirstSlide(exposure, getters["alternativeShown"]);
      commit("setCurrentExposureItemMedia", {
        id: pageSelected || exposureFirstSlide?.id,
        exposureType: pageSelected ? undefined : exposureFirstSlide?.exposureType,
      });
    }
  },
  setExposures(
    store: ActionContext<LessonState, any>,
    payload: {
      exposures: Exposure[];
    },
  ) {
    store.commit("setExposures", payload);
  },
  setCurrentExposure(store: ActionContext<LessonState, any>, payload: { id: string }) {
    store.commit("setCurrentExposure", payload);
  },
  setCurrentExposureItemMedia(store: ActionContext<LessonState, any>, payload: { id: string; exposureType?: ExposureDetailType }) {
    store.commit("setCurrentExposureItemMedia", payload);
  },
  setExposureStatus(store: ActionContext<LessonState, any>, payload: { id: string; status: ExposureStatus }) {
    store.commit("setExposureStatus", payload);
  },
  setIsBlackOut(store: ActionContext<LessonState, any>, payload: { IsBlackOut: boolean }) {
    store.commit("setIsBlackOut", payload);
  },
  setPreviousExposure(store: ActionContext<LessonState, any>, payload: { id: string }) {
    store.commit("setPreviousExposure", payload);
  },
  setPreviousExposureItemMedia(store: ActionContext<LessonState, any>, payload: { id: string }) {
    store.commit("setPreviousExposureItemMedia", payload);
  },
  clearLessonData(store: ActionContext<LessonState, any>) {
    store.commit("clearLessonData");
  },
  storeCacheImage(store: ActionContext<LessonState, any>, payload: { url: string; metadata: LPItemMetadata; base64String: string }) {
    store.commit("storeCacheImage", payload);
  },
  clearCacheImage(store: ActionContext<LessonState, any>) {
    store.commit("clearCacheImage");
  },
  setZoomRatio(store: ActionContext<LessonState, any>, payload) {
    store.commit("setZoomRatio", payload);
  },
  setImgCoords(store: ActionContext<LessonState, any>, payload) {
    store.commit("setImgCoords", payload);
  },
  setLessonPreviewObjects(store: ActionContext<LessonState, any>, payload) {
    store.commit("setLessonPreviewObjects", payload);
  },
  setShowPreviewCanvas(store: ActionContext<LessonState, any>, payload) {
    store.commit("setShowPreviewCanvas", payload);
  },
  setClickedExposureItem(store: ActionContext<LessonState, any>, payload) {
    store.commit("setClickedExposureItem", payload);
  },
  async getAlternateMediaUrl(store: ActionContext<LessonState, any>, payload: { token: string; id: string }) {
    const res = await LessonService.getMediaUrl(payload.token, payload.id);
    store.commit("setAlternateMediaUrl", { id: payload.id, url: res });
  },
  endCurrentContent({ commit }) {
    commit("endCurrentContent");
  },
  setPdfScrollProgress(store: ActionContext<LessonState, any>, payload: number) {
    store.commit("setPdfScrollProgress", payload);
  },
  async [defineVuexName(VuexNames.LESSON.DISPATCHES.REQUEST_TOGGLE_TARGET)]({ rootState }, payload: ToggleTargetModel) {
    const manager: TeacherRoomManager | StudentRoomManager | undefined = rootState.teacherRoom.teacher
      ? rootState.teacherRoom?.manager
      : rootState.studentRoom?.manager;
    await manager?.WSClient.sendRequestToggleShape(payload);
  },
  [defineVuexName(VuexNames.LESSON.DISPATCHES.PROCESS_VISIBLE_TARGETS_DATA_FROM_API)](
    { commit }: ActionContext<LessonState, any>,
    payload: TeachingActivityTarget[] | undefined,
  ) {
    const transformedTags = payload?.filter((target) => target.visible).map((target) => target.tag) || [];
    commit(vuexName(VuexNames.LESSON.COMMITS.SET_VISIBLE_TARGET_TAGS, false), transformedTags);
  },
  [defineVuexName(VuexNames.LESSON.DISPATCHES.PROCESS_VISIBLE_TARGET_BY_FROM_MESSAGE)](
    { commit, state }: ActionContext<LessonState, any>,
    payload: TeachingActivityTarget,
  ) {
    let newTags = [...state.visibleTargetTags];
    if (payload.visible) {
      if (!state.visibleTargetTags.includes(payload.tag)) {
        newTags = [...state.visibleTargetTags, payload.tag];
      }
    } else {
      newTags = state.visibleTargetTags.filter((tag) => tag !== payload.tag);
    }

    commit(vuexName(VuexNames.LESSON.COMMITS.SET_VISIBLE_TARGET_TAGS, false), newTags);
  },
  async [defineVuexName(VuexNames.LESSON.DISPATCHES.TOGGLE_VISIBLE_TARGET)](
    { commit, state, dispatch, rootState }: ActionContext<LessonState, any>,
    payload: string,
  ) {
    const wasVisible = state.visibleTargetTags.includes(payload);
    const newTags = wasVisible ? state.visibleTargetTags.filter((tag) => tag !== payload) : [...state.visibleTargetTags, payload];
    commit(vuexName(VuexNames.LESSON.COMMITS.SET_VISIBLE_TARGET_TAGS, false), newTags);

    const model: TeachingActivityTarget = {
      userId: rootState.teacherRoom.teacher ? rootState.teacherRoom.teacher?.id : rootState.studentRoom.student?.id,
      visible: !wasVisible,
      tag: payload,
    };
    await dispatch(vuexName(VuexNames.LESSON.DISPATCHES.REQUEST_TOGGLE_TARGET), model, { root: true });
  },

  async [defineVuexName(VuexNames.LESSON.DISPATCHES.TOGGLE_ALL_VISIBLE_TARGETS)](
    { commit, dispatch, rootState }: ActionContext<LessonState, any>,
    {
      isShowAll,
      tags,
    }: {
      isShowAll: boolean;
      tags: string[];
    },
  ) {
    commit(vuexName(VuexNames.LESSON.COMMITS.SET_VISIBLE_TARGET_TAGS, false), isShowAll ? tags : []);

    // TODO: This solution is not optimal, but GSConnect mobile app old version is using this logic, so we need to keep it for now
    for (const tag of tags) {
      const model: TeachingActivityTarget = {
        userId: rootState.teacherRoom.teacher ? rootState.teacherRoom.teacher?.id : rootState.studentRoom.student?.id,
        visible: isShowAll,
        tag,
      };
      await dispatch(vuexName(VuexNames.LESSON.DISPATCHES.REQUEST_TOGGLE_TARGET), model, { root: true });
    }
  },
};

export default actions;
