import { v4 as uuidv4 } from "uuid";
import { DEVICE_ID_KEY } from "./constant";
import NonAvatar from "@/assets/images/user-default.png";
import { FbObjects } from "@/utils/fabric-utils";
import { LPItemMetadata } from "@/store/lesson/state";
import { SessionStateModel } from "@/services";
export const TransparentRgba = "rgba(255,255,255,0.01)";

export const createGuid = (prefix?: string): string => {
  return `${prefix ? prefix : ""}${uuidv4()}`;
};

enum DeviceType {
  Tablet = "tablet",
  Mobile = "mobile",
  Desktop = "desktop",
}

enum DeviceOs {
  iOS = "iOS",
  Android = "Android",
  Unknown = "Unknown",
}

const deviceType = (): DeviceType => {
  const ua = navigator.userAgent;
  if (
    /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua) ||
    (/Macintosh/i.test(ua) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1)
  ) {
    return DeviceType.Tablet;
  } else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return DeviceType.Mobile;
  }
  return DeviceType.Desktop;
};

const mobileDeviceOs = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const ua = navigator.userAgent || navigator.vendor || window.opera;
  if (/android/i.test(ua)) {
    return DeviceOs.Android;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  } else if ((/iPad|iPhone|iPod/.test(ua) && !window.MSStream) || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)) {
    return DeviceOs.iOS;
  }
  return DeviceOs.Unknown;
};

export const isMobileBrowser = deviceType() === DeviceType.Mobile;
export const isDesktopBrowser = deviceType() === DeviceType.Desktop;
export const isAndroidDevice = mobileDeviceOs() === DeviceOs.Android;
export const isIosDevice = mobileDeviceOs() === DeviceOs.iOS;
export const isUnknownDevice = mobileDeviceOs() === DeviceOs.Unknown;

export enum DefaultCanvasDimension {
  width = 717,
  height = 435,
}

export const calcRatioVal = ({ metadata, widthImg, heightImg }: { metadata: LPItemMetadata; widthImg: number; heightImg: number }) => {
  const widthCanvas = DefaultCanvasDimension.width;
  const heightCanvas = DefaultCanvasDimension.height;
  let imgWidthCropFit, imgHeightCropFit;
  const objectFitCenter = 50;
  let widthMetadata, heightMetadata;
  if (metadata.width === 0 && metadata.height === 0) {
    widthMetadata = widthImg;
    heightMetadata = heightImg;
  } else {
    widthMetadata = metadata.width;
    heightMetadata = metadata.height;
  }
  const cropRatio = widthMetadata / heightMetadata;
  const canvasRatio = widthCanvas / heightCanvas;
  if (cropRatio > canvasRatio) {
    imgWidthCropFit = widthCanvas;
    imgHeightCropFit = widthCanvas / cropRatio;
  } else {
    imgWidthCropFit = heightCanvas * cropRatio;
    imgHeightCropFit = heightCanvas;
  }
  const imgLeftCrop = (widthCanvas - imgWidthCropFit) * (objectFitCenter / 100);
  const wRatio = imgWidthCropFit / widthMetadata;
  const hRatio = imgHeightCropFit / heightMetadata;
  const ratio = Math.min(wRatio, hRatio);

  return { imgLeftCrop, ratio };
};

export const getRadius = (width: number, height: number): number => {
  return Math.min(width, height) / 2;
};

export enum Tools {
  Cursor = "cursor",
  Pen = "pen",
  Laser = "laser",
  Stroke = "stroke",
  Delete = "delete",
  Clear = "clear",
  StrokeColor = "stroke-color",
  Circle = "circle",
  Square = "square",
  TextBox = "TextBox",
}

export enum Mode {
  Cursor = 1,
  Draw = 2,
}

export enum ErrorCode {
  Default = 0,
  ConcurrentUserException = 1,
  StudentNotInClass = 5,
}
export enum FilterMode {
  Student = 1,
  Session = 2,
}
export enum SmoothingQuality {
  High = "high",
  Medium = "medium",
  Low = "low",
}

export enum ScheduleType {
  Create = "Create",
  Cancelled = "Cancelled",
  Delete = "Delete",
  Skip = "Skip",
}
/* eslint-disable no-useless-escape */
export const mobileDevice =
  /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
    navigator.userAgent,
  ) ||
  /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
    navigator.userAgent.substr(0, 4),
  );

export const queryStringToObject = () => {
  const search = location.search.substring(1);
  if (!search) return null;
  return JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) {
    return key === "" ? value : decodeURIComponent(value);
  });
};

export const getDeviceId = () => sessionStorage.getItem(DEVICE_ID_KEY) ?? "";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const isChromium = window.chrome;

//All commonly used browser by customer are listed in https://grapeseed.atlassian.net/browse/GL-18651

export const isSafariBrowser = () =>
  navigator.vendor &&
  navigator.vendor.indexOf("Apple") > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf("CriOS") == -1 &&
  navigator.userAgent.indexOf("FxiOS") == -1;

export const isOperaBrowser = () => window.navigator.userAgent.indexOf("OPR") > -1 || window.navigator.userAgent.indexOf("Opera") > -1;

export const isYandexBrowser = () => window.navigator.userAgent.indexOf("YaBrowser") !== -1;

export const isCocCocBrowser = () => window.navigator.userAgent.indexOf("coc_coc") !== -1;

export const isAvastBrowser = () => window.navigator.userAgent.indexOf("Avast") !== -1;

export const isAtomBrowser = () => window.navigator.userAgent.indexOf("Atom") !== -1;

export const isWhaleBrowser = () => window.navigator.userAgent.indexOf("Whale") !== -1;

export const isEdgeBrowser = () => window.navigator.userAgent.indexOf("Edg") > -1 && isChromium !== null;

export const isChromiumButNotChrome = () => isCocCocBrowser() || isAvastBrowser() || isAtomBrowser() || isWhaleBrowser();

export const isChromeBrowser = () => {
  const winNav = window.navigator;
  const vendorName = winNav.vendor;
  const isIOSChrome = winNav.userAgent.match("CriOS");
  return (
    isIOSChrome ||
    (isChromium !== null &&
      typeof isChromium !== "undefined" &&
      vendorName === "Google Inc." &&
      //distinct chromium based browsers to not be recognized as chrome
      !isChromiumButNotChrome())
  );
};

export const rgbToRgba = (rgb: string, alpha = 0.1) => rgb.replace(")", `, ${alpha})`).replace("rgb", "rgba");
export const upperCaseFirstLetter = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);
const toRGBArray = (rgbStr: string) => rgbStr.match(/\d+/g)?.map(Number);

export const calColorByBackground = (rgbStr: string) => {
  // Randomly update colours
  const rgb = toRGBArray(rgbStr) ?? [255, 0, 0];
  // http://www.w3.org/TR/AERT#color-contrast
  const brightness = Math.round((rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000);
  const textColour = brightness > 125 ? "#1e293b" : "white";
  const backgroundColour = "rgb(" + rgb[0] + "," + rgb[1] + "," + rgb[2] + ")";
  return textColour;
};

export const generateAvatar = (url?: string) => (url ? url : NonAvatar);

export enum GSConnectRoles {
  TEACHER,
  HELPER,
  STUDENT,
}
export const isAntdModalOpen = () => {
  const modal = Array.from(document.querySelectorAll(".ant-modal-wrap") as unknown as HTMLCollectionOf<HTMLElement>);
  if (!modal) {
    return false;
  }
  for (let i = 0; i < modal.length; i++) {
    if (!modal[i].style.display) {
      return true;
    }
  }
  return false;
};

export const isAntdSelectOpen = () => {
  const select = document.querySelectorAll(".ant-select-open");
  if (select.length > 0) {
    return true;
  }
  return false;
};

export enum ObjectTypes {
  TextBox = 0,
  Shape = 1,
  Line = 2,
}
export enum MediaDeviceTypes {
  Microphone = 0,
  Camera = 1,
}
export enum OperationTypes {
  Add = 0,
  Edit = 1,
  Remove = 2,
}
export const determineObjectTypes = (canvasObjectType: string) => {
  let type = ObjectTypes.TextBox;
  if (canvasObjectType === FbObjects.Circle || canvasObjectType === FbObjects.Rect) {
    type = ObjectTypes.Shape;
  } else if (canvasObjectType === FbObjects.Path) {
    type = ObjectTypes.Line;
  }
  return type;
};

export const removeCanvasObjectIcon = {
  size: 25,
  dataUrl:
    "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E",
};
export const isInputBeingEdited = () => {
  const activeElement = document.activeElement;
  return activeElement && activeElement.tagName.toLowerCase() === "input";
};

export const isTeacherUseOnlySlideVisible = ({ isTeacherUseOnly = false, isWhiteboardVisible = false }) => {
  return isTeacherUseOnly && !isWhiteboardVisible;
};

export const generateRealtimeLineId = () => {
  return "line-" + Math.floor(Math.random() * 10000);
};

export const isRotatedLPSlide = (metadata: LPItemMetadata | undefined) => {
  if (!metadata) return false;
  return !!(metadata.rotate !== 0 && (metadata.rotate / 90) % 2);
};
// This function is used to check if the video/audio has been played or not calculated based on the server
export const isMediaNotPlayedYetBasedOnServerState = (data: SessionStateModel) => !data.startMediaAt && !data.pauseMediaAt && data.duration < 1;
export const isMediaElementInitialState = (el: HTMLVideoElement | HTMLAudioElement) => el.currentTime === 0 && el.paused;
export const isChromiumBasedBrowser = (): boolean => {
  const userAgent: string = navigator.userAgent;
  return userAgent.includes("Chrom");
};
