export enum StudentWSEvent {
  JOIN_CLASS = "2106",
  STREAM_CONNECT = "2107",
  MUTE_AUDIO = "2108",
  MUTE_VIDEO = "2109",
  LEAVE = "2110",
  DISCONNECT = "2300",

  STUDENT_RAISING_HAND = "2105",
  STUDENT_LIKE = "2104",
  EVENT_STUDENT_ANSWER_TARGET = "2102",
  EVENT_STUDENT_ANSWER_CORRECT = "2101",
  EVENT_TEACHER_ANSWER_TARGET = "1109",
  EVENT_STUDENT_UPDATE_MEDIA_INFO = "2103",

  EVENT_STUDENT_SEND_UNITY = "2400",
  EVENT_STUDENT_UPDATE_SHAPE_LIST = "2111",
  EVENT_STUDENT_DRAWS_LINE = "2112",

  EVENT_UPDATE_SHAPE = "1122",
  EVENT_UPDATE_ALL_SHAPES = "1123",
  CAPTURE_IMAGE = "1124",
  STUDENT_SEND_CAPTURE_STATUS = '2113',
}
