import IconSpeakerStop from "@/assets/icons/pause-button.png";
import IconSpeakerPlay from "@/assets/icons/play-button.png";
import { ClassSetUp, DeviceTesterLocale } from "@/locales/localeid";
import { MediaStatus } from "@/models";
import { VCPlatform } from "@/store/app/state";
import { Logger } from "@/utils/logger";
import { CheckOutlined, LoadingOutlined } from "@ant-design/icons-vue";
import ZoomVideo, { LocalAudioTrack, LocalVideoTrack, TestMicrophoneReturn } from "@zoom/videosdk";
import AgoraRTC, { DeviceInfo, ICameraVideoTrack, IMicrophoneAudioTrack } from "agora-rtc-sdk-ng";
import { Button, Divider, Progress, Row, Select, Skeleton, Space, Spin, Switch, Tooltip } from "ant-design-vue";
import { computed, defineComponent, onUnmounted, ref, watch } from "vue";
import { fmtMsg } from "vue-glcommonui";
import { useStore } from "vuex";
import { isDesktopBrowser } from "@/utils/utils";
import { VideoCameraIcon, VideoCameraSlashIcon, SpeakerWaveIcon, MicrophoneIcon } from "@heroicons/vue/24/outline";
import { PlayCircleIcon, PauseCircleIcon } from "@heroicons/vue/24/solid";
import { DeviceErrorTooltip } from "./components";
export interface DeviceType {
  deviceId: string;
  groupId: string;
  kind: string;
  label: string;
}
interface ILocalTracks {
  videoTrack?: any;
  audioTrack?: any;
}
export default defineComponent({
  props: {
    isDetectedPlatform: {
      type: Boolean,
      required: false,
    },
    isUsingAgora: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    isCamEnabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    isMicEnabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    isMidSession: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["onOpenCamChange", "onOpenMicChange", "onCurrentCamChange", "onCurrentMicChange", "onCurrentSpeakerChange", "onDeviceError"],
  components: {
    Tooltip,
    Progress,
    Select,
    SelectOption: Select.Option,
    Button,
    Skeleton,
    Divider,
    Row,
    Space,
    Spin,
    Switch,
    CheckOutlined,
    LoadingOutlined,
    VideoCameraIcon,
    SpeakerWaveIcon,
    MicrophoneIcon,
    PlayCircleIcon,
    PauseCircleIcon,
    VideoCameraSlashIcon,
    DeviceErrorTooltip,
  },
  async created() {
    const { dispatch } = useStore();
    const showCameraOptions = !this.isMidSession || (this.isMidSession && isDesktopBrowser);
    await dispatch("setMuteAudio", { status: MediaStatus.mediaNotLocked });
    if (showCameraOptions) {
      await dispatch("setHideVideo", { status: MediaStatus.mediaNotLocked });
    }
  },
  setup(props, { emit }) {
    const { getters, dispatch } = useStore();
    const CheckMic = computed(() => fmtMsg(DeviceTesterLocale.CheckMic));
    const SelectDevice = computed(() => fmtMsg(DeviceTesterLocale.SelectDevice));
    const SystemDefaultSpeakerDevice = computed(() => fmtMsg(DeviceTesterLocale.SystemDefaultSpeakerDevice));
    const CheckCam = computed(() => fmtMsg(DeviceTesterLocale.CheckCam));
    const CheckSpeaker = computed(() => fmtMsg(DeviceTesterLocale.CheckSpeaker));
    const CamOff = computed(() => fmtMsg(DeviceTesterLocale.CamOff));
    const warningMsgSpeaker = computed(() => fmtMsg(DeviceTesterLocale.MessageWarningSpeaker));
    const warningMsgMicrophone = computed(() => fmtMsg(DeviceTesterLocale.MessageWarningMic));
    const warningMsgCamera = computed(() => fmtMsg(DeviceTesterLocale.MessageWarningCamera));
    const RemoteSetUpText = computed(() => props.title || fmtMsg(ClassSetUp.RemoteClassSetUp));
    const havePermissionCamera = computed(() => getters["havePermissionCamera"]);
    const havePermissionMicrophone = computed(() => getters["havePermissionMicrophone"]);
    const havePermissionSpeaker = computed(() => getters["havePermissionMicrophone"]); // Depend on mic because Agora doesn't support check speaker on Firefox
    const isOpenMic = ref<boolean>(props.isMicEnabled);
    const isOpenCam = ref<boolean>(props.isCamEnabled);
    const isTeacherVideoMirror = ref<boolean>(false);
    const isStudentVideoMirror = ref<boolean>(false);
    const localTracks = ref<ILocalTracks>({});
    const listMics = ref<DeviceType[]>([]);
    const listMicsId = ref<string[]>([]);
    const listCams = ref<DeviceType[]>([]);
    const listCamsId = ref<string[]>([]);
    const playerRef = ref();
    const currentMic = ref<DeviceType>();
    const currentMicLabel = ref();
    const currentCam = ref<DeviceType>();
    const currentCamLabel = ref();
    const currentCamId = ref();
    const volumeByPercent = ref(0);
    const volumeAnimation = ref();
    const videoElementId = "pre-local-player";
    const agoraError = ref(false);
    const agoraMicError = ref(false);
    const agoraCamError = ref(false);
    const isConfigTrackingDone = ref(false);
    const zoomMicError = ref(false);
    const zoomCamError = ref(false);
    const devices = ref<MediaDeviceInfo[]>([]);
    const audio = ref<any>(null);
    const listSpeakers = ref<DeviceType[]>([]);
    const listSpeakersId = ref<string[]>([]);
    const currentSpeaker = ref<DeviceType>();
    const currentSpeakerLabel = ref();
    const isPlayingSound = ref(false);
    const isCheckSpeaker = ref(true);
    const isPlaySpeaker = ref(false);
    const speakerIcon = computed(() => (isPlaySpeaker.value ? IconSpeakerStop : IconSpeakerPlay));
    const microphoneTesterRef = ref<TestMicrophoneReturn>();
    const workingCamId = ref<string>();
    const workingMicId = ref<string>();
    const toggleSpeaker = async () => {
      audio.value = document.getElementById("audio");
      isPlaySpeaker.value = !isPlaySpeaker.value;
      if (isPlaySpeaker.value && audio.value) {
        if (listSpeakers.value.length && currentSpeaker.value?.deviceId) {
          await audio.value.setSinkId(currentSpeaker.value?.deviceId);
        }
        audio.value.play();
      } else {
        audio.value.pause();
        audio.value.currentTime = 0;
      }
      isPlayingSound.value = !audio.value.paused;
    };
    const openCamera = async () => {
      if (!playerRef.value) return;
      await localTracks.value?.videoTrack?.play(videoElementId, {
        mirror: false,
      });
      updateCameraError(false);
    };
    const createCameraTrackWithId = async (cameraId: string): Promise<ICameraVideoTrack> => {
      return new Promise((resolve, reject) => {
        AgoraRTC.createCameraVideoTrack({
          cameraId,
          encoderConfig: {
            width: 1280,
            height: 720,
          },
        })
          .then((videoTrack: ICameraVideoTrack) => {
            resolve(videoTrack);
          })
          .catch((error) => {
            Logger.error(`Error creating camera video track with cameraId ${cameraId}:`, error);
            reject(error);
          });
      });
    };

    const initializeVideoTrack = async () => {
      let videoTrack: ICameraVideoTrack | null = null;
      const cams = await AgoraRTC.getCameras();
      let cameraIdList = cams.map((cam) => cam.deviceId);
      const persistedCameraId = getters["cameraDeviceId"];
      if (persistedCameraId) {
        if (cameraIdList.includes(persistedCameraId)) {
          cameraIdList = cameraIdList.filter((id) => id !== persistedCameraId);
          cameraIdList.unshift(persistedCameraId);
        }
      }
      for (const cameraId of cameraIdList) {
        try {
          videoTrack = await createCameraTrackWithId(cameraId);
          Logger.log(`Video track created successfully with cameraId: ${cameraId}`);
          workingCamId.value = cameraId;
          break;
        } catch (error) {
          Logger.error(`Failed to create video track with cameraId: ${cameraId}`);
        }
      }
      return videoTrack;
    };

    const createMicroTrackWithId = async (microId: string): Promise<IMicrophoneAudioTrack> => {
      return new Promise((resolve, reject) => {
        AgoraRTC.createMicrophoneAudioTrack()
          .then((audioTrack: IMicrophoneAudioTrack) => {
            resolve(audioTrack);
          })
          .catch((error) => {
            Logger.error(`Error creating micro audio track with microId ${microId}:`, error);
            reject(error);
          });
      });
    };

    const initializeAudioTrack = async () => {
      let audioTrack: IMicrophoneAudioTrack | null = null;
      const mics = await AgoraRTC.getMicrophones();
      let microIdList = mics.map((mic) => mic.deviceId);
      const persistedMicroId = getters["microphoneDeviceId"];
      if (persistedMicroId) {
        if (microIdList.includes(persistedMicroId)) {
          microIdList = microIdList.filter((id) => id !== persistedMicroId);
          microIdList.unshift(persistedMicroId);
        }
      }
      for (const microId of microIdList) {
        try {
          audioTrack = await createMicroTrackWithId(microId);
          Logger.log(`Audio track created successfully with miroId: ${microId}`);
          workingMicId.value = microId;
          break;
        } catch (error) {
          Logger.error(`Failed to create audio track with miroId: ${microId}`);
        }
      }
      return audioTrack;
    };

    const setupVideoTrack = async () => {
      let videoTrack = null;
      try {
        videoTrack = await initializeVideoTrack();
      } catch (error) {
        Logger.error(error);
        if (error?.code === "PERMISSION_DENIED") {
          await dispatch("setHavePermissionCamera", false);
        }
        agoraCamError.value = true;
      }
      if (videoTrack) {
        localTracks.value.videoTrack = videoTrack;
      }
      return !!videoTrack;
    };

    const setupAudioTrack = async () => {
      let audioTrack = null;
      try {
        audioTrack = await initializeAudioTrack();
      } catch (error) {
        Logger.error(error);
        if (error?.code === "PERMISSION_DENIED") {
          await dispatch("setHavePermissionMicrophone", false);
        }
        agoraMicError.value = true;
      }
      if (audioTrack) {
        localTracks.value.audioTrack = audioTrack;
      }
      return !!audioTrack;
    };

    const setupAgora = async () => {
      if (showCameraOptions.value) {
        await setupVideoTrack();
      }
      await setupAudioTrack();
    };

    const setupZoom = async () => {
      let audioTrack: LocalAudioTrack | null = null;
      let videoTrack: LocalVideoTrack | null = null;
      try {
        await navigator.mediaDevices.getUserMedia({ audio: true });
        await dispatch("setHavePermissionMicrophone", true);
      } catch (error) {
        Logger.error(error);
        if (error?.name === "NotAllowedError") {
          await dispatch("setHavePermissionMicrophone", false);
        }
      }
      if (showCameraOptions.value) {
        try {
          const videoStreams = await navigator.mediaDevices.getUserMedia({ video: true });
          //* the line above will open camera automatically, stop by https://stackoverflow.com/questions/11642926/stop-close-webcam-stream-which-is-opened-by-navigator-mediadevices-getusermedia
          videoStreams.getTracks().forEach(function (vTrack) {
            vTrack.stop();
          });
          await dispatch("setHavePermissionCamera", true);
        } catch (error) {
          Logger.error(error);
          if (error?.name === "NotAllowedError") {
            await dispatch("setHavePermissionCamera", false);
          }
        }
      }
      try {
        devices.value = await ZoomVideo.getDevices();
      } catch (error) {
        Logger.error(error);
        devices.value = await ZoomVideo.getDevices(true);
      }
      if (showCameraOptions.value) {
        if (havePermissionCamera.value) {
          try {
            const cams = devices.value.filter(function (device) {
              return device.kind === "videoinput";
            });
            if (cams.length && havePermissionCamera.value) {
              const camSelected = getPersistedOrFirstDevice(cams, "cameraDeviceId");
              currentCam.value = camSelected;
              currentCamLabel.value = camSelected?.label;
              currentCamId.value = camSelected?.deviceId;
              listCams.value = cams;
              listCamsId.value = cams.map((cam) => cam.deviceId);
              videoTrack = ZoomVideo.createLocalVideoTrack(camSelected.deviceId);
            }
          } catch (error) {
            Logger.error(error);
            if (error?.code === "PERMISSION_DENIED") {
              await dispatch("setHavePermissionCamera", false);
            }
            zoomCamError.value = true;
          }
        }
      }
      if (havePermissionMicrophone.value) {
        try {
          const mics = devices.value.filter(function (device) {
            return device.kind === "audioinput";
          });
          if (mics.length && havePermissionMicrophone.value) {
            const micSelected = getPersistedOrFirstDevice(mics, "microphoneDeviceId");
            currentMic.value = micSelected;
            currentMicLabel.value = micSelected?.label;
            listMics.value = mics;
            listMicsId.value = mics.map((mic) => mic.deviceId);
            audioTrack = ZoomVideo.createLocalAudioTrack(micSelected.deviceId);
          }
        } catch (error) {
          Logger.error(error);
          if (error?.code === "PERMISSION_DENIED") {
            await dispatch("setHavePermissionMicrophone", false);
          }
          zoomMicError.value = true;
        }
        try {
          const speakersOrigin = devices.value.filter(function (device) {
            return device.kind === "audiooutput";
          });
          const speakers: DeviceType[] = speakersOrigin.map((speaker) => {
            if (speaker.deviceId === "default") {
              return {
                deviceId: speaker.deviceId,
                groupId: speaker.groupId,
                kind: speaker.kind,
                label: "Default Device",
              };
            }
            return speaker;
          });
          if (speakers.length) {
            const speakerSelected = getPersistedOrFirstDevice(speakers, "speakerDeviceId");
            listSpeakers.value = speakers;
            listSpeakersId.value = speakers.map((speaker: any) => speaker.deviceId);
            currentSpeaker.value = speakerSelected;
            currentSpeakerLabel.value = speakerSelected?.label;
          }
        } catch (error) {
          Logger.error(error);
        }
        Logger.log("Setup zoom media done");
        localTracks.value = {
          audioTrack,
          videoTrack,
        };
      }
    };

    const setupZoomTracking = async () => {
      Logger.log("Setup zoom media tracking");
      try {
        const doc = document.getElementById(videoElementId) as HTMLVideoElement;
        if (showCameraOptions.value && havePermissionCamera.value && currentCam.value) {
          await localTracks.value?.videoTrack?.start(doc);
        }
        if (havePermissionMicrophone.value && currentMic.value) {
          await localTracks.value?.audioTrack?.start();
          await localTracks.value?.audioTrack?.unmute();
          setVolumeWave();
        }

        Logger.log("Setup zoom media tracking done");
      } catch (error) {
        Logger.error(error);
      }
      isConfigTrackingDone.value = true;
    };

    const getPersistedOrFirstDevice = (devices: MediaDeviceInfo[] | DeviceType[], key: string) => {
      const deviceId = getters[key];
      return devices.find((device: DeviceType) => device.deviceId === deviceId) ?? devices[0];
    };

    const setupDevice = async () => {
      if (showCameraOptions.value) {
        try {
          const cams = await AgoraRTC.getCameras();
          if (cams.length) {
            listCams.value = cams;
            listCamsId.value = cams.map((cam) => cam.deviceId);
            const workingCam = cams.find((cam) => cam.deviceId === workingCamId.value);
            const selectedCam = workingCam ?? getPersistedOrFirstDevice(cams, "cameraDeviceId");
            if (selectedCam) {
              currentCam.value = selectedCam;
              currentCamLabel.value = selectedCam.label;
              currentCamId.value = selectedCam.deviceId;
            }
            if (!workingCam) {
              throw new Error();
            }
            await localTracks.value?.videoTrack?.setDevice(workingCam.deviceId);
            try {
              if (!isOpenCam.value) {
                await localTracks.value?.videoTrack?.setEnabled(false);
              }
              await openCamera();
            } catch (error) {
              Logger.error(error);
            }
          }
        } catch (error) {
          Logger.error(error);
          agoraCamError.value = true;
        }
      }
      try {
        const mics = await AgoraRTC.getMicrophones();
        if (mics.length) {
          await dispatch("setHavePermissionMicrophone", true);
          listMics.value = mics;
          listMicsId.value = mics.map((mic) => mic.deviceId);
          const workingMic = mics.find((mic) => mic.deviceId === workingMicId.value);
          const selectedMic = workingMic ?? getPersistedOrFirstDevice(mics, "microphoneDeviceId");
          if (selectedMic) {
            currentMic.value = selectedMic;
            currentMicLabel.value = selectedMic.label;
          }
          if (!workingMic) {
            throw new Error();
          }
          await localTracks.value?.audioTrack?.setDevice(workingMic.deviceId);
          if (!volumeAnimation.value) {
            volumeAnimation.value = window.requestAnimationFrame(setVolumeWave);
          }
        } else {
          await dispatch("setHavePermissionMicrophone", false);
        }
      } catch (error) {
        Logger.error(error);
        await dispatch("setHavePermissionMicrophone", false);
        agoraMicError.value = true;
      }
      try {
        const speakers = await AgoraRTC.getPlaybackDevices();
        if (speakers.length) {
          const speakerSelected = getPersistedOrFirstDevice(speakers, "speakerDeviceId");
          listSpeakers.value = speakers;
          listSpeakersId.value = speakers.map((speaker) => speaker.deviceId);
          currentSpeaker.value = speakerSelected;
          currentSpeakerLabel.value = speakerSelected?.label;
        }
      } catch (error) {
        Logger.error(error);
      }
    };

    const handleHotPluggingMicro = async (newMicroId?: string) => {
      try {
        const mics = await AgoraRTC.getMicrophones();
        const newMicro = mics.find(({ deviceId }) => deviceId === newMicroId);

        // check if the old microphone is working
        let oldMicro = undefined;
        if (newMicroId) {
          oldMicro = mics.find(({ deviceId }) => currentMic.value?.deviceId === deviceId);
        }
        if (mics.length) {
          listMics.value = mics;
          listMicsId.value = mics.map((mic) => mic.deviceId);
          if (!localTracks.value?.audioTrack) {
            const success = await setupAudioTrack();
            if (!success) {
              throw new Error();
            }
          }
          currentMic.value = newMicro ?? oldMicro ?? mics[0];
          currentMicLabel.value = currentMic.value?.label;
          await localTracks.value?.audioTrack?.setDevice(currentMic.value?.deviceId);
          if (!volumeAnimation.value) {
            volumeAnimation.value = window.requestAnimationFrame(setVolumeWave);
          }
        }
      } catch (error) {
        Logger.error(error);
        agoraMicError.value = true;
      }
    };

    const handleHotPluggingCamera = async (newCameraId?: string) => {
      try {
        const cams = await AgoraRTC.getCameras();
        const newCamera = cams.find(({ deviceId }) => deviceId === newCameraId);
        // check if the old camera is working
        let oldCamera = undefined;
        if (newCameraId) {
          oldCamera = cams.find(({ deviceId }) => currentCam.value?.deviceId === deviceId);
        }
        if (cams.length) {
          listCams.value = cams;
          listCamsId.value = cams.map((cam) => cam.deviceId);
          if (!localTracks.value?.videoTrack) {
            const success = await setupVideoTrack();
            if (!success) {
              throw new Error();
            }
          }
          currentCam.value = newCamera ?? oldCamera ?? cams[0];
          currentCamLabel.value = currentCam.value?.label;
          currentCamId.value = currentCam.value?.deviceId;
          await localTracks.value?.videoTrack?.setDevice(currentCam.value?.deviceId);
          try {
            await openCamera();
          } catch (error) {
            Logger.error(error);
          }
        }
      } catch (error) {
        Logger.error(error);
        agoraCamError.value = true;
      }
    };

    const handleHotPluggingSpeaker = async (newSpeakerId?: string) => {
      try {
        const speakers = await AgoraRTC.getPlaybackDevices();
        const newSpeaker = speakers.find(({ deviceId }) => deviceId === newSpeakerId);
        // check if the old camera is working
        let oldSpeaker = undefined;
        if (newSpeakerId) {
          oldSpeaker = speakers.find(({ deviceId }) => currentSpeaker.value?.deviceId === deviceId);
        }
        if (speakers.length) {
          listSpeakers.value = speakers;
          listSpeakersId.value = speakers.map((sp) => sp.deviceId);
          currentSpeaker.value = newSpeaker ?? oldSpeaker ?? speakers[0];
          currentSpeakerLabel.value = currentSpeaker.value?.label;
        }
      } catch (error) {
        Logger.error(error);
      }
    };

    const onHotMicroPluggingDevice = async (changedDevice: DeviceInfo) => {
      if (changedDevice.state === "ACTIVE") {
        await handleHotPluggingMicro(changedDevice.device.deviceId);
      } else {
        await handleHotPluggingMicro();
      }
    };
    const onHotCameraPluggingDevice = async (changedDevice: DeviceInfo) => {
      if (changedDevice.state === "ACTIVE") {
        await handleHotPluggingCamera(changedDevice.device.deviceId);
      } else {
        await handleHotPluggingCamera();
      }
    };

    const onSpeakerPluggingDevice = async (changedDevice: DeviceInfo) => {
      if (changedDevice.state === "ACTIVE") {
        await handleHotPluggingSpeaker(changedDevice.device.deviceId);
      } else {
        await handleHotPluggingSpeaker();
      }
    };
    const setupEvent = () => {
      if (showCameraOptions.value) {
        AgoraRTC.onCameraChanged = onHotCameraPluggingDevice;
      }
      AgoraRTC.onMicrophoneChanged = onHotMicroPluggingDevice;
      AgoraRTC.onPlaybackDeviceChanged = onSpeakerPluggingDevice;
      isConfigTrackingDone.value = true;
    };

    const cancelVolumeAnimation = () => {
      cancelAnimationFrame(volumeAnimation.value);
      volumeAnimation.value = null;
      volumeByPercent.value = 0;
    };

    const mediaPlatformSetup = async () => {
      if (props.isUsingAgora) {
        await setupAgora();
        await setupDevice();
        setupEvent();
      } else {
        await setupZoom();
        await setupZoomTracking();
      }
    };

    watch(currentMic, async (currentMicValue) => {
      emit("onCurrentMicChange", currentMicValue);
      try {
        if (!isConfigTrackingDone.value) return;
        if (!localTracks.value?.audioTrack) return;
        if (!havePermissionMicrophone.value) return;
        if (microphoneTesterRef.value) {
          microphoneTesterRef.value.stop();
        }
        if (currentMicValue) {
          if (props.isUsingAgora) {
            await localTracks.value?.audioTrack?.setEnabled(true);
          } else {
            const thisMic = listMics.value.find(({ deviceId }) => deviceId === currentMicValue.deviceId) || listMics.value[0];
            await localTracks.value?.audioTrack?.stop();
            localTracks.value["audioTrack"] = ZoomVideo.createLocalAudioTrack(thisMic.deviceId);
            await localTracks.value?.audioTrack?.start();
            if (isOpenMic.value) {
              await localTracks.value?.audioTrack?.unmute();
            }
          }
          if (props.isUsingAgora) {
            if (!volumeAnimation.value) {
              volumeAnimation.value = window.requestAnimationFrame(setVolumeWave);
            }
          } else {
            setVolumeWave();
          }
        }
      } catch (error) {
        Logger.error(error);
      }
    });

    watch(currentCam, async (currentCamValue) => {
      emit("onCurrentCamChange", currentCamValue);
      try {
        if (!isConfigTrackingDone.value) return;
        if (!localTracks.value?.videoTrack) return;
        if (!havePermissionCamera.value) return;

        if (currentCamValue) {
          if (props.isUsingAgora) {
            await openCamera();
            await localTracks.value?.videoTrack?.setEnabled(true);
          } else {
            const thisCam = listCams.value.find(({ deviceId }) => deviceId === currentCamValue.deviceId) || listCams.value[0];
            await localTracks.value?.videoTrack?.stop();
            localTracks.value["videoTrack"] = ZoomVideo.createLocalVideoTrack(thisCam.deviceId);
            if (isOpenCam.value) {
              const doc = document.getElementById(videoElementId) as HTMLVideoElement;
              await localTracks.value?.videoTrack?.start(doc);
            }
          }
        }
      } catch (error) {
        updateCameraError(true);
        Logger.error(error);
      }
    });

    watch(currentSpeaker, async (currentSpeakerValue) => {
      emit("onCurrentSpeakerChange", currentSpeakerValue);
    });

    //handle for camera
    watch(isOpenCam, async (currentIsOpenCamValue) => {
      emit("onOpenCamChange", currentIsOpenCamValue);
      if (currentIsOpenCamValue) {
        await dispatch("setHideVideo", { status: MediaStatus.mediaNotLocked });
        if (currentCam.value) {
          if (props.isUsingAgora) {
            await localTracks.value?.videoTrack?.setEnabled(true);
          } else {
            const doc = document.getElementById(videoElementId) as HTMLVideoElement;
            await localTracks.value?.videoTrack?.start(doc);
          }
        }
      }
      if (!currentIsOpenCamValue) {
        await dispatch("setHideVideo", { status: MediaStatus.mediaLocked });
        if (currentCam.value) {
          if (props.isUsingAgora) {
            await localTracks.value?.videoTrack?.setEnabled(false);
          } else {
            await localTracks.value?.videoTrack?.stop();
          }
        }
      }
    });

    //handle for microphone
    watch(isOpenMic, async (currentIsOpenMic) => {
      emit("onOpenMicChange", currentIsOpenMic);
      if (currentIsOpenMic) {
        await dispatch("setMuteAudio", { status: MediaStatus.mediaNotLocked });
        if (currentMic.value) {
          if (props.isUsingAgora) {
            await localTracks.value?.audioTrack?.setEnabled(true);
          } else {
            await localTracks.value?.audioTrack?.unmute();
          }
          if (props.isUsingAgora) {
            if (!volumeAnimation.value) {
              volumeAnimation.value = window.requestAnimationFrame(setVolumeWave);
            }
          } else {
            setVolumeWave();
          }
        }
      }
      if (!currentIsOpenMic) {
        microphoneTesterRef.value?.stop();
        await dispatch("setMuteAudio", { status: MediaStatus.mediaLocked });
        if (volumeAnimation.value) {
          cancelVolumeAnimation();
        }
        if (currentMic.value) {
          if (props.isUsingAgora) {
            await localTracks.value?.audioTrack?.setEnabled(false);
          } else {
            await localTracks.value?.audioTrack?.mute();
          }
        }
      }
    });

    //handle for speaker
    watch(isCheckSpeaker, () => {
      if (audio.value) {
        if (isCheckSpeaker.value) {
          audio.value.play();
        } else {
          audio.value.pause();
          audio.value.currentTime = 0;
        }
        isPlayingSound.value = !audio.value.paused;
      }
    });
    const setVolumeWave = () => {
      if (!localTracks.value) return;
      if (props.isUsingAgora) {
        volumeAnimation.value = window.requestAnimationFrame(setVolumeWave);
        volumeByPercent.value = localTracks.value?.audioTrack?.getVolumeLevel() * 100;
      } else {
        microphoneTesterRef.value = localTracks.value?.audioTrack?.testMicrophone({
          microphoneId: currentMic.value?.deviceId,
          speakerId: currentSpeaker.value?.deviceId,
          recordAndPlay: false,
          onAnalyseFrequency: (value: number) => {
            volumeByPercent.value = Math.min(100, value);
          },
        });
      }
    };

    const handleMicroChange = async (micId: string) => {
      updateMicroError(false);
      try {
        if (props.isUsingAgora) {
          await localTracks.value.audioTrack.setDevice(micId);
        }
        currentMic.value = listMics.value.find((mic) => mic.deviceId === micId);
        if (currentMic.value && currentMic.value.deviceId) localStorage.setItem("micId", currentMic.value.deviceId);
      } catch (error) {
        updateMicroError(true);
        Logger.error(error);
      }
    };

    const handleCameraChange = async (camId: string) => {
      updateCameraError(false);
      try {
        currentCam.value = listCams.value.find((cam) => cam.deviceId === camId);
        if (props.isUsingAgora) {
          await localTracks.value.videoTrack.setDevice(camId);
        }
      } catch (error) {
        updateCameraError(true);
        Logger.error(error);
      }
    };

    const handleSpeakerChange = async (speakerId: string) => {
      audio.value = document.getElementById("audio");
      currentSpeaker.value = listSpeakers.value.find((speaker) => speaker.deviceId === speakerId);
      if (audio.value) {
        await audio.value.setSinkId(currentSpeaker.value?.deviceId);
        audio.value.pause();
        audio.value.currentTime = 0;
        audio.value.play();
      } else {
        audio.value.pause();
        audio.value.currentTime = 0;
      }
      isPlayingSound.value = !audio.value.paused;
      isPlaySpeaker.value = !audio.value.paused;
    };

    const destroySDK = async (isAgora: boolean) => {
      isConfigTrackingDone.value = false;
      if (audio.value && !audio.value.paused) {
        audio.value.pause();
        audio.value.currentTime = 0;
        isPlayingSound.value = false;
      }

      if (volumeAnimation.value) {
        cancelVolumeAnimation();
      }
      if (microphoneTesterRef.value) {
        microphoneTesterRef.value.destroy();
      }
      try {
        if (isOpenMic.value) {
          if (isAgora) {
            await localTracks.value?.audioTrack?.setEnabled(false);
            await localTracks.value?.audioTrack?.close();
          } else {
            await localTracks.value?.audioTrack?.stop();
          }
        }
      } catch (error) {
        Logger.error(error);
      }
      try {
        if (isOpenCam.value) {
          if (isAgora) {
            await localTracks.value?.videoTrack?.setEnabled(false);
            await localTracks.value?.videoTrack?.close();
            AgoraRTC.onCameraChanged = undefined;
            AgoraRTC.onMicrophoneChanged = undefined;
            agoraError.value = false;
            agoraCamError.value = false;
            agoraMicError.value = false;
          } else {
            await localTracks.value?.videoTrack?.stop();
          }
        }
      } catch (error) {
        Logger.error(error);
      }

      localTracks.value = {};

      currentMic.value = undefined;
      currentCam.value = undefined;
      isOpenMic.value = props.isMicEnabled;
      isOpenCam.value = props.isCamEnabled;

      currentSpeaker.value = undefined;
      isCheckSpeaker.value = true;
      isPlaySpeaker.value = false;
      isPlayingSound.value = false;

      isTeacherVideoMirror.value = false;
      isStudentVideoMirror.value = false;

      await dispatch("setHavePermissionCamera", true);
      await dispatch("setHavePermissionMicrophone", true);
      Logger.log("Destroy SDK");
      await dispatch("calling/reRegisterHotPluggingDevicesEventListener");
    };

    const destroy = async () => {
      await destroySDK(props.isUsingAgora);
    };

    const showCameraOptions = computed(() => !props.isMidSession || (props.isMidSession && isDesktopBrowser));

    watch(
      () => props.isUsingAgora,
      async (currentValue, prevValue) => {
        await destroySDK(prevValue);
        updateCameraError(false);
        Logger.log("Platform changed");
        await dispatch("setVideoCallPlatform", currentValue ? VCPlatform.Agora : VCPlatform.Zoom);
        await mediaPlatformSetup();
      },
    );

    watch(
      () => props.isDetectedPlatform,
      (currentVal) => {
        if (currentVal) {
          mediaPlatformSetup();
        }
      },
      { immediate: true },
    );

    onUnmounted(async () => {
      AgoraRTC.onMicrophoneChanged = undefined;
      AgoraRTC.onCameraChanged = undefined;
      if (microphoneTesterRef.value) {
        microphoneTesterRef.value.stop();
      }
      await destroy();
    });

    const isCurrentCamError = computed(() => {
      let isError = false;
      if ((props.isUsingAgora && agoraCamError.value) || (!props.isUsingAgora && zoomCamError.value)) {
        isError = true;
      }
      return isError;
    });

    const updateCameraError = (isError: boolean) => {
      agoraCamError.value = isError;
      zoomCamError.value = isError;
    };

    const updateMicroError = (isError: boolean) => {
      agoraMicError.value = isError;
      zoomMicError.value = isError;
    };

    watch(
      [havePermissionMicrophone, havePermissionCamera, agoraMicError, agoraCamError],
      ([havePermissionMicrophone, havePermissionCamera, agoraMicError, agoraCamError]) => {
        const isError = !havePermissionMicrophone || !havePermissionCamera || agoraCamError || agoraMicError;
        emit("onDeviceError", isError);
      },
    );

    const MsgCameraErrorInfo = computed(() => fmtMsg(DeviceTesterLocale.CameraErrorInfo));
    const MsgMicroErrorInfo = computed(() => fmtMsg(DeviceTesterLocale.MicroErrorInfo));
    const MsgSpeakerErrorInfo = computed(() => fmtMsg(DeviceTesterLocale.SpeakerErrorInfo));

    return {
      MsgCameraErrorInfo,
      MsgMicroErrorInfo,
      MsgSpeakerErrorInfo,
      RemoteSetUpText,
      warningMsgSpeaker,
      warningMsgMicrophone,
      warningMsgCamera,
      havePermissionCamera,
      havePermissionMicrophone,
      havePermissionSpeaker,
      CheckMic,
      SelectDevice,
      SystemDefaultSpeakerDevice,
      CheckCam,
      CamOff,
      isOpenMic,
      isOpenCam,
      playerRef,
      volumeByPercent,
      listMics,
      listCams,
      listCamsId,
      listMicsId,
      currentCam,
      currentMicLabel,
      currentCamLabel,
      handleMicroChange,
      handleCameraChange,
      videoElementId,
      agoraCamError,
      agoraMicError,
      zoomCamError,
      isCheckSpeaker,
      listSpeakers,
      listSpeakersId,
      currentSpeakerLabel,
      CheckSpeaker,
      isPlayingSound,
      handleSpeakerChange,
      speakerIcon,
      toggleSpeaker,
      showCameraOptions,
      currentCamId,
      isCurrentCamError,
      PlayCircleIcon,
      PauseCircleIcon,
    };
  },
});
