import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38366fe1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "font-medium text-base truncate flex-1" }
const _hoisted_2 = { class: "inline-flex overflow-hidden" }
const _hoisted_3 = { class: "p-2" }
const _hoisted_4 = { class: "text-slate-600" }
const _hoisted_5 = { class: "mt-4 flex justify-end items-center space-x-1" }
const _hoisted_6 = {
  class: "inline-block p-1 hover:bg-white/20 transition rounded focus:relative",
  title: "Delete Team"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_MicrophoneIcon = _resolveComponent("MicrophoneIcon")!
  const _component_Button = _resolveComponent("Button")!
  const _component_PencilSquareIcon = _resolveComponent("PencilSquareIcon")!
  const _component_TrashIcon = _resolveComponent("TrashIcon")!
  const _component_Popover = _resolveComponent("Popover")!
  const _component_Member = _resolveComponent("Member")!
  const _component_Draggable = _resolveComponent("Draggable")!
  const _component_TeamWrapper = _resolveComponent("TeamWrapper")!

  return (_openBlock(), _createBlock(_component_TeamWrapper, {
    color: _ctx.team.color
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "flex gap-2 items-center mb-2 pl-4 pr-3 py-3",
        style: _normalizeStyle({ backgroundColor: _ctx.team.color, color: _ctx.calColorByBackground(_ctx.team.color) })
      }, [
        _createVNode(_component_Tooltip, { placement: "topLeft" }, {
          title: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.team.name), 1)
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.team.name), 1)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Tooltip, { placement: "topLeft" }, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.muteTooltipTitle), 1)
            ]),
            default: _withCtx(() => [
              (_ctx.isClassPage && _ctx.isTeamMode)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('handleMute', _ctx.team.id, _ctx.isMuted))),
                    class: _normalizeClass(_ctx.muteBtnClass),
                    disabled: _ctx.disableMute,
                    size: _ctx.muteBtnSize
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.muteBtnLabel) + " ", 1),
                      _createVNode(_component_MicrophoneIcon, {
                        enabled: _ctx.isMuted,
                        isOutline: true,
                        width: _ctx.muteIconSize,
                        height: _ctx.muteIconSize
                      }, null, 8, ["enabled", "width", "height"])
                    ]),
                    _: 1
                  }, 8, ["class", "disabled", "size"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('handleEdit', _ctx.team.id))),
            class: "inline-block p-1 hover:bg-white/20 transition rounded focus:relative",
            title: "Edit Team"
          }, [
            _createVNode(_component_PencilSquareIcon, { class: "h-4 w-4" })
          ]),
          _createVNode(_component_Popover, {
            visible: _ctx.delPopoverOpened,
            "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.delPopoverOpened) = $event)),
            trigger: "click"
          }, {
            content: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.MsgConfirmDelTeam), 1),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_Button, {
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.delPopoverOpened = false))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.MsgNo), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Button, {
                    type: "primary",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('handleDel', _ctx.team.id)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.MsgYes), 1)
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            default: _withCtx(() => [
              _createElementVNode("button", _hoisted_6, [
                _createVNode(_component_TrashIcon, { class: "h-4 w-4" })
              ])
            ]),
            _: 1
          }, 8, ["visible"])
        ])
      ], 4),
      _createVNode(_component_Draggable, {
        class: "members px-3 py-2 grow",
        list: _ctx.team.students,
        group: "people",
        itemKey: "name"
      }, {
        item: _withCtx(({ element: student }) => [
          _createVNode(_component_Member, {
            member: student,
            "team-color": _ctx.team.color
          }, null, 8, ["member", "team-color"])
        ]),
        _: 1
      }, 8, ["list"])
    ]),
    _: 1
  }, 8, ["color"]))
}