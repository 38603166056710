import { StudentRoomManager } from "@/manager/room/student.manager";
import { ClassModel, ClassRoomModel, RoomModel } from "@/models";
import { GLApiStatus, GLError } from "@/models/error.model";
import { UserModel } from "@/models/user.model";
import { GetterTree } from "vuex";
import { ClassView, HelperInClassStatus, HelperState, InClassStatus, StudentState, TeacherState, UserMediaStatus } from "../interface";
import { ClassAction, StudentRoomState } from "./state";

const getters: GetterTree<StudentRoomState, any> = {
  getInitClassLoading(state: StudentRoomState): boolean {
    return state.initClassLoading;
  },
  getIsSessionEnded(state: StudentRoomState): boolean {
    return state.isSessionEnded;
  },
  isConnected(state: StudentRoomState): boolean {
    if (!state.manager || !state.manager.WSClient) return false;
    return state.manager.WSClient.isConnected;
  },
  isAgoraClientInitialized(state: StudentRoomState): boolean {
    return !!state.manager?.agoraClient?.initialized;
  },
  globalAudios(state: StudentRoomState): Array<string> {
    return state.globalAudios;
  },
  error(state: StudentRoomState): GLError | null {
    return state.error;
  },
  apiStatus(state: StudentRoomState): GLApiStatus | null {
    return state.apiStatus;
  },
  info(state: StudentRoomState): RoomModel {
    return state.info as RoomModel;
  },
  classInfo(state: StudentRoomState): ClassRoomModel | undefined {
    return state.info?.classInfo;
  },
  classes(state: StudentRoomState): Array<ClassModel> {
    return state.classes;
  },
  students(state: StudentRoomState): Array<StudentState> {
    return state.students;
  },
  teacher(state: StudentRoomState): TeacherState {
    return state.teacher as TeacherState;
  },
  student(state: StudentRoomState): StudentState {
    return state.student as StudentState;
  },
  localStudentId(state: StudentRoomState): string | undefined {
    return state.student?.id;
  },
  roomManager(state: StudentRoomState): StudentRoomManager {
    return state.manager as StudentRoomManager;
  },
  classView(state: StudentRoomState): ClassView {
    return state.classView;
  },
  isAllVideoHidden(state: StudentRoomState) {
    for (const student of state.students) {
      if (student.videoEnabled) return false;
    }
    return true;
  },
  isAllAudioMuted(state: StudentRoomState) {
    for (const student of state.students) {
      if (student.audioEnabled) return false;
    }
    return true;
  },
  isAllPaletteHidden(state: StudentRoomState) {
    for (const student of state.students) {
      if (student.isPalette) return false;
    }
    return true;
  },
  classAction(state: StudentRoomState): ClassAction {
    return state.classAction;
  },
  getStudentModeOneId(state: StudentRoomState): string {
    return state.idOne;
  },
  getStudentModeHelperOneId(state: StudentRoomState): string {
    return state.idHelperOne;
  },
  oneStudentIds(state: StudentRoomState): string[] {
    const studentIds = [];
    if (state.idOne) {
      studentIds.push(state.idOne);
    }
    if (state.idHelperOne) {
      studentIds.push(state.idHelperOne);
    }
    return studentIds;
  },
  onlineStudentIdsNotInOneToOneMode(state: StudentRoomState): string[] {
    return state.students.filter((st) => st.id !== state.idOne && st.id !== state.idHelperOne && st.status === InClassStatus.JOINED).map((s) => s.id);
  },
  speakingUsers(state: StudentRoomState): Array<string> {
    return state.speakingUsers;
  },
  isShowWhiteboard(state: StudentRoomState): boolean {
    return state.showWhiteBoard;
  },
  isDisconnected(state: StudentRoomState): boolean {
    return state.isDisconnected;
  },
  isJoined(state: StudentRoomState): boolean {
    return state.isJoined;
  },
  teacherIsDisconnected(state: StudentRoomState): boolean {
    return state.teacherIsDisconnected;
  },
  getAvatarStudentOneToOne(state: StudentRoomState): string {
    return state.avatarStudentOneToOne;
  },
  getAvatarStudentHelperOneToOne(state: StudentRoomState): string {
    return state.avatarStudentHelperOneToOne;
  },
  videosFeedVisible(state: StudentRoomState): boolean {
    return state.videosFeedVisible;
  },
  user(state: StudentRoomState): UserModel | undefined {
    return state.user;
  },
  isLoggedInAsStudent(state: StudentRoomState): boolean {
    return !!state.user?.id;
  },
  isCaptureImage(state: StudentRoomState): boolean {
    return state.startCaptureImage;
  },
  getMediaState(state: StudentRoomState): boolean {
    return state.mediaState;
  },
  getCurrentTimeMedia(state: StudentRoomState): number {
    return state.currentTimeMedia;
  },
  helperInfo(state: StudentRoomState): HelperState | undefined {
    return state.helper;
  },
  helperId(state: StudentRoomState): string | undefined {
    return state.helper?.id;
  },
  helperVideoStatus(state: StudentRoomState): boolean {
    return state.helper?.isVideoShownByTeacher ?? false;
  },
  helperAvatar(state: StudentRoomState): string {
    return state.helper?.avatar ?? "";
  },
  helperCameraOn(state: StudentRoomState): boolean {
    return state.helper?.videoEnabled ?? false;
  },
  signalRConnected(state: StudentRoomState): boolean {
    return !!state.manager?.WSClient.isConnected;
  },
  usersMedia(state: StudentRoomState): UserMediaStatus[] {
    const { teacher, helper, students } = state;
    const users = students.map((student) => ({
      id: student.id,
      video: student.videoEnabled,
      audio: student.audioEnabled,
      isConnectedSignalR: student.status == InClassStatus.JOINED,
    }));
    if (teacher?.id) {
      const { id, videoEnabled, audioEnabled, status } = teacher;
      users.push({ id, video: videoEnabled, audio: audioEnabled, isConnectedSignalR: status == InClassStatus.JOINED });
    }
    if (helper?.id) {
      const { id, videoEnabled, audioEnabled, connectionStatus } = helper;
      users.push({ id, video: videoEnabled, audio: audioEnabled, isConnectedSignalR: connectionStatus == HelperInClassStatus.Joined });
    }
    return users;
  },
  isMeInOneOne(state: StudentRoomState): boolean {
    return state.idOne === state.student?.id || state.idHelperOne === state.student?.id;
  },
  inOneToOneModeWithHelper(state: StudentRoomState): boolean {
    return state.idHelperOne === state.student?.id;
  },
  inOneToOneModeWithTeacher(state: StudentRoomState): boolean {
    return !!(state.idOne && state.idOne === state.student?.id);
  },
  anotherInOneToOneModeWithTeacher(state: StudentRoomState): boolean {
    return !!(state.idOne && state.idOne !== state.student?.id);
  },
  anotherInOneToOneModeWithHelper(state: StudentRoomState): boolean {
    return !!(state.idHelperOne && state.idHelperOne !== state.student?.id);
  },
  localStudentRaisedHand(state: StudentRoomState): boolean {
    return !!state.student?.raisingHand;
  },
  isCameraLocking(state: StudentRoomState): boolean {
    return state.cameraLock;
  },
  isMicrophoneLocking(state: StudentRoomState): boolean {
    return state.microphoneLock;
  },
};

export default getters;
