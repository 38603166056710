import { computed, createVNode, defineComponent, ref } from "vue";
import { CloseOutlined, DownOutlined, ExclamationCircleOutlined, LoadingOutlined, UpOutlined } from "@ant-design/icons-vue";
import { Button, Modal } from "ant-design-vue";
import { DeviceSettingsModal, MicrophoneIcon } from "@/components/common";
import { HelperState } from "@/store/room/interface";
import { useStore } from "vuex";
import { fmtMsg } from "vue-glcommonui";
import { CommonLocale, HelperLocales, TeacherClassLocale } from "@/locales/localeid";
import { HelperService } from "@/services";
import { Logger } from "@/utils/logger";
import {
  VideoCameraIcon,
  VideoCameraSlashIcon,
  SpeakerWaveIcon,
  SpeakerXMarkIcon,
  XMarkIcon,
  Cog6ToothIcon,
  EyeIcon,
  EyeSlashIcon,
  ArrowsPointingInIcon,
  WifiIcon,
} from "@heroicons/vue/20/solid";
import { generateAvatar } from "@/utils/utils";
import NameTag from "@/components/common/name-tag/name-tag.vue";
import { useDevicesLocking } from "@/hooks/use-devices-locking";

export default defineComponent({
  name: "HelperCardComponent",
  props: {
    isMinimized: Boolean,
    onClickMinimized: {
      type: Object as () => void,
      required: true,
    },
  },
  components: {
    LoadingOutlined,
    NameTag,
    CloseOutlined,
    DownOutlined,
    UpOutlined,
    Button,
    DeviceSettingsModal,
    VideoCameraIcon,
    VideoCameraSlashIcon,
    SpeakerWaveIcon,
    SpeakerXMarkIcon,
    XMarkIcon,
    Cog6ToothIcon,
    EyeIcon,
    EyeSlashIcon,
    ArrowsPointingInIcon,
    WifiIcon,
    MicrophoneIcon,
  },
  setup() {
    const { getters, dispatch } = useStore();
    const { isCameraLocking, isMicrophoneLocking } = useDevicesLocking(false);
    const helperInfo = computed<HelperState | undefined>(() => getters["teacherRoom/helperInfo"]);
    const helperVideoStatus = computed<boolean>(() => getters["teacherRoom/helperVideoStatus"]);
    const helperCameraOn = computed<boolean>(() => getters["teacherRoom/helperCameraOn"]);
    const helperMicroOn = computed<boolean>(() => getters["teacherRoom/helperMicroOn"]);
    const helperId = computed(() => getters["teacherRoom/helperId"]);
    const isHelper = computed<boolean>(() => getters["teacher/isHelper"]);
    const oneAndOne = computed(() => getters["teacherRoom/getStudentModeOneId"]);
    const CommonYesText = computed(() => fmtMsg(CommonLocale.CommonYesButtonText));
    const CommonNoText = computed(() => fmtMsg(CommonLocale.CommonNoButtonText));
    const ConfirmRemoveHelperText = computed(() => fmtMsg(TeacherClassLocale.ConfirmRemoveHelper));
    const onHelperToggleCamera = async () => {
      if (isCameraLocking.value) return;
      let isOff = false;
      if (helperCameraOn.value) {
        isOff = true;
      }
      await dispatch("teacherRoom/toggleHelperCamera", isOff);
    };
    const onHelperToggleMicro = async () => {
      if (isMicrophoneLocking.value) return;
      let isOff = false;
      if (helperMicroOn.value) {
        isOff = true;
      }
      await dispatch("teacherRoom/toggleHelperMicro", isOff);
    };
    const updateCamOpen = async (isOpen: boolean) => {
      await dispatch("teacherRoom/toggleHelperCamera", !isOpen);
    };

    const updateMicOpen = async (isOpen: boolean) => {
      await dispatch("teacherRoom/toggleHelperMicro", !isOpen);
    };

    const isDeviceSettingsModalShown = ref(false);
    const openDeviceSettingsModal = () => {
      isDeviceSettingsModalShown.value = true;
    };

    const onRemoveHelper = () => {
      Modal.confirm({
        icon: createVNode(ExclamationCircleOutlined),
        content: ConfirmRemoveHelperText.value,
        okText: CommonYesText.value,
        cancelText: CommonNoText.value,
        onOk: () => {
          dispatch("teacherRoom/teacherRemoveHelper");
        },
      });
    };

    const showHelperVideoText = computed(() => fmtMsg(HelperLocales.ShowVideoButton));
    const hideHelperVideoText = computed(() => fmtMsg(HelperLocales.HideVideoButton));
    const toggleHelperVideoLoading = ref(false);
    const toggleHelperVideo = async (isShown = false) => {
      if (helperVideoStatus.value === isShown || toggleHelperVideoLoading.value) return;
      try {
        toggleHelperVideoLoading.value = true;
        await HelperService.teacherToggleHelperVideo(isShown);
      } catch (error) {
        Logger.error(error);
      }
      toggleHelperVideoLoading.value = false;
    };

    const showHelperVideo = computed<boolean>(() => {
      return isHelper.value ? helperCameraOn.value : helperCameraOn.value && helperVideoStatus.value && !oneAndOne.value;
    });

    const helperAvatar = computed(() => generateAvatar(getters["teacherRoom/helperAvatar"]));

    const isUsingAgora = computed<boolean>(() => getters["isUsingAgora"]);
    const isSupportedVideo = computed(() => !!(window as any).chrome && !(typeof SharedArrayBuffer === "function"));
    const zoomId = computed(() => {
      let id = helperId.value;
      if (isHelper.value) {
        id = id + "__video";
      } else {
        id = id + "__sub";
      }
      return id;
    });

    return {
      helperInfo,
      helperVideoStatus,
      isHelper,
      helperCameraOn,
      helperMicroOn,
      onHelperToggleCamera,
      onHelperToggleMicro,
      updateCamOpen,
      updateMicOpen,
      openDeviceSettingsModal,
      isDeviceSettingsModalShown,
      onRemoveHelper,
      toggleHelperVideoLoading,
      toggleHelperVideo,
      showHelperVideoText,
      hideHelperVideoText,
      showHelperVideo,
      helperAvatar,
      isUsingAgora,
      isSupportedVideo,
      zoomId,
      helperId,
      VideoCameraIcon,
      VideoCameraSlashIcon,
      SpeakerWaveIcon,
      SpeakerXMarkIcon,
      EyeIcon,
      EyeSlashIcon,
      isCameraLocking,
      isMicrophoneLocking,
    };
  },
});
