import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e42724a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mt-5 text-right space-x-1" }
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckOutlined = _resolveComponent("CheckOutlined")!
  const _component_DeviceSettings = _resolveComponent("DeviceSettings")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    visible: _ctx.visible,
    onCancel: _ctx.closeModal,
    footer: null,
    destroyOnClose: true,
    width: 900
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DeviceSettings, {
        isUsingAgora: _ctx.isUsingAgora,
        isDetectedPlatform: true,
        title: _ctx.DeviceSettingsText,
        isCamEnabled: _ctx.isCamEnabled,
        isMicEnabled: _ctx.isMicEnabled,
        isMidSession: true,
        onOnCurrentCamChange: _ctx.onCurrentCamChange,
        onOnCurrentMicChange: _ctx.onCurrentMicChange,
        onOnCurrentSpeakerChange: _ctx.onCurrentSpeakerChange,
        onOnOpenCamChange: _ctx.onOpenCamChange,
        onOnOpenMicChange: _ctx.onOpenMicChange,
        onOnDeviceError: _ctx.handleDeviceError
      }, _createSlots({ _: 2 }, [
        (!_ctx.hideFooter)
          ? {
              name: "default",
              fn: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("button", {
                    class: "transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md cursor-pointer focus:ring-4 focus:ring-gs-purple focus:ring-opacity-20 focus-visible:outline-none [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed border-secondary text-slate-500 [&:hover:not(:disabled)]:bg-secondary/20 w-24",
                    type: "button",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
                  }, _toDisplayString(_ctx.CommonCancelText), 1),
                  _createElementVNode("button", {
                    class: "transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-5 rounded-md cursor-pointer focus:ring-4 focus:ring-gs-purple focus:ring-opacity-20 focus-visible:outline-none [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-gs-purple border-gs-purple text-white",
                    type: "button",
                    disabled: _ctx.isUpdateBtnDisabled,
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onUpdateDeviceSettings && _ctx.onUpdateDeviceSettings(...args)))
                  }, [
                    _createVNode(_component_CheckOutlined, { style: {"padding-inline":"3px","font-size":"18px"} }),
                    _createElementVNode("span", null, _toDisplayString(_ctx.CommonUpdateText), 1)
                  ], 8, _hoisted_2)
                ])
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["isUsingAgora", "title", "isCamEnabled", "isMicEnabled", "onOnCurrentCamChange", "onOnCurrentMicChange", "onOnCurrentSpeakerChange", "onOnOpenCamChange", "onOnOpenMicChange", "onOnDeviceError"])
    ]),
    _: 1
  }, 8, ["visible", "onCancel"]))
}