import { computed } from "vue";
import { useStore } from "vuex";
import { StudentState, UserMediaStatus } from "@/store/room/interface";
import { ToggleStudentMediaDevicesRequestModel } from "@/services";
import { CanvasObjectModel } from "@/hooks/use-send-websocket-msg";

export enum RoomStore {
  Teacher = 0,
  Student = 1,
}
export const useRoomStore = (room: RoomStore) => {
  const module = room === RoomStore.Teacher ? "teacherRoom" : "studentRoom";
  const { getters, dispatch } = useStore();
  const usersMedia = computed<UserMediaStatus[]>(() => getters[`${module}/usersMedia`]);
  const students = computed<StudentState[]>(() => getters[`${module}/students`]);
  const oneStudentIds = computed<string[]>(() => getters[`${module}/oneStudentIds`]);
  const onlineStudentIdsNotInOneToOneMode = computed<string[]>(() => getters[`${module}/onlineStudentIdsNotInOneToOneMode`]);
  return {
    usersMedia,
    students,
    oneStudentIds,
    onlineStudentIdsNotInOneToOneMode,
  };
};
